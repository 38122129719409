import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Icon from 'components/Icon';

export const CardLinkContainer = styled('div')`
  display: flex;
  gap: 38px;
  margin-top: 32px;
`;

export const CardLinkStyled = styled(Link)`
    position: relative;
    border: 2px solid #000000;
    text-decoration: none;
    width: 400px;
    color: #000000;

    img {
    width: 100%;
    height: 100%;
    }
`;

export const CardLinkAction = styled('div')`
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    padding: 16px 20px;
    display: flex;
    align-items: center;

    h4 {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        margin-right: 8px;
    }
`;

interface CardLinkProps extends LinkProps {
    bgURL: string
    title: string
}

const CardLink = React.forwardRef<any, CardLinkProps>(({ bgURL, title, ...props }, ref) => (
    <CardLinkContainer>
        <CardLinkStyled {...props} ref={ref}>
            <img src={bgURL} alt="bg-1" />
            <CardLinkAction>
                <Typography variant="h4">{title}</Typography>
                <Icon name="link-out" size={14} />
            </CardLinkAction>
        </CardLinkStyled>
    </CardLinkContainer>
))

export default CardLink