import * as React from 'react';
import { useNavigate } from 'react-router-dom';
// import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Modal } from '@mui/material';

import UICard from "components/UICard";
import { PageHeading } from 'components/Heading';
import UIButton from 'components/UIButton';
import IOSSwitch from 'components/IOSSwitch';
import { ModalBody } from 'components/UIModal';
import Icon from 'components/Icon';
import {
  SettingsCard,
  TeamHeader,
  InviteHeading,
  InviteActions,
  InviteModalHeading,
  InviteLinkContainer,
  InviteLinkIcon,
  InviteLinkInfo,
  CustomerTable,
  CustomerTableRow,
  CustomerDisableContainer,
  CustomerTableActions
} from "./settings.styled";
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCustomersList, setCustomerActivation } from 'store/customers';
import { CustomerActivationStatus } from 'store/customers/types';

export default function Customers() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const { customersList, customersListNext, customersListPrevious } = useAppSelector(({ customers }) => customers);
  const navigate = useNavigate();

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleActivationStatus = (id: string, status: CustomerActivationStatus) => {
    dispatch(setCustomerActivation({
      id,
      activation_status: status
    }));
  }

  const handlePagination = (cursor: string | null) => {
    if (cursor) {
      dispatch(getCustomersList(cursor));
    }
  }

  React.useEffect(() => {
    dispatch(getCustomersList());
  }, [dispatch]);

  return (
    <>
      <PageHeading variant="h4">Customers</PageHeading>

      <UICard style={{ marginTop: 32 }}>
        <SettingsCard>
          <TeamHeader>
            <Typography variant="body1">Manage Customers</Typography>
            {/* <UIButton variant="outlined" onClick={handleOpen}>Invite</UIButton> */}
          </TeamHeader>

          <TableContainer>
            <CustomerTable sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Live Mode</TableCell>
                  <TableCell>7d</TableCell>
                  <TableCell>14d</TableCell>
                  <TableCell>30d</TableCell>
                  <TableCell>90d</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customersList.map(customer => (
                  <CustomerTableRow key={customer.id}>
                    <TableCell component="th" scope="row">
                      {customer.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {customer.activation_status === 'disabled' ? (
                        <CustomerDisableContainer>
                          <Icon name="trash-success" size={16} />
                          <Typography variant="body1">Disabled</Typography>
                        </CustomerDisableContainer>
                      ) : (
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={customer.activation_status === 'live'}
                          onChange={e => handleActivationStatus(customer.id, e.target.checked ? 'live' : 'test')}
                        />
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {customer.seven_days_shipment_count}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {customer.fourteen_days_shipment_count}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {customer.thirty_days_shipment_count}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {customer.ninty_days_shipment_count}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {customer.activation_status !== 'disabled' ? (
                        <UIButton variant="outlined" style={{ marginRight: 10 }} onClick={() => handleActivationStatus(customer.id, 'disabled')}>Disable</UIButton>
                      ) : null}
                      <UIButton color="primary" onClick={() => navigate(`/customers/${customer.id}`)}>View</UIButton>
                    </TableCell>
                  </CustomerTableRow>
                ))}
              </TableBody>
            </CustomerTable>

            <CustomerTableActions>
              <button onClick={() => handlePagination(customersListPrevious)} disabled={!Boolean(customersListPrevious)}>
                <Typography variant="body1">{'<'} Prev</Typography>
              </button>
              <button onClick={() => handlePagination(customersListNext)} disabled={!Boolean(customersListNext)}>
                <Typography variant="body1">Next {'>'}</Typography>
              </button>
            </CustomerTableActions>
          </TableContainer>
        </SettingsCard>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <ModalBody>
            <InviteHeading variant="h2">Invite new customer</InviteHeading>

            <InviteModalHeading variant="body1">General access</InviteModalHeading>

            <InviteLinkContainer>
              <InviteLinkIcon>
                <Icon name="link" size={16} masked={true} />
              </InviteLinkIcon>

              <InviteLinkInfo>
                <Typography variant="body1">Customers with the link</Typography>
                <Typography variant="body1">Only people with the link can become a customer.</Typography>
              </InviteLinkInfo>
            </InviteLinkContainer>

            <InviteModalHeading variant="body1">Link sharing</InviteModalHeading>

            <InviteActions>
              <UIButton variant="outlined" color="primary" form="invite-form" startIcon={<Icon name="link" size={16} masked={true} color="primary" />}>Copy link</UIButton>
              <UIButton type="submit" color="primary" form="invite-form">Done</UIButton>
            </InviteActions>
          </ModalBody>
        </Modal>
      </UICard>
    </>
  )
};