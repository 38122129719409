import { Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import UIButton from 'components/UIButton';
import { Link } from 'react-router-dom';

export const BackLink = styled(Link)`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    color: #808080;
    margin-bottom: 10px;
`;

export const ManifestHeader = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ManifestForm = styled('form')``;

export const DimensionContainer = styled('div')`
    display: flex;
    gap: 20px;

    & > .MuiFormControl-root .MuiTextField-root {
        min-width: 100px;
    }

    & > button {
        align-self: baseline;
        margin-top: 2px;
    }
`;

export const ManifestInfo = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ManifestItem = styled('div')`
    display: flex;
    gap: 20px;
    align-items: center;

    & > p:first-of-type {
        color: ${props => props.theme.palette.greyOne.main}
    }
`;

const manifestAlertColors = {
    success: ' #AEF3AC',
    warning: '#EFCC52',
    error: '#FF5555',
    pending: '#000'
}

export const ManifestAlert = styled(Alert) <{ status: 'success' | 'warning' | 'error' | 'pending' }>`
    display: flex;
    justify-content: center;
    align-items: center;
    
    background: ${props => manifestAlertColors[props.status] || '#000'};
    color: #000;
`;

export const DeleteButton = styled(UIButton)`
    min-width: 26px;
    height: 26px;
    padding: 8px;
`;