import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon from "components/Icon";
import { Link, useLocation } from "react-router-dom";

const HeadingContainer = styled('div')`
    display: flex;
    align-items: center;

    h4 {
        display: flex;
        align-items: center;
        font-size: 32px;
        color: ${props => props.theme.palette.greyOne.main};
        margin-right: 13px;

        &:last-of-type {
            color: #000;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
        text-transform: capitalize;
    }
`;

const headingAlias = {
    "settings": "Organization Settings",
    "carrier": "Carrier Configuration",
    "billing": "Invoices and Billing"
}

const HeadingCrumb: React.FC<{ lastElement?: string, limit?: number, alias?: string[] }> = ({ lastElement, limit, alias }) => {
    const location = useLocation();
    let currentLink = '';
    let lastElementLink = '';

    const crumbs = location.pathname.split('/').filter(crumb => crumb !== '');

    const renderCrumbs = crumbs.splice(0, limit || crumbs.length)
        .map((crumb, i, row) => {
            const lastIndex = row.length - 1;
            currentLink += `/${crumb}`;
            lastElementLink = (lastElement && limit) ? `${currentLink}/${crumbs[crumbs.length - 1]}` : currentLink;

            if (alias) {
                return (
                    <Typography variant="h4" key={crumb}>
                        <Link to={currentLink}>{headingAlias[crumb] ? headingAlias[crumb] : alias[i]}</Link>
                        {i !== lastIndex && (
                            <Icon style={{ marginLeft: 13 }} name="arrow-right" size={32} />
                        )}
                    </Typography>
                )
            }

            return (
                <Typography variant="h4" key={crumb}>
                    {i !== lastIndex || !lastElement ? (
                        <Link to={currentLink}>{headingAlias[crumb] ? headingAlias[crumb] : crumb}</Link>
                    ) : (
                        <Link to={lastElementLink}>{lastElement}</Link>
                    )}
                    {i !== lastIndex && (
                        <Icon style={{ marginLeft: 13 }} name="arrow-right" size={32} />
                    )}
                </Typography>
            )
        });

    return (
        <HeadingContainer>
            {renderCrumbs}
        </HeadingContainer>
    )
}

export default HeadingCrumb;