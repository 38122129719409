import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import HeadingCrumb from 'components/HeadingCrumb';
import { SettingsDesc, SettingsHeading } from 'pages/OrganizationSettings/settings.styled';
import UICard from 'components/UICard';
import { Grid, Modal, Typography, styled } from '@mui/material';
import { SettingsCard } from 'pages/Customers/settings.styled';
import UIButton from 'components/UIButton';
import { Input, UISelect } from 'components/UIForm';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { ModalBody } from "components/UIModal";
import { ConfirmModalHeading } from 'styles/modal.styled';
import Icon from 'components/Icon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getWebhookMappingList, getWebhooks, selectWebhook, updateWebhookCodeMapping } from 'store/auth';
import boxcIcon from 'assets/images/boxc-icon.png';
import { default as eventsCodes } from 'integrations/boxc/eventsCodes.json';

const WebhookInputItem = styled('div')`
    width: 100%;
    gap: 16px;

    h6 {
        margin-bottom: 16px;
    }

    &:not(:last-of-type) {
        margin-bottom: 32px;
    }

    .MuiFormControl-root {
        width: 256px;
    }
`;

const WebCodeBlock = styled(Box)`
    background-color: #eee;
    display: block;
    padding: 10px;
    font-family: 'Andale Mono', sans-serif;
    font-size: 14px;
    border-radius: 6px;
    width: 100%;
    word-wrap: break-word;
    margin-top: 8px;
`;

const WebCodeActions = styled('div')`
    display: flex;
    align-items: baseline;
    /* justify-content: space-between; */
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 32px;

    .MuiInputBase-root {
        height: 40px;
    }

    .MuiFormControl-root {
        flex: 1 1;
    }
`;

const WebhookCodeItem = styled('div')`
    &:not(:last-of-type) {
        margin-top: 15px;
    }
`;

const WebhookCodeItemInfo = styled('div')`
    display: flex;
    align-items: center;
    gap: 8px;

    img {
        width: 16px;
        height: 16px;
    }
`;

const WebhookItemAction = styled('div')`
    /* display: flex;
    align-items: baseline;
    gap: 8px; */
    margin-top: 10px;
    
    button {
        padding: 5px;
        min-width: 20px;
        /* margin-top: 20px; */
        /* align-self: center; */
    }

    .MuiFormControl-root  {
        width: 100%;

        input {
            font-family: 'Andale Mono', sans-serif;
        }
    }
`;

const webhookObject = {
    status_code: "D3I",
    reason_code: "ABC",
    status_description: "Event Description"
}

const eventCodeSchema = Yup.object().shape({
    code_mappings: Yup.array().of(
        Yup.object().shape({
            code: Yup.string().required('Code is required'),
            // eslint-disable-next-line
            json: Yup.string().required('JSON is required').matches(/-?(?:0|[1-9]\d*)(?:\.\d+)?(?:[eE][+\-]?\d+)?/, 'Please enter a valid json')
        })
    )
});

type EventCodeSchemaType = Yup.InferType<typeof eventCodeSchema>;

const Webhooks = () => {
    const [open, setOpen] = React.useState(false);
    const [bxCode, setBxCode] = React.useState('');
    const [bxCodeError, setBxCodeError] = React.useState(false);
    const dispatch = useAppDispatch();
    const { webhooksList, webhookMappingList, selectedWebhook } = useAppSelector(state => state.auth);

    const handleOpen = (id: string) => {
        dispatch(selectWebhook(id));
        dispatch(getWebhookMappingList(id));
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formik = useFormik<EventCodeSchemaType>({
        enableReinitialize: true,
        //@ts-ignore
        initialValues: { code_mappings: webhookMappingList },
        validationSchema: eventCodeSchema,
        onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
            const status_code_mappings = {};

            values.code_mappings?.forEach(codeMap => {
                status_code_mappings[codeMap.code] = JSON.parse(codeMap.json);
            });

            if (selectedWebhook) {
                dispatch(updateWebhookCodeMapping({
                    id: selectedWebhook,
                    status_code_mappings
                })).unwrap().finally(() => {
                    setSubmitting(false);
                    setOpen(false);
                })
            }
        }
    });

    useEffect(() => {
        const promise = dispatch(getWebhooks())

        return () => {
            promise.abort();
        }
    }, [dispatch])

    // console.log(formik.values.code_mappings?.sort(sort_by_code()));
    return (
        <>
            <HeadingCrumb />

            {webhooksList.map(wbh => (
                <div key={wbh.id}>
                    <SettingsHeading variant="h5">Tracking Events Forwarding (#{wbh.id})</SettingsHeading>

                    <UICard>
                        <SettingsCard>
                            <Grid container>
                                <Grid item md={4}>
                                    <WebhookInputItem>
                                        <Typography variant="h6">Webhook Type</Typography>

                                        <FormControl>
                                            <UISelect
                                                labelId="webhook_type-label"
                                                id="webhook_type"
                                                name="webhook_type"
                                                value=""
                                                disabled
                                                displayEmpty
                                            >
                                                <MenuItem value="" disabled>{wbh.name}</MenuItem>
                                            </UISelect>
                                        </FormControl>
                                    </WebhookInputItem>

                                    <WebhookInputItem>
                                        <Typography variant="h6">Delivering to</Typography>

                                        <FormControl>
                                            <Input
                                                name="name"
                                                id="name"
                                                placeholder="Webhook"
                                                aria-describedby="primary-error-text"
                                                value={wbh.deliver_to}
                                                disabled
                                            />
                                        </FormControl>
                                    </WebhookInputItem>
                                </Grid>
                                <Grid item md={8}>
                                    <Typography variant="body1">Mappings Configuration</Typography>

                                    <SettingsDesc variant="body1">
                                        This is a custom integration. You can make some changes using the “Edit Mappings” button below.
                                    </SettingsDesc>

                                    <UIButton
                                        type="submit"
                                        color="primary"
                                        style={{ marginTop: 16 }}
                                        onClick={() => handleOpen(wbh.id)}
                                        disabled={false}
                                    >
                                        Edit Mappings
                                    </UIButton>
                                </Grid>
                            </Grid>
                        </SettingsCard>

                    </UICard>
                </div >
            ))}

            <Modal
                open={open}
                onClose={handleClose}
                style={{ overflow: 'auto' }}
                aria-labelledby="confirm-cancel-ship-modal-title"
                aria-describedby="confirm-cancel-ship-modal-description"
                keepMounted
            >
                <ModalBody>
                    <ConfirmModalHeading variant="h2">
                        <Icon style={{ display: 'inline-block', marginRight: 8 }} name="arrows" size={24} />
                        Mappings Configuration
                    </ConfirmModalHeading>
                    <Typography variant="body1" mt={2}>Use the following JSON structure to configure your mappings:</Typography>

                    <WebCodeBlock component="code">
                        {JSON.stringify(webhookObject)}
                    </WebCodeBlock>

                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <FieldArray
                                name="code_mappings"
                                render={arrayHelpers => (
                                    <>
                                        <WebCodeActions>
                                            <FormControl error={!!bxCodeError}>
                                                <UISelect
                                                    labelId="codes-label"
                                                    id="codes"
                                                    name="codes"
                                                    value={bxCode}
                                                    onChange={e => setBxCode(e.target.value as string)}
                                                    error={!!bxCodeError}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="" disabled>Codes</MenuItem>
                                                    {eventsCodes
                                                        .filter(eventCode => !formik.values.code_mappings?.some(mappedCode => +mappedCode.code === eventCode.code))
                                                        .map(eventsCode => (
                                                            <MenuItem
                                                                key={eventsCode.code}
                                                                value={eventsCode.code}
                                                            >
                                                                {eventsCode.code} - {eventsCode.description}
                                                            </MenuItem>
                                                        ))}
                                                </UISelect>
                                                {bxCodeError && <FormHelperText id="primary-error-text">Code already added</FormHelperText>}
                                            </FormControl>

                                            <UIButton
                                                type="button"
                                                color="black"
                                                disabled={!bxCode}
                                                onClick={() => {
                                                    setBxCodeError(false);

                                                    if (formik.values.code_mappings?.find(codMap => (codMap as any).code === +bxCode)) {
                                                        setBxCodeError(true);
                                                    } else {
                                                        arrayHelpers.push({
                                                            code: +bxCode,
                                                            json: ''
                                                        });

                                                        setBxCode('');
                                                    }
                                                }}
                                            >
                                                Add Mapping
                                            </UIButton>
                                        </WebCodeActions>

                                        <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                                            {formik.values.code_mappings?.map((codeMap, index) => (
                                                <WebhookCodeItem key={(codeMap as any).code}>
                                                    <WebhookCodeItemInfo>
                                                        <img src={boxcIcon} alt="logo" />
                                                        <Typography variant="body1">Code {(codeMap as any).code}</Typography>
                                                    </WebhookCodeItemInfo>

                                                    <WebhookItemAction>
                                                        <FormControl error={Boolean(formik.errors.code_mappings && (formik.errors.code_mappings[index] as any)?.json)}>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                                                <Input
                                                                    name={`code_mappings.${index}.json`}
                                                                    placeholder="JSON"
                                                                    aria-describedby="json-error-text"
                                                                    value={(codeMap as any).json}
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                />

                                                                <UIButton
                                                                    color="black"
                                                                    variant="outlined"
                                                                    type="button"
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                >
                                                                    <Icon name="trash" size={24} />
                                                                </UIButton>
                                                            </div>

                                                            {(formik.errors.code_mappings && (formik.errors.code_mappings[index] as any)?.json) && <FormHelperText id="primary-error-text">{(formik.errors.code_mappings[index] as any)?.json}</FormHelperText>}
                                                        </FormControl>
                                                    </WebhookItemAction>
                                                </WebhookCodeItem>
                                            ))}
                                        </div>
                                    </>
                                )}
                            />

                            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 16, marginTop: 30 }}>
                                <UIButton type="button" variant="outlined" onClick={handleClose}>Cancel</UIButton>
                                <UIButton type="submit" color="primary" disabled={formik.isSubmitting || !formik.isValid}>Apply</UIButton>
                            </div>
                        </form>
                    </FormikProvider>
                </ModalBody>
            </Modal>
        </>
    )
}

export default Webhooks;