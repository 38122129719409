import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UICard from 'components/UICard';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import { SettingsCard } from 'pages/Customers/settings.styled';
import { SettingBottomCard, SettingsDesc } from 'pages/OrganizationSettings/settings.styled';
import { Input } from 'components/UIForm';
import UIButton from 'components/UIButton';
import { styled } from "@mui/material/styles";
import Icon from 'components/Icon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeUserPassword, resetErrorChangeUserPassword, setErrorChangeUserPassword } from 'store/auth';

const profileSchema = Yup.object().shape({
    code: Yup.string()
        .required('Code is required'),
    current_password: Yup.string()
        .required('current_password is required'),
    new_password: Yup.string()
        .required('password is required'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required('confirm_password is required')
});

const HeadingContainer = styled('div')`
    display: flex;
    align-items: center;

    h4 {
        display: flex;
        align-items: center;
        font-size: 32px;
        color: ${props => props.theme.palette.greyOne.main};
        margin-right: 13px;

        &:last-of-type {
            color: #000;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
        text-transform: capitalize;
    }
`;

const ChangePassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { changeUserPasswordStatus } = useAppSelector(state => state.auth);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: searchParams.get('code'),
            current_password: '',
            new_password: '',
            confirm_password: ''
        },
        validationSchema: profileSchema,
        onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {
            if (values.code) {
                dispatch(changeUserPassword({
                    code: values.code,
                    old_password: values.current_password,
                    new_password: values.new_password
                })).unwrap().catch(err => {
                    console.log('err: ', err);

                    if (err.old_password) {
                        setErrors({
                            current_password: err.old_password[0] || 'Error'
                        });
                    } else if (err.code) {
                        navigate('/account/settings');
                        dispatch(setErrorChangeUserPassword());

                        const intervalId = setInterval(() => {
                            dispatch(resetErrorChangeUserPassword(intervalId));
                        }, 5000);
                    } else {
                        setErrors(err);
                    }
                });

                setSubmitting(false);
            }
        }
    });

    return (
        <>
            <HeadingContainer>
                <Typography variant="h4">Profile Settings</Typography>
                <Icon style={{ marginLeft: 13, marginRight: 13 }} name="arrow-right" size={32} />
                <Typography variant="h4">Reset Password</Typography>
            </HeadingContainer>

            <UICard style={{ marginTop: 32 }}>
                <form onSubmit={formik.handleSubmit}>
                    <SettingsCard>
                        <Typography variant="body1">Display Settings</Typography>
                        <SettingsDesc variant="body1">Thanks for confirming your email address! Now, enter your current password and you can decide on a new, secure password to sign in with.</SettingsDesc>

                        <SettingBottomCard direction="column" gap={10}>
                            <FormControl error={Boolean(formik.errors.current_password && formik.touched.current_password)}>
                                <Input
                                    type="password"
                                    name="current_password"
                                    id="current_password"
                                    placeholder="Current Password"
                                    aria-describedby="current_password-error-text"
                                    value={formik.values.current_password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.errors.current_password && formik.touched.current_password)}
                                />
                                {(formik.errors.current_password && formik.touched.current_password) && <FormHelperText id="current_password-error-text">{formik.errors.current_password}</FormHelperText>}
                            </FormControl>

                            <FormControl error={Boolean(formik.errors.new_password && formik.touched.new_password)}>
                                <Input
                                    type="password"
                                    name="new_password"
                                    id="new_password"
                                    placeholder="New Password"
                                    aria-describedby="new_password-error-text"
                                    value={formik.values.new_password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.errors.new_password && formik.touched.new_password)}
                                />
                                {(formik.errors.new_password && formik.touched.new_password) && <FormHelperText id="new_password-error-text">{formik.errors.new_password}</FormHelperText>}
                            </FormControl>

                            <FormControl error={Boolean(formik.errors.confirm_password && formik.touched.confirm_password)}>
                                <Input
                                    type="password"
                                    name="confirm_password"
                                    id="confirm_password"
                                    placeholder="Confirm New Password"
                                    aria-describedby="confirm_password-error-text"
                                    value={formik.values.confirm_password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.errors.confirm_password && formik.touched.confirm_password)}
                                />
                                {(formik.errors.confirm_password && formik.touched.confirm_password) && <FormHelperText id="confirm_password-error-text">{formik.errors.confirm_password}</FormHelperText>}
                            </FormControl>
                        </SettingBottomCard>
                    </SettingsCard>
                    <SettingsCard>
                        {changeUserPasswordStatus === 'PENDING' ? (
                            <CircularProgress color="black" size={24} thickness={6} />
                        ) : (
                            <UIButton type="submit" disabled={formik.isSubmitting || !formik.isValid}>Update Password</UIButton>
                        )}
                    </SettingsCard>
                </form>
            </UICard>
        </>
    )
}

export default ChangePassword;