import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormControl, Modal, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from "@mui/material/styles";
import UIButton from "components/UIButton";
import UICard from "components/UICard";
import { ModalBody } from "components/UIModal";
import { Input } from "components/UIForm";
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { inviteUserToTeam, removeAdmin, resetInviteUserStatus } from 'store/users';
import { SettingsCard } from "./settings.styled";
import Icon from 'components/Icon';
import { ConfirmModalContent, ConfirmModalHeading } from 'styles/modal.styled';
import theme from 'theme';

const StyledTableRow = styled(TableRow)`
    border: 2px solid #E7E7E7;
    
    & td, th {
        font-weight: 500;
        font-family: 'Cabin';
    }

    &:nth-of-type(even) {
        background-color:#F9F9F9
    }

    &:last-child td, 
    &:last-child th {
        border: 0,
    }
`;

const TeamHeader = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const InviteHeading = styled(Typography)`
    font-size: 28px;
`;

const InviteInfo = styled(Typography)`
    /* font-size: 28px; */
    font-family: 'Inter';
    font-weight: 700;
    margin-top: 24px;
`;

const InviteForm = styled('form')`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > div {
        width: 100%;
    }
`;

const InviteAccess = styled('div')`
    display: flex;
    align-items: center;
    margin-top: 12px;
`;

const InviteAccessIcon = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #E7E7E7;
`;

const InviteAccessInfo = styled('div')`
    margin-left: 16px;

    & p {
        font-size: 12px;

        &:first-of-type {
            font-weight: 700;
        }

        &:last-of-type {
            color: #808080;
        }
    }
`;

const InviteActions = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    color: rgba(0, 0, 0, 0.26);
`;

const InviteSuccessContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 24px;
    padding-bottom: 40px;
`;

const InviteSuccessInfo = styled('div')`
    margin-top: 26px;

    & > p {
        color: ${props => props.theme.palette.greyOne.main};
    }
`;

const CurrentUserText = styled('span')`
    color: ${props => props.theme.palette.greyOne.main};
`;

const inviteSchema = Yup.object().shape({
    first_name: Yup.string()
        .required('First Name is required'),
    last_name: Yup.string()
        .required('Last Name is required'),
    email: Yup.string()
        .email('Wrong email format')
        .required('Email is required')
});

const Team = () => {
    const { usersList, inviteUserStatus } = useAppSelector(({ users }) => users);
    const { user, organization } = useAppSelector(({ auth }) => auth);
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [currentSelectedUser, setCurrentSelectedUser] = React.useState<any>({});
    const dispatch = useAppDispatch();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { email: '', first_name: '', last_name: '' },
        validationSchema: inviteSchema,
        onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {
            dispatch(inviteUserToTeam(values)).unwrap().catch(err => {
                if (Array.isArray(err)) {
                    setErrors({
                        email: err[0]
                    })
                }
                // console.log('err: ', err);
            }).finally(() => {
                setSubmitting(false);
            });
        }
    });

    const handleCloseConfirm = () => setOpenConfirm(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        formik.resetForm();
        dispatch(resetInviteUserStatus())
    };

    const handleRemoveUser = () => {
        dispatch(removeAdmin({
            id: currentSelectedUser.id,
            data: {
                is_active: false
            }
        }));

        handleCloseConfirm()
    };


    const handleInviteDone = () => {
        dispatch(resetInviteUserStatus())
        handleClose()
    }

    const handleRemoveConfirm = (id: string) => {
        const usr = usersList.find(u => u.id === id);
        setCurrentSelectedUser(usr);
        setOpenConfirm(true);
    }

    const handleInviteOther = () => {
        dispatch(resetInviteUserStatus())
        formik.resetForm();
    }

    const handleResend = (id: string) => {
        const user = usersList.find(u => u.id === id);

        if (user) {
            dispatch(inviteUserToTeam({
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name
            })).unwrap().then(res => {
                handleOpen()
            })
        }
    }

    // React.useEffect(() => {
    //     dispatch(resetInviteUserStatus())
    // }, [dispatch])

    return (
        <UICard>
            <SettingsCard>
                <TeamHeader>
                    <Typography variant="body1">Manage Team</Typography>
                    <UIButton variant="outlined" onClick={handleOpen}>Invite</UIButton>
                </TeamHeader>

                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email Address</TableCell>
                                <TableCell align="left">Role</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usersList.filter(user => user.is_active).map((row, index, arr) => (
                                <StyledTableRow
                                    key={row.id}
                                >
                                    <TableCell component="th" scope="row">
                                        <span>{row.full_name}</span> {user?.id === row.id && <CurrentUserText>(You)</CurrentUserText>}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.email}
                                    </TableCell>
                                    {/* envelope-close */}
                                    <TableCell align="left">
                                        <span style={{ textTransform: 'capitalize' }}>
                                            {row.role}
                                        </span>
                                        {!row.accepted_invite && (
                                            // <span style={{ display: 'flex', alignItems: 'center', gap: 8, color: theme.palette.greyOne.main }}>
                                            <span style={{ color: theme.palette.greyOne.main }}>
                                                <Icon style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: 16, marginRight: 8 }} name="envelope-close" size={16} masked={true} color="greyOne" />
                                                <span style={{ verticalAlign: 'middle' }}>Pending</span>
                                            </span>
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {!row.accepted_invite && (
                                            <UIButton
                                                onClick={() => handleResend(row.id)}
                                                variant="outlined"
                                                style={{ marginRight: 10 }}
                                                startIcon={<Icon name="envelope-close" size={16} masked={true} color="black" />}
                                            >
                                                Re-send
                                            </UIButton>
                                        )}
                                        {arr.length > 1 ? (
                                            <UIButton
                                                variant="outlined"
                                                // onClick={() => handleRemoveUser(row.id)}
                                                onClick={() => handleRemoveConfirm(row.id)}
                                                disabled={user?.id === row.id}
                                            >
                                                Remove
                                            </UIButton>
                                        ) : null}
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </SettingsCard>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <ModalBody>
                    {inviteUserStatus !== 'SUCCESS' && (
                        <>
                            <InviteHeading variant="h2">Invite new admin</InviteHeading>

                            <InviteForm id="invite-form" onSubmit={formik.handleSubmit}>
                                <FormControl error={Boolean(formik.errors.first_name && formik.touched.first_name)}>
                                    <Input
                                        name="first_name"
                                        id="first_name"
                                        placeholder="First Name"
                                        aria-describedby="first_name-error-text"
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.errors.first_name && formik.touched.first_name)}
                                    />
                                    {(formik.errors.first_name && formik.touched.first_name) && <FormHelperText id="first_name-error-text">{formik.errors.first_name}</FormHelperText>}
                                </FormControl>

                                <FormControl error={Boolean(formik.errors.last_name && formik.touched.last_name)}>
                                    <Input
                                        name="last_name"
                                        id="last_name"
                                        placeholder="Last Name"
                                        aria-describedby="last_name-error-text"
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.errors.last_name && formik.touched.last_name)}
                                    />
                                    {(formik.errors.last_name && formik.touched.last_name) && <FormHelperText id="last_name-error-text">{formik.errors.last_name}</FormHelperText>}
                                </FormControl>

                                <FormControl error={Boolean(formik.errors.email && formik.touched.email)}>
                                    <Input
                                        name="email"
                                        id="email"
                                        placeholder="Email Address"
                                        aria-describedby="email-error-text"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.errors.email && formik.touched.email)}
                                    />
                                    {(formik.errors.email && formik.touched.email) && <FormHelperText id="email-error-text">{formik.errors.email}</FormHelperText>}
                                </FormControl>
                            </InviteForm>

                            <InviteInfo variant="body1">General access</InviteInfo>

                            <InviteAccess>
                                <InviteAccessIcon>
                                    <Icon name="lock-locked" masked={true} color="black" size={12} />
                                </InviteAccessIcon>

                                <InviteAccessInfo>
                                    <Typography variant="body1">Restricted</Typography>
                                    <Typography variant="body1">Only people invited here can join your account</Typography>
                                </InviteAccessInfo>
                            </InviteAccess>

                            <InviteInfo variant="body1">Link sharing</InviteInfo>

                            <InviteActions>
                                <UIButton type="button" startIcon={<Icon name="link" masked={true} size={16} color="disabled" />} disabled={true}>Copy link</UIButton>
                                <UIButton type="submit" color="primary" form="invite-form" disabled={formik.isSubmitting || !formik.isValid}>Invite</UIButton>
                            </InviteActions>
                        </>
                    )}

                    {inviteUserStatus === 'SUCCESS' && (
                        <>
                            <InviteSuccessContainer>
                                <Icon name="invite-success" size={150} />

                                <InviteSuccessInfo>
                                    <InviteHeading variant="h2">Success! Invite Sent</InviteHeading>
                                    {/* <Typography variant="body1">{formik.values.email}</Typography> */}
                                    <Typography variant="body1">{formik.values.email}</Typography>
                                </InviteSuccessInfo>
                            </InviteSuccessContainer>

                            <InviteActions>
                                <UIButton variant="outlined" type="button" onClick={handleInviteOther}>Invite Another</UIButton>
                                <UIButton type="button" color="primary" onClick={handleInviteDone}>Done</UIButton>
                            </InviteActions>
                        </>
                    )}
                </ModalBody>
            </Modal>

            <Modal
                open={openConfirm}
                onClose={handleCloseConfirm}
                style={{ overflow: 'auto' }}
                aria-labelledby="confirm-cancel-ship-modal-title"
                aria-describedby="confirm-cancel-ship-modal-description"
                keepMounted
            >
                <ModalBody>
                    <ConfirmModalHeading variant="h2">Confirmation</ConfirmModalHeading>

                    <ConfirmModalContent>
                        <Typography variant="body1">
                            Are you sure you want to remove {currentSelectedUser.first_name} {currentSelectedUser.last_name} ({currentSelectedUser.email}) as an admin from {organization?.business_name}? They will immediately lose access to the admin panel, and you will need to re-invite them to grant them access again.
                        </Typography>
                    </ConfirmModalContent>

                    <UIButton onClick={handleRemoveUser} fullWidth>Confirm and Remove</UIButton>
                    <UIButton variant="outlined" style={{ marginTop: 8 }} onClick={handleCloseConfirm} fullWidth>Cancel</UIButton>
                </ModalBody>
            </Modal>
        </UICard>
    )
}

export default Team