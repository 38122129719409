import axios from "axios";
import api from "../index";
import { withAsync } from "utils";
import {
  SignInUserResponse,
  SignInUserPayload,
  UpdateOrganizationResponse,
  UpdateOrganizationPayload,
  GetOrganizationResponse,
  SignInImageCreateResponse,
  UpdateOrganizationProviderConfig,
  UpdateOrganizationProviderConfigResponse,
  GetProvidersResponse,
  GetProviderServicesResponse,
  GetMappedServicesResponse,
  AddMappedServicesResponse,
  AddMappedServicesPayload,
  SignUpOrganizationUPayload,
  SetPasswordResetPayload,
  GetUserSetupGuideResponse,
  UpdateUserSetupGuideResponse,
  UpdateUserSetupGuidePayload,
  UpdateUserPayload,
  UpdateUserResponse,
  GetUserResponse,
  GetInvoicesResponse,
  GetWebhooksListResponse,
  UpdateWebhookMappingPayload,
  GetWebhookMappingResponse,
} from "./types";
import { ProviderServices } from "store/auth/types";

export const userLogin = (data: SignInUserPayload) => {
  return api
    .post<SignInUserResponse>("/auth/token", data)
    .then((res) => res.data);
};

export const refreshAuthToken = (refreshToken: string) => {
  return api
    .post<{ access: string; refresh: string }>("/auth/token/refresh", {
      refresh: refreshToken,
    })
    .then((res) => res.data);
};

export const organizationSignUp = (data: SignUpOrganizationUPayload) => {
  return api
    .post<{ business_name: string }>("/organizations", data)
    .then((res) => res.data);
};

export const userVerify = (data: { code: string }, thunkAPI: any) => {
  return api
    .post<{ code: string }>("/auth/users/verify", data, {
      signal: thunkAPI.signal,
    })
    .then((res) => res.data);
};

export const passwordReset = (data: { email: string }, thunkAPI: any) => {
  return api
    .post<{ code: string }>("/auth/users/forget_password", data, {
      signal: thunkAPI.signal,
    })
    .then((res) => res.data);
};

export const setPasswordReset = (
  data: SetPasswordResetPayload,
  thunkAPI: any
) => {
  return api
    .post<{ code: string }>("/auth/users/reset_password", data)
    .then((res) => res.data);
};

export const changePasswordRequest = () => {
  return api
    .post<{ detail: string }>("/auth/users/reset_password_request", {})
    .then((res) => res.data);
};

export const changePassword = (
  data: { old_password: string; new_password: string; code: string },
  thunkAPI: any
) => {
  return api
    .post<{ detail: string }>("/auth/users/change_password", data)
    .then((res) => res.data)
    .catch((err) => {
      throw thunkAPI.rejectWithValue((err as any).response?.data);
    });
};

export const updateOrganization = async (data: UpdateOrganizationPayload) => {
  if (data.file) {
    const { response } = await withAsync(() =>
      api.post<SignInImageCreateResponse>("/images/create", {
        file_path: `organization_logo/${data.logo}`,
      })
    );

    if (response) {
      const { error } = await withAsync(() =>
        axios.put(response.data.presigned_url, data.file, {
          headers: { "Content-Type": "image/jpeg" },
        })
      );

      if (error) {
        throw new Error("Error updating organization!");
      } else {
        data.logo = response.data.id;
      }
    } else {
      throw new Error("Error updating organization!");
    }
  } else {
    delete data.file;
    delete data.logo;
  }

  return api
    .patch<UpdateOrganizationResponse>("/organizations", data)
    .then((res) => res.data);
};

export const getOrganization = (arg = {}, thunkAPI: any) => {
  return api
    .get<GetOrganizationResponse>("/organizations", { signal: thunkAPI.signal })
    .then((res) => res.data);
};

export const updateOrganizationProviderConfig = (
  data: UpdateOrganizationProviderConfig,
  thunkAPI: any
) => {
  const updateData = { ...data };
  delete updateData.providerInfo;

  return api
    .post<UpdateOrganizationProviderConfigResponse>(
      "/organizations/provider_auth_configs",
      updateData,
      {
        signal: thunkAPI.signal,
      }
    )
    .then((res) => {
      return {
        id: res.data.id || crypto.randomUUID(),
        id_token: res.data.id_token,
        access_token: res.data.access_token,
        access_token_expired_at: res.data.access_token_expired_at,
        custom_settings: res.data.custom_settings,
        provider: data.providerInfo,
        created: res.data.created || new Date().toISOString(),
      };
    });
};

export const getAllProviders = (data: string | undefined, thunkAPI: any) => {
  return api
    .get<GetProvidersResponse>("/providers", { signal: thunkAPI.signal })
    .then((res) => res.data);
};

export const getAllProviderServices = async (
  id: string
): Promise<ProviderServices> => {
  const { response } = await withAsync(() =>
    api.get<GetProviderServicesResponse>(`/providers/${id}/provider_services`)
  );

  if (response) {
    return {
      [id]: {
        services: response.data,
      },
    };
  }

  throw new Error("Error fetching services!");
};

export const getAllMappedServices = () => {
  return api
    .get<GetMappedServicesResponse>("/organizations/shipment_service_mappings")
    .then((res) => res.data);
};

export const addServicesMapping = (data: AddMappedServicesPayload[]) => {
  return api
    .post<AddMappedServicesResponse>(
      "/organizations/shipment_service_mappings",
      data
    )
    .then((res) => res.data);
};

export const removeServicesMapping = (id: string) => {
  return api
    .delete(`/organizations/shipment_service_mappings/${id}`)
    .then((res) => ({ id: id }));
};

export const getServicesOptions = () => {
  return api
    .get<
      {
        has_insurance: boolean;
        has_signature_confirmation: boolean;
        billing_reconciliation_assistant: boolean;
        shipments_cancellation_days: string | null;
      }[]
    >("/organizations/shipment_configs")
    .then((res) => {
      return {
        has_insurance: res.data[0].has_insurance || false,
        has_signature_confirmation:
          res.data[0].has_signature_confirmation || false,
        billing_reconciliation_assistant:
          res.data[0].billing_reconciliation_assistant || false,
        shipments_cancellation_days: res.data[0].shipments_cancellation_days,
      };
    });
};

export const updateServicesOptions = (data: {
  provider: string;
  has_insurance: boolean;
  has_signature_confirmation: boolean;
  billing_reconciliation_assistant: boolean;
  shipments_cancellation_days: string | null;
}) => {
  return api
    .post<{
      has_insurance: boolean;
      has_signature_confirmation: boolean;
      billing_reconciliation_assistant: boolean;
      shipments_cancellation_days: string | null;
    }>("/organizations/shipment_configs", data)
    .then((res) => res.data);
};

export const verifyDNS = () => {
  return api
    .get<{
      is_domain_verified: boolean;
      is_api_domain_verified: boolean;
    }>("/organizations/verify_dns")
    .then((res) => res.data);
};

export const getUserSetupGuide = (id: string | undefined) => {
  return api
    .get<GetUserSetupGuideResponse>("/user_setup_guides/" + id)
    .then((res) => res.data);
};

export const updateUserSetupGuide = (payload: UpdateUserSetupGuidePayload) => {
  return api
    .patch<UpdateUserSetupGuideResponse>("/user_setup_guides/" + payload.id, {
      setup_metadata: payload.setup_metadata,
    })
    .then((res) => res.data);
};

export const updateUser = (payload: UpdateUserPayload, thunkAPI: any) => {
  const token = payload.token;

  delete payload.token;
  return api
    .patch<UpdateUserResponse>("/users/me", payload, {
      headers: { "X-PAYLOAD-TOKEN": token },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw thunkAPI.rejectWithValue((err as any).response?.data);
    });
};

export const getCurrentUser = (
  { token }: { token?: string },
  thunkAPI: any
) => {
  return api
    .get<GetUserResponse>("/users/me", {
      headers: { "X-PAYLOAD-TOKEN": token },
      signal: thunkAPI.signal,
    })
    .then((res) => res.data);
};

export const getCustormerInvoices = () => {
  return api.get<GetInvoicesResponse>("/invoices").then((res) => res.data);
};

export const getWebhooksList = (arg = {}, thunkAPI: any) => {
  return api
    .get<GetWebhooksListResponse>("/webhook-gateways", {
      signal: thunkAPI.signal,
    })
    .then((res) => res.data);
};

export const updateWebhookMapping = ({
  id,
  ...payload
}: UpdateWebhookMappingPayload) => {
  return api
    .patch(`/webhook-gateways/${id}/mapping`, { ...payload })
    .then((res) => res.data);
};

export const getWebhookMapping = (id: string) => {
  return api
    .get<GetWebhookMappingResponse>(`/webhook-gateways/${id}/mapping`)
    .then((res) => res.data);
};
