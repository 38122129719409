import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Button from 'components/UIButton';
import UICard from 'components/UICard';
import { Input } from 'components/UIForm';
import {
    AuthHeading,
    AuthInfoHeading,
    AuthContnetContainer,
    AuthFormContainer,
    AuthForm,
    AuthActions,
    AuthLink,
    AuthLinkLight,
    AuthError,
    EyeAdornment
} from './sigin.styled';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { signInUser } from 'store/auth';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { withAsync } from 'utils';
import Icon from 'components/Icon';
// import { InputAdornment } from '@mui/material';

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required'),
});

const SignIn = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { logInUserStatus, updateUserDetailsStatus } = useAppSelector(state => state.auth);

    const formik = useFormik({
        initialValues: { email: '', password: '' },
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setErrors, setSubmitting }) => {
            const { response } = await withAsync(() => dispatch(signInUser(values)));

            if ((response as any).error) {
                setErrors({ password: 'Your login information was not recognized.' });
            } else {
                navigate('/');
            }

            setSubmitting(false);
        }
    });

    return (
        <AuthContnetContainer>
            {updateUserDetailsStatus === 'ERROR' && (
                <UICard style={{ marginBottom: 16 }} padding="p2">
                    <AuthError>
                        <Icon name="warning" size={64} />
                        <Typography variant="body1">The link you clicked has expired. Please contact your administrator to re-send your invite.</Typography>
                    </AuthError>
                </UICard>
            )}
            <UICard padding="p4" style={{ display: 'flex', flexDirection: 'column', gap: 40, textAlign: 'center' }}>
                <div>
                    <AuthHeading variant="h5">Sign In</AuthHeading>
                    <AuthInfoHeading variant="h6">To continue to portal</AuthInfoHeading>
                </div>

                <AuthFormContainer>
                    <AuthForm id="login-form" onSubmit={formik.handleSubmit}>
                        <FormControl error={Boolean(formik.errors.email && formik.touched.email)}>
                            <Input
                                name="email"
                                id="email"
                                placeholder="Email Address"
                                aria-describedby="email-error-text"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.email && formik.touched.email)}
                            />
                            {(formik.errors.email && formik.touched.email) && <FormHelperText id="email-error-text">{formik.errors.email}</FormHelperText>}
                        </FormControl>

                        <FormControl error={Boolean(formik.errors.password && formik.touched.password)}>
                            <Input
                                name="password"
                                id="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                aria-describedby="password-error-text"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.password && formik.touched.password)}
                                InputProps={{
                                    endAdornment: (
                                        <EyeAdornment position="end" style={{ background: '#fff' }}>
                                            {showPassword ? (
                                                <Icon name="eye-cross" masked={true} color="greyOne" size={16} style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />

                                            ) : (
                                                <Icon name="eye" masked={true} color="greyOne" size={16} style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
                                            )}
                                        </EyeAdornment>
                                    )
                                }}
                            />
                            {(formik.errors.password && formik.touched.password) && <FormHelperText id="password-error-text">{formik.errors.password}</FormHelperText>}
                        </FormControl>
                    </AuthForm>

                    <AuthLinkLight to="/auth/reset">Forgot Password?</AuthLinkLight>
                </AuthFormContainer>

                <AuthActions style={{ height: 40 }}>
                    <AuthLink to="/auth/signup">Create Account</AuthLink>
                    {logInUserStatus === 'PENDING' ? (
                        <CircularProgress color="black" size={24} thickness={6} />
                    ) : (
                        <Button type="submit" variant="contained" color="primary" form="login-form" disabled={formik.isSubmitting || !formik.isValid}>Next</Button>
                    )}
                </AuthActions>
            </UICard>
            {/* <AuthLinkLight to="/">Developer docs</AuthLinkLight> */}
        </AuthContnetContainer>
    )
}

export default SignIn;