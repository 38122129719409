import * as React from 'react';
// import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import jwtDecode from 'jwt-decode';
import UICard from 'components/UICard';
import Button from 'components/UIButton';
import {
    AuthContnetContainer,
    AuthHeading,
    AuthInfoHeading,
    AuthFormContainer,
    AuthForm,
    AuthActions,
    // AuthLink
} from 'pages/SignIn/sigin.styled';
import { FormControl, FormHelperText, CircularProgress } from '@mui/material';
import { Input } from 'components/UIForm';
import { signInUser, updateUserDetails } from 'store/auth';
import { useAppDispatch } from 'store/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { User } from 'store/auth/types';
import { withAsync } from 'utils';

interface TokenPayload {
    code: string
    email: string
    exp: number
    first_name: string
    iat: number
    last_name: string
    token_type: string
    user_id: string
}

const signInSchema = Yup.object().shape({
    first_name: Yup.string()
        .required('First name is required'),
    last_name: Yup.string()
        .required('Last name is required'),
    token: Yup.string(),
    email: Yup.string()
        .email('Wrong email format')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

const AcceptInvitation = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [user, setUser] = React.useState<Pick<User, 'email' | 'first_name' | 'last_name'> | null>(null);
    const dispatch = useAppDispatch();
    // const { updateUserDetailsStatus } = useAppSelector(state => state.auth);

    const formik = useFormik({
        initialValues: {
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
            email: user?.email || '',
            token: searchParams.get('token') || '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: signInSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {
            const { email, first_name, last_name, token, password } = values;

            dispatch(updateUserDetails({
                email: email,
                first_name: first_name,
                last_name: last_name,
                token: token,
                password: password
            })).unwrap().then(async () => {
                formik.resetForm();

                const { response } = await withAsync(() => dispatch(signInUser({
                    email: formik.values.email,
                    password: password
                })));

                if (!(response as any).error) {
                    navigate('/');
                }
            }).catch(err => {
                if (Array.isArray(err)) {
                    setErrors({ password: err[0] || 'Error' });
                } else if (err.password) {
                    setErrors({ password: err.password[0] || 'Error' });
                    setErrors({ password: err.password[0] || 'Error' });
                } else {
                    navigate('/auth/signin')
                }
            }).finally(() => {
                setSubmitting(false);
            });
        }
    });

    // React.useEffect(() => {
    //     const promise = dispatch(getCurrentTokenUserDetails({
    //         token: searchParams.get('token') || ''
    //     }));

    //     promise.unwrap().then((res) => {
    //         setUser(res);
    //     }).catch(err => {
    //         navigate('/auth/signin')
    //     });
    // }, [dispatch, navigate, searchParams])

    React.useEffect(() => {
        const token = searchParams.get('token');

        if (token) {
            const decode: TokenPayload = jwtDecode(token);

            setUser({
                email: decode.email,
                first_name: decode.first_name,
                last_name: decode.last_name
            })
        }
    }, [searchParams])

    return (
        <AuthContnetContainer>
            <UICard padding="p4" style={{ display: 'flex', flexDirection: 'column', gap: 40, textAlign: 'center' }}>
                <div>
                    <AuthHeading variant="h5">Sign Up</AuthHeading>
                    <AuthInfoHeading variant="h6">To continue to portal</AuthInfoHeading>
                </div>

                <AuthFormContainer>
                    <AuthForm id="sigup-form" onSubmit={formik.handleSubmit}>
                        <FormControl error={Boolean(formik.errors.first_name && formik.touched.first_name)}>
                            <Input
                                name="first_name"
                                id="first_name"
                                placeholder="First Name"
                                aria-describedby="first_name-error-text"
                                value={formik.values.first_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.first_name && formik.touched.first_name)}
                            />
                            {(formik.errors.first_name && formik.touched.first_name) && <FormHelperText id="first_name-error-text">{formik.errors.first_name}</FormHelperText>}
                        </FormControl>

                        <FormControl error={Boolean(formik.errors.last_name && formik.touched.last_name)}>
                            <Input
                                name="last_name"
                                id="last_name"
                                placeholder="Last Name"
                                aria-describedby="last_name-error-text"
                                value={formik.values.last_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.last_name && formik.touched.last_name)}
                            />
                            {(formik.errors.last_name && formik.touched.last_name) && <FormHelperText id="last_name-error-text">{formik.errors.last_name}</FormHelperText>}
                        </FormControl>

                        <FormControl error={Boolean(formik.errors.email && formik.touched.email)}>
                            <Input
                                name="email"
                                id="email"
                                placeholder="Work Email"
                                aria-describedby="email-error-text"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.email && formik.touched.email)}
                                autoComplete="off"
                                disabled
                            />
                            {(formik.errors.email && formik.touched.email) && <FormHelperText id="email-error-text">{formik.errors.email}</FormHelperText>}
                        </FormControl>

                        <FormControl error={Boolean(formik.errors.password && formik.touched.password)}>
                            <Input
                                name="password"
                                id="password"
                                type="password"
                                placeholder="Password"
                                aria-describedby="password-error-text"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.password && formik.touched.password)}
                                autoComplete="off"
                            />
                            {(formik.errors.password && formik.touched.password) && <FormHelperText id="password-error-text">{formik.errors.password}</FormHelperText>}
                        </FormControl>

                        {/* {`${formik.touched.password}`} */}
                        {formik.values.password && (
                            <FormControl error={Boolean(formik.errors.confirmPassword && formik.touched.confirmPassword)}>
                                <Input
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    type="password"
                                    placeholder="Confirm Password"
                                    aria-describedby="confirmPassword-error-text"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.errors.confirmPassword && formik.touched.confirmPassword)}
                                />
                                {(formik.errors.confirmPassword && formik.touched.confirmPassword) && <FormHelperText id="confirmPassword-error-text">{formik.errors.confirmPassword}</FormHelperText>}
                            </FormControl>
                        )}
                    </AuthForm>
                </AuthFormContainer>

                <AuthActions style={{ justifyContent: 'flex-end' }}>
                    {/* <AuthLink to="/auth/signin">Sign In Instead</AuthLink> */}
                    {formik.isSubmitting ? (
                        <CircularProgress color="black" size={24} thickness={6} />
                    ) : (
                        <Button type="submit" variant="contained" color="primary" form="sigup-form" disabled={formik.isSubmitting || !formik.isValid}>Confirm Details</Button>
                    )}
                </AuthActions>
            </UICard>
        </AuthContnetContainer>
    )
}

export default AcceptInvitation;