import React from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import { styled } from '@mui/material/styles';

const SelectStyled = styled(Select)`
    border-radius: 6px;
    width: 100%;
    min-width: 256px;

    & input::placeholder {
        color: ${props => props.theme.palette.greyOne.main};
        font-weight: 600;
    }
    
    & input {
        padding: 10px 16px;
        font-weight: 600;
    }

    fieldset {
        border-color: ${(props: any) => !props.error && '#E7E7E7'} !important;
    }

    &.Mui-disabled {
        background-color: #DDDDDD;
    }
`;

const UISelect: React.FC<SelectProps> = React.forwardRef(({ ...props }, ref) => {
    return (
        <SelectStyled ref={ref} {...props} />
    )
})

export default UISelect;