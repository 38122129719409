import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { InputAdornment } from '@mui/material';

export const AuthNav = styled('nav')`
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 28px;
    padding-bottom: 28px;
    border-bottom: 1px solid #E7E7E7;
`;

export const AuthLogo = styled('img')` 
    width: 180px;
`;

export const AuthNavHeading = styled(Typography)`
    margin-left: auto;
    font-family: 'Cabin';
    font-weight: 600;
`;

export const AuthHeading = styled(Typography)`
    margin-left: auto;
    font-family: 'Cabin';
    font-weight: 600;
`;

export const AuthInfoHeading = styled(Typography)`
    margin-left: auto;
    font-family: 'Cabin';
    font-weight: 600;
`;

export const AuthContnetContainer = styled('div')`
    width: 340px;
    margin: 0 auto;
    /* margin-top: 200px; */
    margin-top: 80px;
`;

export const AuthFormContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

export const AuthForm = styled('form')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    & > div {
        width: 100%;
    }

    & > div:not(:last-child) {
        margin-bottom: 16px;
    }
`;

export const AuthActions = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const AuthLink = styled(Link)`
    text-decoration: none;
    color: #000;
    font-family: 'Inter', 'Cabin';
    font-weight: 600;
    font-size: 12px;
`;

export const AuthLinkLight = styled(AuthLink)`
    color: ${props => props.theme.palette.greyOne.main};
`;

export const AuthError = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
`;

export const EyeAdornment = styled(InputAdornment)`
    background: #fff;
`;