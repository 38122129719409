import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const CustomerHeading = styled(Typography)`
  color: #000;
  font-size: 24px;
  margin-bottom: 32px;
  margin-top: 32px;
`;

export const DetailHeading = styled(Typography)`
  color: #000;
  font-size: 20px;
  margin-bottom: 16px;
`;

export const ShipmentHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;