import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PageHeading } from 'components/Heading';
import UICard from 'components/UICard';
import { SettingsCard } from 'pages/Customers/settings.styled';
import { ResetInfo, SettingBottomCard, SettingsDesc, SettingsHeading } from 'pages/OrganizationSettings/settings.styled';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import UIButton from 'components/UIButton';
import { Input } from 'components/UIForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeUserPasswordRequest, resetChangeUserPasswordRequestStatus, updateUserDetails } from 'store/auth';
import Icon from 'components/Icon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const profileSchema = Yup.object().shape({
    email: Yup.string(),
    // .required('Email Address is required'),
    first_name: Yup.string(),
    // .required('first_name Line 1 is required'),
    last_name: Yup.string(),
    // .required('last_name Code is required')
});

const AccountSettings = () => {
    const { user, changeUserPasswordRequestStatus, changeUserPasswordInvalidStatus } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: user?.email || '',
            first_name: user?.first_name || '',
            last_name: user?.last_name || ''
        },
        validationSchema: profileSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            const { first_name, last_name } = values;
            // await dispatch(updateUserOrganization({ billing_address: values }));
            await dispatch(updateUserDetails({
                first_name: first_name,
                last_name: last_name
            }));

            setSubmitting(false);
        }
    });

    const handleResetRequest = () => {
        dispatch(changeUserPasswordRequest()).unwrap().finally(() => {
            let intervalId = setInterval(() => {
                dispatch(resetChangeUserPasswordRequestStatus(intervalId));
            }, 5000);
        });
    }

    return (
        <>
            <PageHeading variant="h4">Profile Settings</PageHeading>

            <SettingsHeading variant="h5">Profile</SettingsHeading>

            <UICard>
                <form onSubmit={formik.handleSubmit}>
                    <SettingsCard>
                        <Typography variant="body1">Display Settings</Typography>
                        <SettingsDesc variant="body1">Your name will be used (and shown) only to other administrators in your organization.</SettingsDesc>

                        <SettingBottomCard direction="column" gap={10}>
                            <FormControl error={Boolean(formik.errors.first_name && formik.touched.first_name)}>
                                <Input
                                    name="first_name"
                                    id="first_name"
                                    placeholder="First Name"
                                    aria-describedby="primary-error-text"
                                    value={formik.values.first_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.errors.first_name && formik.touched.first_name)}
                                />
                                {(formik.errors.first_name && formik.touched.first_name) && <FormHelperText id="primary-error-text">{formik.errors.first_name}</FormHelperText>}
                            </FormControl>

                            <FormControl error={Boolean(formik.errors.last_name && formik.touched.last_name)}>
                                <Input
                                    name="last_name"
                                    id="last_name"
                                    placeholder="Last Name"
                                    aria-describedby="primary-error-text"
                                    value={formik.values.last_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.errors.last_name && formik.touched.last_name)}
                                />
                                {(formik.errors.last_name && formik.touched.last_name) && <FormHelperText id="primary-error-text">{formik.errors.last_name}</FormHelperText>}
                            </FormControl>
                        </SettingBottomCard>

                    </SettingsCard>
                    <SettingsCard>
                        <Typography variant="body1">Account Email Address</Typography>
                        <SettingsDesc variant="body1">For your security, we do not currently allow you to change your registered email address. To do so, you will need to add a new administrator to your account and remove your old (current) user.</SettingsDesc>

                        <SettingBottomCard>
                            <FormControl error={Boolean(formik.errors.email && formik.touched.email)}>
                                <Input
                                    name="email"
                                    id="email"
                                    placeholder="Email Address"
                                    aria-describedby="primary-error-text"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.errors.email && formik.touched.email)}
                                    disabled={true}
                                />
                                {(formik.errors.email && formik.touched.email) && <FormHelperText id="primary-error-text">{formik.errors.email}</FormHelperText>}
                            </FormControl>
                        </SettingBottomCard>
                    </SettingsCard>
                    <SettingsCard>
                        <UIButton type="submit" disabled={formik.isSubmitting || !formik.isValid}>Save Profile</UIButton>
                    </SettingsCard>
                </form>
            </UICard>

            <SettingsHeading variant="h5">Security</SettingsHeading>

            <UICard>
                <SettingsCard>
                    <Typography variant="body1">Reset your Password</Typography>
                    <SettingsDesc variant="body1">For your security, we will need to validate your email address before you can reset your password.</SettingsDesc>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 40 }}>
                        <UIButton onClick={handleResetRequest} variant="outlined">Send me a Reset Link</UIButton>

                        {changeUserPasswordRequestStatus === 'SUCCESS' && (
                            <ResetInfo>
                                <Icon name="success" size={16} />
                                <Typography variant="body1">Reset Link sent to your email address</Typography>
                            </ResetInfo>
                        )}
                    </div>
                </SettingsCard>
            </UICard>


            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={changeUserPasswordInvalidStatus === 'ERROR'}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <Alert
                        color="error"
                        variant="filled"
                        icon={<CheckCircleOutlineIcon fontSize="inherit" />}
                    >
                        <>
                            <AlertTitle>Error</AlertTitle>
                            {"Invalid or expire link"}
                        </>
                    </Alert>
                </div>
            </Snackbar>
        </>
    )
}

export default AccountSettings;
