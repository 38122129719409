const extractTrackingNumberFromBarCode = (input: string) => {
    if (input.length > 31) {
        /**
         * AUPost 41
         * Get 23 character from right
         */
        return input.slice(-23);
    } else if (input.length > 28 && input.length <= 30) {
        /**
         * USPS 30
         * Get 22 character from right
         */
        return input.slice(-22);
    } else if (input.length === 28) {
        // CA Post 28
        return input.slice(0, 23).slice(-16);
    } else {
        // CA Post 16
        return input;
    }
}

export default extractTrackingNumberFromBarCode;