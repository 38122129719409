import {
    configureStore,
    combineReducers,
    // createAction
} from '@reduxjs/toolkit'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import authReducer from './auth';
import usersReducer from './users';
import customersReducer from './customers';
import wmsReducer, { wmsApiSlice } from './wms';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: ['auth', 'customers', 'wms', wmsApiSlice.reducerPath]
}

const authPersistConfig = {
    key: 'auth',
    version: 1,
    storage,
    blacklist: ['signUpOrganizationStatus', 'resetSetPasswordStatus', 'updateUserDetailsStatus']
}

const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    users: usersReducer,
    customers: customersReducer,
    wms: wmsReducer,
    [wmsApiSlice.reducerPath]: wmsApiSlice.reducer
});

// const initialState = rootReducer({}, {})

// export const resetStore = createAction('resetStore')

// const appReducer: typeof rootReducer = (state, action) => {
//     if (action.type === resetStore.type) {
//         return rootReducer(undefined, action)
//     }

//     return rootReducer(state, action)
// }

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            }
        }).concat(wmsApiSlice.middleware)
})

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type ApiStatus = 'IDLE' | 'PENDING' | 'SUCCESS' | 'ERROR'

export default store;