import { Routes, Route } from 'react-router-dom';
import AuthLayout from 'layouts/AuthLayout';

import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import Verify from 'pages/Verify';
import PasswordReset from 'pages/PasswordReset';
import Forgot from 'pages/Forgot';
import AcceptInvitation from 'pages/AcceptInvitation';

const AuthRoutes = () => {
    return (
        <Routes>
            <Route element={<AuthLayout />}>
                <Route path='signin' element={<SignIn />} />
                <Route path='signup' element={<SignUp />} />
                <Route path='verify' element={<Verify />} />
                <Route path='reset' element={<PasswordReset />} />
                <Route path='forgot' element={<Forgot />} />
                <Route path='invitation' element={<AcceptInvitation />} />
                {/* <Route index element={<SignIn />} /> */}
            </Route>
        </Routes>
    )
}

export default AuthRoutes;