import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { styled } from "@mui/material/styles";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from 'components/UIButton';
import UICard from 'components/UICard';
import { Input } from 'components/UIForm';
import Icon from "components/Icon";
import {
    AuthHeading,
    AuthContnetContainer,
    AuthFormContainer,
    AuthForm,
    AuthActions
} from 'pages/SignIn/sigin.styled';
import { CircularProgress, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetpassword } from 'store/auth';

const SigupCompleteHeading = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    & > h5 {
        color: #000;
        margin-left: 8px;
    }
`;

const TypographyInfo = styled(Typography)`
    font-size: 20px;

    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
`;

const WarningCard = styled(UICard)`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px;

    & > p {
        margin-left: 16px;
    }
`;

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .required('Email is required'),
});

const PasswordReset = () => {
    const dispatch = useAppDispatch();
    const [isNext, setIsNext] = React.useState(false);
    const { resetpasswordStatus, resetSetPasswordStatus } = useAppSelector(state => state.auth);

    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            await dispatch(resetpassword(values));
            setSubmitting(false)
            setIsNext(true);
        }
    });

    return (
        <AuthContnetContainer>
            {resetSetPasswordStatus === 'ERROR' ? (
                <WarningCard>
                    <div>
                        <Icon name="warning" size={32} />
                    </div>

                    <Typography variant="body1">Something went wrong while resetting your password. Try again here.</Typography>
                </WarningCard>
            ) : null}

            {!isNext ? (
                <UICard padding="p4" style={{ display: 'flex', flexDirection: 'column', gap: 40, textAlign: 'center' }}>
                    <div>
                        <AuthHeading variant="h5">Password Recovery</AuthHeading>
                    </div>

                    <AuthFormContainer>
                        <AuthForm id="reset-password-form" onSubmit={formik.handleSubmit}>
                            <FormControl error={Boolean(formik.errors.email && formik.touched.email)}>
                                <Input
                                    name="email"
                                    id="email"
                                    placeholder="Email Address"
                                    aria-describedby="email-error-text"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.errors.email && formik.touched.email)}
                                />
                                {(formik.errors.email && formik.touched.email) && <FormHelperText id="email-error-text">{formik.errors.email}</FormHelperText>}
                            </FormControl>
                        </AuthForm>
                    </AuthFormContainer>

                    <AuthActions>
                        {/* <AuthLink to="/auth/signin"></AuthLink> */}
                        <div></div>
                        {resetpasswordStatus === 'PENDING' ? (
                            <CircularProgress color="black" size={24} thickness={6} />
                        ) : (
                            <Button type="submit" variant="contained" color="primary" form="reset-password-form" disabled={formik.isSubmitting || !formik.isValid}>Reset</Button>
                        )}
                    </AuthActions>
                </UICard>
            ) : (
                <UICard padding="p4" style={{ textAlign: 'center' }}>
                    <SigupCompleteHeading>
                        <Icon name="success" size={24} />
                        <Typography variant="h5">Almost there</Typography>
                    </SigupCompleteHeading>

                    <TypographyInfo variant="h5">If an account exists with the email you entered, you will have received an email from us.</TypographyInfo>
                    <TypographyInfo variant="h5">Click the link in that email to reset your password.</TypographyInfo>
                </UICard>
            )}
        </AuthContnetContainer>

    )
}

export default PasswordReset;