import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from 'components/UIButton';
import UICard from 'components/UICard';
import { Input } from 'components/UIForm';
import {
    AuthHeading,
    AuthContnetContainer,
    AuthFormContainer,
    AuthForm,
    AuthActions
} from 'pages/SignIn/sigin.styled';
import { CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetSetPassword } from 'store/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';

const getSearchParams = (params: URLSearchParams) => Object.fromEntries(params);

const loginSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required').min(6, 'Password must be atleast 6 character long'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

const PasswordReset = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const { resetSetPasswordStatus } = useAppSelector(state => state.auth);

    const formik = useFormik({
        initialValues: { password: '', confirmPassword: '' },
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            const params = getSearchParams(searchParams);
            dispatch(resetSetPassword({ new_password: values.password, user: params.user, code: params.code }))
                .unwrap()
                .then(() => {
                    navigate('/auth/signin');
                }).catch((err) => {
                    console.log('err: ', err);                    
                    navigate('/auth/reset');
                }).finally(() => {
                    setSubmitting(false)
                });
        }
    });

    return (
        <AuthContnetContainer>
            <UICard padding="p4" style={{ display: 'flex', flexDirection: 'column', gap: 40, textAlign: 'center' }}>
                <div>
                    <AuthHeading variant="h5">Password Recovery</AuthHeading>
                </div>

                <AuthFormContainer>
                    <AuthForm id="reset-password-form" onSubmit={formik.handleSubmit}>
                        <FormControl error={Boolean(formik.errors.password && formik.touched.password)}>
                            <Input
                                name="password"
                                id="password"
                                type="password"
                                placeholder="New Password"
                                aria-describedby="password-error-text"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.password && formik.touched.password)}
                            />
                            {(formik.errors.password && formik.touched.password) && <FormHelperText id="password-error-text">{formik.errors.password}</FormHelperText>}
                        </FormControl>

                        <FormControl error={Boolean(formik.errors.confirmPassword && formik.touched.confirmPassword)}>
                            <Input
                                name="confirmPassword"
                                id="confirmPassword"
                                type="password"
                                placeholder="Confirm New Password"
                                aria-describedby="confirm-password-error-text"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.confirmPassword && formik.touched.confirmPassword)}
                            />
                            {(formik.errors.confirmPassword && formik.touched.confirmPassword) && <FormHelperText id="confirm-password-error-text">{formik.errors.confirmPassword}</FormHelperText>}
                        </FormControl>
                    </AuthForm>
                </AuthFormContainer>

                <AuthActions>
                    <div></div>
                    {resetSetPasswordStatus === 'PENDING' ? (
                        <CircularProgress color="black" size={24} thickness={6} />
                    ) : (
                        <Button type="submit" variant="contained" color="primary" form="reset-password-form" disabled={formik.isSubmitting || !formik.isValid}>Confirm</Button>
                    )}
                </AuthActions>
            </UICard>
        </AuthContnetContainer>

    )
}

export default PasswordReset;