import UICard from "components/UICard"
import { FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useAppSelector } from "store/hooks";
import Icon from "components/Icon";
import { cancelShipmentLabel, getShipmentLabel } from "api/customers";
import { downloder, withAsync } from "utils";
import { ShipmentTableRow } from "styles/table.styled";
import { ShipmentActionsItem, ShipmentActionsSelect } from "styles/select.styled";

const Labels = () => {
    const { currentShipment } = useAppSelector((state) => state.customers)

    const handleGetLabel = async (id: string) => {
        const { response } = await withAsync(() => getShipmentLabel(id));

        if (response) {
            // console.log('response: ', response);
            downloder.download(response.data, 'application/pdf', `${id}`);
        }
    }

    const handleCancelLabel = async (id: string) => {
        const { response } = await withAsync(() => cancelShipmentLabel(id));

        if (response) {
            console.log('response: ', response);
        }
    }

    return (
        <UICard padding="p4" style={{ marginTop: 32 }}>
            <Typography variant="body1">Labels ({currentShipment?.labels?.length || 0})</Typography>

            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Tracking Number</TableCell>
                            <TableCell>Weight</TableCell>
                            <TableCell>Override Fee</TableCell>
                            <TableCell>Oversize Fee</TableCell>
                            <TableCell>Insurance</TableCell>
                            <TableCell>Surcharge</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentShipment?.labels?.map((label, i) => (
                            <ShipmentTableRow key={"label" + i}>
                                <TableCell component="th" scope="row">
                                    {label.tracking_number}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {label.chargeable_weight} kg
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    ${label.override_fee}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    ${label.oversize_fee}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    ${label.insurance_fee}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    ${label.surcharge_fee}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    ${label.cost}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {label.status}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <FormControl style={{ width: 28, height: 28 }} size="small">
                                        <ShipmentActionsSelect
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                        >
                                            <ShipmentActionsItem onClick={() => handleCancelLabel(label.tracking_number)}>
                                                <Icon name="cancel" size={16} masked={true} color="black" />
                                                <Typography>Cancel Label</Typography>
                                            </ShipmentActionsItem>
                                            <ShipmentActionsItem onClick={() => handleGetLabel(label.tracking_number)} disabled={!label.tracking_number}>
                                                <Icon name="download-full" size={16} masked={true} color="black" />
                                                <Typography>Download Label</Typography>
                                            </ShipmentActionsItem>
                                        </ShipmentActionsSelect>
                                    </FormControl>
                                </TableCell>
                            </ShipmentTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </UICard>
    )
}

export default Labels;