import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const LinkContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const DetailHeading = styled(Typography)`
  color: #000;
  font-size: 20px;
  margin-bottom: 16px;
`;