import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, Grid, Typography } from '@mui/material';
import { PageHeading } from 'components/Heading';
import { Input } from 'components/UIForm';
import { BackLink, InputContainer, OverPackDescription, OverPackInfo, OverPackListContainer, OverPackMeta, TrackingItem } from './overpacking.styled';
import UIButton from 'components/UIButton';
import Icon from 'components/Icon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createNewOverpack, getOverpackByProviderId, printCurrentOverpack, resetSession, resetShipmentInfo, updateOverpackById } from 'store/wms';
import { extractTrackingNumberFromBarCode, randomID } from 'utils';

const OverPacking = () => {
    const {
        currentOverpackSession,
        createNewOverpackStatus,
        updateOverpackByIdStatus
    } = useAppSelector(state => state.wms);
    const [trackingNumber, setTrackingNumber] = React.useState('');
    const [overpackNumber, setOverpackNumber] = React.useState('');
    const [disableOverpack, setDisableOverpack] = React.useState(false);
    const trackingRef = React.useRef<HTMLInputElement>(null);
    const overpackRef = React.useRef<HTMLInputElement>(null);
    const overPackListRef = React.useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleTrackingNumberSubmit = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            if (overpackNumber) {
                dispatch(updateOverpackById({
                    id: overpackNumber,
                    payload: {
                        shipments: [{
                            _id: randomID.uniqueId(),
                            type: 'pending',
                            // tracking_number: trackingNumber,
                            tracking_number: extractTrackingNumberFromBarCode(trackingNumber),
                            historyType: 'tracking'
                        }]
                    }
                })).unwrap().then((res) => {
                    setOverpackNumber(res.overpack.provider_overpack_id.toString())
                    setDisableOverpack(true)
                }).finally(() => {
                    setTrackingNumber('');
                    trackingRef.current?.focus();

                    if (overPackListRef.current) {
                        overPackListRef.current.scrollTop = -overPackListRef.current.scrollHeight
                    }
                    let intervalId = setInterval(() => {
                        dispatch(resetShipmentInfo(intervalId));
                    }, 3000)
                })
            } else {
                dispatch(createNewOverpack({
                    shipments: [{
                        _id: randomID.uniqueId(),
                        type: 'pending',
                        // tracking_number: trackingNumber,
                        tracking_number: extractTrackingNumberFromBarCode(trackingNumber),
                        historyType: 'tracking'
                    }]
                })).unwrap().then((res) => {
                    setOverpackNumber(res.overpack.provider_overpack_id.toString());
                    setDisableOverpack(true)
                }).finally(() => {
                    setTrackingNumber('');
                    trackingRef.current?.focus();

                    if (overPackListRef.current) {
                        overPackListRef.current.scrollTop = -overPackListRef.current.scrollHeight
                    }
                    let intervalId = setInterval(() => {
                        dispatch(resetShipmentInfo(intervalId));
                    }, 3000)
                })
            }
        }
    }

    const handleOverpackChange = () => {
        setDisableOverpack(false);
        setOverpackNumber('');
    }

    const handleOverpackNumberSubmit = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            dispatch(getOverpackByProviderId(overpackNumber)).unwrap().then(res => {
                setOverpackNumber(res.provider_overpack_id.toString())
                setDisableOverpack(true)
            });
        }
    }

    const handleRePrint = () => {
        dispatch(printCurrentOverpack());
    }

    React.useEffect(() => {
        dispatch(resetSession());
        trackingRef.current?.focus();
    }, [dispatch]);

    React.useEffect(() => {
        if (!!currentOverpackSession.overPack && !disableOverpack) {
            overpackRef.current?.focus();
        }
    }, [currentOverpackSession, disableOverpack]);

    return (
        <>
            <BackLink to="/warehousing">
                <Icon name="back-arrow" size={12} />
                <Typography>Back to Warehousing</Typography>
            </BackLink>

            <PageHeading variant="h4">Overpack</PageHeading>

            <Grid container columnSpacing={10} style={{ marginTop: 64 }}>
                <Grid item xs={6}>
                    <InputContainer>
                        <FormControl>
                            <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />

                            <Input
                                name="tracking_number"
                                id="tracking_number"
                                placeholder="Barcode / Tracking Number"
                                aria-describedby="tracking_number-error-text"
                                value={trackingNumber}
                                onChange={e => setTrackingNumber(e.target.value.trim())}
                                onKeyDown={handleTrackingNumberSubmit}
                                disabled={createNewOverpackStatus === 'PENDING' || updateOverpackByIdStatus === 'PENDING'}
                                inputRef={trackingRef}
                                autoComplete="off"
                            />
                        </FormControl>

                        <div style={{ display: 'flex' }}>
                            <FormControl style={{ flex: 1 }}>
                                <Input
                                    name="overpack_number"
                                    id="overpack_number"
                                    placeholder="Overpack Number"
                                    aria-describedby="overpack_number-error-text"
                                    value={overpackNumber}
                                    onChange={e => setOverpackNumber(e.target.value.trim())}
                                    onKeyDown={handleOverpackNumberSubmit}
                                    disabled={disableOverpack}
                                    inputRef={overpackRef}
                                    autoComplete="off"
                                />
                            </FormControl>

                            {!!currentOverpackSession.overPack && (
                                <UIButton
                                    type="button"
                                    startIcon={<Icon name="pencil" size={16} />}
                                    style={{ marginLeft: 10 }}
                                    onClick={handleOverpackChange}
                                >
                                    Change
                                </UIButton>
                            )}
                        </div>
                    </InputContainer>

                    {!!currentOverpackSession.overPack && (
                        <UIButton
                            type="button"
                            variant="outlined"
                            startIcon={<Icon name="print" size={16} />}
                            onClick={handleRePrint}
                        >
                            Re-print
                        </UIButton>
                    )}

                    <OverPackDescription>
                        <Typography variant="body1">
                            Start an Overpack
                        </Typography>
                        <Typography variant="body1" >
                            Leave the overpack field empty and scan a shipment’s tracking number to create a new overpack. The shipment will be assigned to the overpack.
                        </Typography>
                    </OverPackDescription>

                    <OverPackDescription>
                        <Typography variant="body1">
                            Continue an Overpack
                        </Typography>
                        <Typography variant="body1">
                            Scan the overpack number into the overpack field to continue packing. Pack shipments into the overpack by scanning the tracking numbers into the barcode field.
                        </Typography>
                    </OverPackDescription>

                    {!!currentOverpackSession.overPack && <UIButton onClick={() => navigate('/wms/manifesting')}>Start new Manifest</UIButton>}
                </Grid>
                <Grid item xs={6}>
                    <OverPackInfo status={currentOverpackSession.currentShipmentInfo.type}>
                        <Typography variant="body1">
                            {currentOverpackSession.currentShipmentInfo.type === 'pending' && 'Scan Package'}
                            {currentOverpackSession.currentShipmentInfo.type !== 'pending' && currentOverpackSession.currentShipmentInfo.message}
                        </Typography>

                        {!!currentOverpackSession.overPack && (
                            <OverPackMeta>
                                <Typography variant="body1">
                                    {currentOverpackSession.overPack.provider_overpack_id} / {currentOverpackSession.overPack.exit_point} / {currentOverpackSession.overPack.carrier}
                                </Typography>
                            </OverPackMeta>
                        )}
                    </OverPackInfo>

                    <OverPackListContainer id="cont" ref={overPackListRef}>
                        <div className="fix" />
                        {currentOverpackSession?.overPackHistory.map((shp, i) => (
                            <TrackingItem key={shp._id} status={shp.type}>
                                <Typography variant="body1">{shp.info} - {shp.tracking_number}</Typography>
                            </TrackingItem>
                        ))}
                    </OverPackListContainer>
                    {/* <button id="btn1" onClick={() => overPackListRef.current?.scroll({ top: 0, behavior: 'smooth' })}>Top</button> */}
                </Grid>
            </Grid>
        </>
    )
}

export default OverPacking;