import React from 'react';
import { styled } from '@mui/material/styles';

const IconConfig = styled('div') <{ height?: string, size: number }>`
  height: ${props => props.height ? props.height : props.size + "px"};
  width: ${props => props.size + "px"};
`

export const IconWrapper = styled(IconConfig) <{ name: string }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${props => require('assets/icons/' + props.name + '.svg')});
`;

export const IconWrapperMasked = styled(IconConfig) <{ name: string }>`
  mask-position: center center;
  mask-clip: content-box;
  mask-size: contain;
  mask-repeat: no-repeat;
  transition: background-color 200ms ease-out;
  background-color: ${props => props.color ? props.theme.palette[props.color].main : '#fff'};

  -webkit-mask-image: url(${props => require('assets/icons/' + props.name + '.svg')});
  mask-image: url(${props => require('assets/icons/' + props.name + '.svg')});
`;

interface IconProps extends React.ComponentPropsWithoutRef<"div"> {
  name: string
  size: number
  masked?: boolean
  color?: string
  height?: string
}

const Icon: React.FC<IconProps> = ({ size = 3, masked = false, name, ...props }) => {
  if (masked) {
    return <IconWrapperMasked size={size} name={name} {...props} />
  }

  return <IconWrapper size={size} name={name} {...props} />
}

export default Icon;