import * as React from 'react';
// import { PageHeading } from 'components/Heading';
import { SettingsHeading } from './settings.styled';
import Branding from './Branding';
// import BillingBalance from './BillingBalance';
import Billing from './Billing';
import BillingInvoices from './BillingInvoices';
import CarrierConfiguration from './Carrier';
import Team from './Team';
import { useAppDispatch } from 'store/hooks';
import { getInvoices, getUserOrganization } from 'store/auth';
import { getAdmins } from 'store/users';
import OrganizationApproval from './Approval';
import HeadingCrumb from 'components/HeadingCrumb';

const OrganizationSettings = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const getUserOrganizationPromise = dispatch(getUserOrganization());
    const getAdminsPromise = dispatch(getAdmins());
    const getInvoicesPromise = dispatch(getInvoices());

    return () => {
      getUserOrganizationPromise.abort();
      getAdminsPromise.abort();
      getInvoicesPromise.abort();
    }
  }, [dispatch]);
  return (
    <>
      {/* <PageHeading variant="h4">Organization Settings</PageHeading> */}
      <HeadingCrumb />

      <SettingsHeading variant="h5">Carrier Configuration</SettingsHeading>
      <OrganizationApproval />
      <CarrierConfiguration />

      <SettingsHeading variant="h5">Branding</SettingsHeading>
      <Branding />

      <SettingsHeading variant="h5">Billing</SettingsHeading>
      {/* <BillingBalance /> */}
      <BillingInvoices />
      <Billing />

      <SettingsHeading variant="h5">Team</SettingsHeading>
      <Team />
    </>
  )
};


export default OrganizationSettings;