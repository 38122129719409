import axios, { AxiosError, AxiosInstance } from 'axios';
import config from 'config';
import { withAsync } from 'utils';
import {
    ApiRequestConfig,
    WithAbordFn,
    ApiExecutor,
    ApiExecutorArgs,
    ApiError,
} from './api.types';
import { Store } from '@reduxjs/toolkit';
import { tokenRefresh } from 'store/auth';
import type { } from 'redux-thunk/extend-redux';

let store: Store;
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

// Default config for the axios instance
const axiosParams = {
    // Set different base URL based on the environment
    baseURL: config.backendAPIURL,
}

// Create axios instance with default params
const axiosInstance = axios.create(axiosParams)

// axiosInstance.interceptors.response.use(null, async (err) => {
//     const originalConfig = err.config;

//     if (err.code === "ERR_NETWORK") {
//         alert(err.message);
//     }

//     if (originalConfig.url !== "/auth/token" && err.response) {
//         if (err.response.status === 401 && !originalConfig._retry) {
//             originalConfig._retry = true;

//             const {
//                 auth: { refreshToken },
//             } = store.getState();

//             const { error: _error } = await withAsync(() => store.dispatch(tokenRefresh(refreshToken)));

//             if (_error) {
//                 return Promise.reject(_error)
//             } else {
//                 return axiosInstance(originalConfig);
//             }
//         }
//     }

//     return Promise.reject(err);
// });

axiosInstance.interceptors.response.use(null, async (err) => {
    const _axios = axiosInstance;
    const originalRequest = err.config;

    if (err.response.status === 401 && !originalRequest._retry && !originalRequest.isCustomToken) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject })
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return _axios.request(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const {
            auth: { refreshToken },
        } = store.getState();

        return new Promise(async (resolve, reject) => {
            const { error: _error } = await withAsync(() => store.dispatch(tokenRefresh(refreshToken)));

            const {
                auth: { accessToken },
            } = store.getState();

            if (_error) {
                processQueue(err, null);
                reject(err);
            } else {
                processQueue(null, accessToken);
                resolve(_axios(originalRequest));
                isRefreshing = false
            }
        });
    }

    return Promise.reject(err);
})

axiosInstance.interceptors.request.use(
    (config: any) => {
        // console.log('headers: ', config.headers);
        const {
            auth: { accessToken },
        } = store.getState()

        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        }

        if(config.headers['X-PAYLOAD-TOKEN']) {
            config.headers.Authorization = `Bearer ${config.headers['X-PAYLOAD-TOKEN']}`;
            config.isCustomToken = true;

            delete config.headers['X-PAYLOAD-TOKEN'];
        }

        return config
    },
    (err: any) => Promise.reject(err)
)

const didAbort = (error: AxiosError) => axios.isCancel(error)

const getCancelSource = () => axios.CancelToken.source()

export const isApiError = (error: unknown): error is ApiError => {
    return axios.isAxiosError(error)
}

const withAbort = <T>(fn: WithAbordFn) => {
    const executor: ApiExecutor<T> = async (...args: ApiExecutorArgs) => {
        const originalConfig = args[args.length - 1] as ApiRequestConfig
        // Extract abort property from the config
        const { abort, ...config } = originalConfig

        // Create cancel token and abort method only if abort
        // function was passed
        if (typeof abort === 'function') {
            const { cancel, token } = getCancelSource()
            config.cancelToken = token
            abort(cancel)
        }

        try {
            if (args.length > 2) {
                const [url, body] = args
                return await fn<T>(url, body, config)
            } else {
                const [url] = args
                return await fn<T>(url, config)
            }
        } catch (error) {
            if (!isApiError(error)) throw error

            // Add "aborted" property to the error if the request was cancelled
            if (didAbort(error)) {
                error.aborted = true
            } else {
                error.aborted = false
            }

            throw error
        }
    }

    return executor
}

// Main api function
const api = (axios: AxiosInstance) => {
    return {
        get: <T>(url: string, config: ApiRequestConfig = {}) =>
            withAbort<T>(axios.get)(url, config),
        delete: <T>(url: string, config: ApiRequestConfig = {}) =>
            withAbort<T>(axios.delete)(url, config),
        post: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) =>
            withAbort<T>(axios.post)(url, body, config),
        patch: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) =>
            withAbort<T>(axios.patch)(url, body, config),
        put: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) =>
            withAbort<T>(axios.put)(url, body, config),
    }
}

export const injectStore = (_store: Store) => {
    store = _store
}

export default api(axiosInstance)