import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  userLogin,
  updateOrganization,
  getOrganization,
  updateOrganizationProviderConfig,
  getAllProviders,
  getAllProviderServices,
  getAllMappedServices,
  removeServicesMapping,
  addServicesMapping,
  getServicesOptions,
  updateServicesOptions,
  organizationSignUp,
  verifyDNS,
  userVerify,
  passwordReset,
  setPasswordReset,
  refreshAuthToken,
  getUserSetupGuide,
  updateUserSetupGuide,
  updateUser,
  getCurrentUser,
  changePasswordRequest,
  changePassword,
  getCustormerInvoices,
  getWebhooksList,
  updateWebhookMapping,
  getWebhookMapping,
} from "api/auth";
import { AuthState } from "./types";
import { getSearchParamFromURL } from "utils";

const initialState: AuthState = {
  isAuthorized: false,
  accessToken: null,
  refreshToken: null,
  user: null,
  organization: null,
  providers: [],
  providerServices: {},
  mappedServices: [],
  serviceOptions: {
    has_insurance: false,
    has_signature_confirmation: false,
    billing_reconciliation_assistant: false,
    shipments_cancellation_days: null,
  },
  setupMeta: {
    wms_announcement: null,
  },
  billingInvoiceListNext: null,
  billingInvoiceListPrevious: null,
  billingInvoiceList: [],
  webhooksList: [],
  selectedWebhook: null,
  webhookMappingList: [],
  logInUserStatus: "IDLE",
  refreshTokenStatus: "IDLE",
  signUpOrganizationStatus: "IDLE",
  updateOrganizationStatus: "IDLE",
  getUserOrganizationStatus: "IDLE",
  updateOrganizationProviderStatus: "IDLE",
  getProvidersStatus: "IDLE",
  getProviderServicesStatus: "IDLE",
  getMappedServicesStatus: "IDLE",
  deleteServicesMappingStatus: "IDLE",
  addUpdateServicesMappingStatus: "IDLE",
  verifyDNSStatus: "IDLE",
  verifyUserStatus: "IDLE",
  resetpasswordStatus: "IDLE",
  resetSetPasswordStatus: "IDLE",
  getUserSetupGuideByIdStatus: "IDLE",
  updateUserSetupGuideByIdStatus: "IDLE",
  updateUserDetailsStatus: "IDLE",
  getCurrentUserDetailsStatus: "IDLE",
  getCurrentTokenUserDetailsStatus: "IDLE",
  changeUserPasswordRequestStatus: "IDLE",
  changeUserPasswordStatus: "IDLE",
  changeUserPasswordInvalidStatus: "IDLE",
  getInvoicesStatus: "IDLE",
  // inviteAdminStatus: 'IDLE'
  // getServiceOptions
  // updateServiceOptions
};

export const signInUser = createAsyncThunk("auth/userLogin", userLogin);
export const signUpOrganization = createAsyncThunk(
  "auth/organizationSignUp",
  organizationSignUp
);
export const updateUserOrganization = createAsyncThunk(
  "auth/updateOrganization",
  updateOrganization
);
export const getUserOrganization = createAsyncThunk(
  "auth/getOrganization",
  getOrganization
);
export const updateOrganizationProvider = createAsyncThunk(
  "auth/updateOrganizationProviderConfig",
  updateOrganizationProviderConfig
);
export const getProviders = createAsyncThunk(
  "auth/getAllProviders",
  getAllProviders
);
export const getProviderServices = createAsyncThunk(
  "auth/getAllProviderServices",
  getAllProviderServices
);
export const getMappedServices = createAsyncThunk(
  "auth/getAllMappedServices",
  getAllMappedServices
);
export const addUpdateServicesMapping = createAsyncThunk(
  "auth/addServicesMapping",
  addServicesMapping
);
export const deleteServicesMapping = createAsyncThunk(
  "auth/removeServicesMapping",
  removeServicesMapping
);
export const getServiceOptions = createAsyncThunk(
  "auth/getServicesOptions",
  getServicesOptions
);
export const updateServiceOptions = createAsyncThunk(
  "auth/updateServicesOptions",
  updateServicesOptions
);
export const verifyDomainDNS = createAsyncThunk("auth/verifyDNS", verifyDNS);
export const verifyUser = createAsyncThunk("auth/userVerify", userVerify);
export const resetpassword = createAsyncThunk(
  "auth/passwordReset",
  passwordReset
);
export const resetSetPassword = createAsyncThunk(
  "auth/setPasswordReset",
  setPasswordReset
);
export const changeUserPasswordRequest = createAsyncThunk(
  "auth/changePasswordRequest",
  changePasswordRequest
);
export const changeUserPassword = createAsyncThunk(
  "auth/changePassword",
  changePassword
);
export const tokenRefresh = createAsyncThunk(
  "auth/refreshAuthToken",
  refreshAuthToken
);
export const getUserSetupGuideById = createAsyncThunk(
  "users/getUserSetupGuide",
  getUserSetupGuide
);
export const updateUserSetupGuideById = createAsyncThunk(
  "users/updateUserSetupGuide",
  updateUserSetupGuide
);
export const updateUserDetails = createAsyncThunk(
  "users/updateUser",
  updateUser
);
export const getCurrentUserDetails = createAsyncThunk(
  "users/getCurrentUser",
  getCurrentUser
);
export const getCurrentTokenUserDetails = createAsyncThunk(
  "users/getTokenCurrentUser",
  getCurrentUser
);
export const getInvoices = createAsyncThunk(
  "users/getCustormerInvoices",
  getCustormerInvoices
);
export const getWebhooks = createAsyncThunk(
  "users/getWebhooksList",
  getWebhooksList
);
export const updateWebhookCodeMapping = createAsyncThunk(
  "users/updateWebhookMapping",
  updateWebhookMapping
);
export const getWebhookMappingList = createAsyncThunk(
  "users/getWebhookMapping",
  getWebhookMapping
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateServicesMapping: (state, action) => {
      state.mappedServices = [...state.mappedServices, action.payload];
    },
    updateServicesOrgSrvName: (state, action) => {
      state.mappedServices = state.mappedServices.map((srv) => {
        if (srv.id === action.payload.id) {
          srv.organization_service_name =
            action.payload.organization_service_name;
        }

        return srv;
      });
    },
    removeLogo: (state) => {
      if (state.organization) {
        state.organization.logo = undefined;
      }
    },
    logoutUser: (state) => {
      return initialState;
    },
    setWMSShowAgain: (state, action) => {
      if (state.setupMeta.wms_announcement) {
        state.setupMeta.wms_announcement.show_again = action.payload;
      }
    },
    resetChangeUserPasswordRequestStatus: (state, action) => {
      state.changeUserPasswordRequestStatus = "IDLE";

      clearInterval(action.payload);
    },
    setErrorChangeUserPassword: (state) => {
      state.changeUserPasswordInvalidStatus = "ERROR";
    },
    resetErrorChangeUserPassword: (state, action) => {
      state.changeUserPasswordInvalidStatus = "IDLE";

      clearInterval(action.payload);
    },
    selectWebhook: (state, action) => {
      state.selectedWebhook = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInUser.pending, (state, action) => {
      state.logInUserStatus = "PENDING";
    });
    builder.addCase(signInUser.fulfilled, (state, action) => {
      const organization = { ...action.payload.user.organization };
      const user = { ...action.payload.user, organization: undefined };

      state.user = user;
      state.organization = organization;
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
      state.isAuthorized = true;
      state.logInUserStatus = "SUCCESS";
    });
    builder.addCase(signInUser.rejected, (state, action) => {
      state.logInUserStatus = "ERROR";
    });
    builder.addCase(tokenRefresh.pending, (state, action) => {
      state.refreshTokenStatus = "PENDING";
    });
    builder.addCase(tokenRefresh.fulfilled, (state, action) => {
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
      state.refreshTokenStatus = "SUCCESS";
    });
    builder.addCase(tokenRefresh.rejected, (state, action) => {
      state.refreshTokenStatus = "ERROR";
    });
    builder.addCase(signUpOrganization.pending, (state, action) => {
      state.signUpOrganizationStatus = "PENDING";
    });
    builder.addCase(signUpOrganization.fulfilled, (state, action) => {
      state.signUpOrganizationStatus = "SUCCESS";
    });
    builder.addCase(signUpOrganization.rejected, (state, action) => {
      state.signUpOrganizationStatus = "ERROR";
    });
    builder.addCase(resetpassword.pending, (state, action) => {
      state.resetpasswordStatus = "PENDING";
    });
    builder.addCase(resetpassword.fulfilled, (state, action) => {
      state.resetpasswordStatus = "SUCCESS";
    });
    builder.addCase(resetpassword.rejected, (state, action) => {
      state.resetpasswordStatus = "ERROR";
    });
    builder.addCase(resetSetPassword.pending, (state, action) => {
      state.resetSetPasswordStatus = "PENDING";
    });
    builder.addCase(resetSetPassword.fulfilled, (state, action) => {
      state.resetSetPasswordStatus = "SUCCESS";
    });
    builder.addCase(resetSetPassword.rejected, (state, action) => {
      state.resetSetPasswordStatus = "ERROR";
    });
    builder.addCase(changeUserPasswordRequest.pending, (state, action) => {
      state.changeUserPasswordRequestStatus = "PENDING";
    });
    builder.addCase(changeUserPasswordRequest.fulfilled, (state, action) => {
      state.changeUserPasswordRequestStatus = "SUCCESS";
    });
    builder.addCase(changeUserPasswordRequest.rejected, (state, action) => {
      state.changeUserPasswordRequestStatus = "ERROR";
    });
    builder.addCase(changeUserPassword.pending, (state, action) => {
      state.changeUserPasswordStatus = "PENDING";
    });
    builder.addCase(changeUserPassword.fulfilled, (state, action) => {
      state.changeUserPasswordStatus = "SUCCESS";
    });
    builder.addCase(changeUserPassword.rejected, (state, action) => {
      state.changeUserPasswordStatus = "ERROR";
    });
    builder.addCase(updateUserOrganization.pending, (state, action) => {
      state.updateOrganizationStatus = "PENDING";
    });
    builder.addCase(updateUserOrganization.fulfilled, (state, action) => {
      state.organization = { ...state.organization, ...action.payload };
      state.updateOrganizationStatus = "SUCCESS";
    });
    builder.addCase(updateUserOrganization.rejected, (state, action) => {
      state.updateOrganizationStatus = "ERROR";
    });
    builder.addCase(getUserOrganization.pending, (state, action) => {
      state.getUserOrganizationStatus = "PENDING";
    });
    builder.addCase(getUserOrganization.fulfilled, (state, action) => {
      state.organization = { ...state.organization, ...action.payload };
      state.getUserOrganizationStatus = "SUCCESS";
    });
    builder.addCase(getUserOrganization.rejected, (state, action) => {
      state.getUserOrganizationStatus = "ERROR";
    });
    builder.addCase(updateOrganizationProvider.pending, (state, action) => {
      state.updateOrganizationProviderStatus = "PENDING";
    });
    builder.addCase(updateOrganizationProvider.fulfilled, (state, action) => {
      state.organization?.auth_configs.push(action.payload);
      state.updateOrganizationProviderStatus = "SUCCESS";
    });
    builder.addCase(updateOrganizationProvider.rejected, (state, action) => {
      state.updateOrganizationProviderStatus = "ERROR";
    });
    builder.addCase(getProviders.pending, (state, action) => {
      state.getProvidersStatus = "PENDING";
    });
    builder.addCase(getProviders.fulfilled, (state, action) => {
      state.providers = action.payload;
      state.getProvidersStatus = "SUCCESS";
    });
    builder.addCase(getProviders.rejected, (state, action) => {
      state.getProvidersStatus = "ERROR";
    });
    builder.addCase(getProviderServices.pending, (state, action) => {
      state.getProviderServicesStatus = "PENDING";
    });
    builder.addCase(getProviderServices.fulfilled, (state, action) => {
      state.providerServices = { ...state.providerServices, ...action.payload };
      state.getProviderServicesStatus = "SUCCESS";
    });
    builder.addCase(getProviderServices.rejected, (state, action) => {
      state.getProviderServicesStatus = "ERROR";
    });
    builder.addCase(getMappedServices.pending, (state, action) => {
      state.getMappedServicesStatus = "PENDING";
    });
    builder.addCase(getMappedServices.fulfilled, (state, action) => {
      state.mappedServices = action.payload;
      state.getMappedServicesStatus = "SUCCESS";
    });
    builder.addCase(getMappedServices.rejected, (state, action) => {
      state.getMappedServicesStatus = "ERROR";
    });
    builder.addCase(addUpdateServicesMapping.pending, (state, action) => {
      state.addUpdateServicesMappingStatus = "PENDING";
    });
    builder.addCase(addUpdateServicesMapping.fulfilled, (state, action) => {
      state.mappedServices = action.payload;
      state.addUpdateServicesMappingStatus = "SUCCESS";
    });
    builder.addCase(addUpdateServicesMapping.rejected, (state, action) => {
      state.addUpdateServicesMappingStatus = "ERROR";
    });
    builder.addCase(deleteServicesMapping.pending, (state, action) => {
      state.deleteServicesMappingStatus = "PENDING";
    });
    builder.addCase(deleteServicesMapping.fulfilled, (state, action) => {
      state.mappedServices = state.mappedServices.filter(
        (mapSrv) => mapSrv.id !== action.payload.id
      );
      state.deleteServicesMappingStatus = "SUCCESS";
    });
    builder.addCase(deleteServicesMapping.rejected, (state, action) => {
      state.deleteServicesMappingStatus = "ERROR";
    });
    builder.addCase(getServiceOptions.pending, (state, action) => {
      // state.deleteServicesMappingStatus = 'PENDING';
    });
    builder.addCase(getServiceOptions.fulfilled, (state, action) => {
      state.serviceOptions = action.payload;
    });
    builder.addCase(getServiceOptions.rejected, (state, action) => {
      // state.deleteServicesMappingStatus = 'ERROR';
    });
    builder.addCase(updateServiceOptions.pending, (state, action) => {
      // state.deleteServicesMappingStatus = 'PENDING';
    });
    builder.addCase(updateServiceOptions.fulfilled, (state, action) => {
      state.serviceOptions = action.payload;
    });
    builder.addCase(updateServiceOptions.rejected, (state, action) => {
      // state.deleteServicesMappingStatus = 'ERROR';
    });
    builder.addCase(verifyDomainDNS.pending, (state, action) => {
      state.verifyDNSStatus = "PENDING";
    });
    builder.addCase(verifyDomainDNS.fulfilled, (state, action) => {
      state.organization = {
        ...JSON.parse(JSON.stringify(state.organization)),
        ...action.payload,
      };
      state.verifyDNSStatus = "SUCCESS";
    });
    builder.addCase(verifyDomainDNS.rejected, (state, action) => {
      state.verifyDNSStatus = "ERROR";
    });
    builder.addCase(verifyUser.pending, (state, action) => {
      state.verifyUserStatus = "PENDING";
    });
    builder.addCase(verifyUser.fulfilled, (state, action) => {
      state.verifyUserStatus = "SUCCESS";
    });
    builder.addCase(verifyUser.rejected, (state, action) => {
      state.verifyUserStatus = "ERROR";
    });
    builder.addCase(getUserSetupGuideById.pending, (state, action) => {
      state.getUserSetupGuideByIdStatus = "PENDING";
    });
    builder.addCase(getUserSetupGuideById.fulfilled, (state, action) => {
      state.setupMeta = action.payload.setup_metadata;
      state.getUserSetupGuideByIdStatus = "SUCCESS";
    });
    builder.addCase(getUserSetupGuideById.rejected, (state, action) => {
      state.getUserSetupGuideByIdStatus = "ERROR";
    });
    builder.addCase(updateUserSetupGuideById.pending, (state, action) => {
      state.updateUserSetupGuideByIdStatus = "PENDING";
    });
    builder.addCase(updateUserSetupGuideById.fulfilled, (state, action) => {
      // state.setupMeta = action.payload
      console.log("updateUserSetupGuideById: ", action.payload);
      state.setupMeta = action.payload.setup_metadata;
      state.updateUserSetupGuideByIdStatus = "SUCCESS";
    });
    builder.addCase(updateUserSetupGuideById.rejected, (state, action) => {
      state.updateUserSetupGuideByIdStatus = "ERROR";
    });
    builder.addCase(updateUserDetails.pending, (state, action) => {
      state.updateUserDetailsStatus = "PENDING";
    });
    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      if (!action.meta.arg.token) {
        if (state.user) {
          state.user.first_name = action.payload.first_name;
          state.user.last_name = action.payload.last_name;
        }
      }
      state.updateUserDetailsStatus = "SUCCESS";
    });
    builder.addCase(updateUserDetails.rejected, (state, action) => {
      state.updateUserDetailsStatus = "ERROR";
    });
    builder.addCase(getCurrentUserDetails.pending, (state, action) => {
      state.getCurrentUserDetailsStatus = "PENDING";
    });
    builder.addCase(getCurrentUserDetails.fulfilled, (state, action) => {
      state.getCurrentUserDetailsStatus = "SUCCESS";
    });
    builder.addCase(getCurrentUserDetails.rejected, (state, action) => {
      state.getCurrentUserDetailsStatus = "ERROR";
    });
    builder.addCase(getCurrentTokenUserDetails.pending, (state, action) => {
      state.getCurrentTokenUserDetailsStatus = "PENDING";
    });
    builder.addCase(getCurrentTokenUserDetails.fulfilled, (state, action) => {
      state.getCurrentTokenUserDetailsStatus = "SUCCESS";
    });
    builder.addCase(getCurrentTokenUserDetails.rejected, (state, action) => {
      state.getCurrentTokenUserDetailsStatus = "ERROR";
    });
    builder.addCase(getInvoices.pending, (state, action) => {
      state.getInvoicesStatus = "PENDING";
    });
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      // state.billingInvoiceList = [
      //     {
      //         id: "string",
      //         due_date: "string",
      //         pdf_url: "string",
      //         is_paid: true,
      //         total: "string",
      //         invoice_number: "string"
      //     }
      // ];
      state.billingInvoiceList = action.payload.results;
      state.billingInvoiceListNext = getSearchParamFromURL(
        action.payload.next,
        "cursor"
      );
      state.billingInvoiceListPrevious = getSearchParamFromURL(
        action.payload.previous,
        "cursor"
      );
      state.getInvoicesStatus = "SUCCESS";
    });
    builder.addCase(getInvoices.rejected, (state, action) => {
      state.getInvoicesStatus = "ERROR";
    });
    builder.addCase(getWebhooks.fulfilled, (state, action) => {
      state.webhooksList = action.payload;
    });
    builder.addCase(updateWebhookCodeMapping.fulfilled, (state, action) => {});
    builder.addCase(getWebhookMappingList.fulfilled, (state, action) => {
      state.webhookMappingList = Object.entries(
        action.payload.status_code_mappings
      ).map(([key, value]) => {
        return {
          code: key,
          json: JSON.stringify(value),
        };
      });
    });
  },
});

export const {
  updateServicesMapping,
  removeLogo,
  updateServicesOrgSrvName,
  logoutUser,
  setWMSShowAgain,
  resetChangeUserPasswordRequestStatus,
  setErrorChangeUserPassword,
  resetErrorChangeUserPassword,
  selectWebhook,
} = authSlice.actions;
export default authSlice.reducer;
