import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Table, TableCell } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import UIButton from 'components/UIButton';

export const ShipmentInfo = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ShipmentMode = styled('div') <{ testMode: boolean }>`
  font-family: 'Inter';
  display: ${props => props.testMode ? 'flex' : 'none'};
  background: #DC9A38;
  padding: 4px 8px;
  border-radius: 7px;
`;


export const ShipmentHeading = styled(Typography)`
  color: #000;
  font-size: 24px;
  margin-bottom: 32px;
  margin-top: 32px;
`;

export const ShipmentDetailsTable = styled(Table)`
  width: 80%;

  thead tr th {
    border: none;
  }

  tbody tr td {
    border: none;
    padding: 0;
  }

  tbody tr td {
    &:nth-of-type(1) {
      padding-right: 25px;
    }
  }
`;

export const ShipmentAccordion = styled(Accordion)`
  border-radius: 5px;
  box-shadow: none;
  margin-bottom: 20px;
  border: 1px solid #E7E7E7;

  &::before {
    display: none;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    border-bottom: 1px solid #E7E7E7;
    border-radius: 5px 5px 0px 0px;
  }

  .MuiCollapse-root {
    /* border: 1px solid #E7E7E7; */
    /* border-top: none; */
  }
`;

export const AccordionTable = styled(ShipmentDetailsTable)`
  width: 100%;
`;

export const ClaimTableCell = styled(TableCell)`
    display: flex;
    align-items: center;
`;

export const ClaimButton = styled(UIButton)`
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding: 4px 8px;

  & > p {
    margin-left: 4px;
  }
`