import api from '../index';
import { InviteUserPayload, InviteUserResponse, GetOrganizationAdminsResponse } from './types';

export const inviteUser = (data: InviteUserPayload, thunkAPI: any) => {
    return api.post<InviteUserResponse>('/organizations/admins/invite', data).then(res => res.data).catch(err => {
        throw thunkAPI.rejectWithValue((err as any).response?.data)
    });
}

export const getOrganizationAdmins = (data: string | undefined, thunkAPI: any) => {
    return api.get<GetOrganizationAdminsResponse[]>('/organizations/admins', { signal: thunkAPI.signal }).then(res => res.data);
}

export const statusUpdateAdmin = (payload: { id: string, data: { is_active: boolean } }) => {
    return api.put<{ is_active: boolean }>(`/organizations/admins/${payload.id}/status_update`, payload.data).then(res => {
        return {
            ...res.data,
            id: payload.id
        }
    });
}

// export const getUserSetupGuide = (id: string | undefined) => {
//     return api.get<GetUserSetupGuideResponse>('/user_setup_guides/' + id).then(res => res.data);
// }