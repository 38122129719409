import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography/Typography";
import UICard from 'components/UICard';
import Icon from 'components/Icon';
import dayjs from 'dayjs';

const InvoiceContainer = styled(UICard)`
    display: flex;
    align-items: center;
    padding: 18px 24px;
    font-family: 'Inter';
`;

const InvoiceInfo = styled('div')`
    display: flex;
    flex-direction: column;

    /* & > p {
        font-family: 'Inter';
    } */
`;

const InvoiceInfoTop = styled('div')`
    display: flex;
    align-items: center;
    gap: 16px;

    & .invoice-date {
        color: ${props => props.theme.palette.greyOne.main};
    }

    & > p {
        font-family: 'Inter';
    }
`;

const InvoiceInfoBottom = styled('div')`
    display: flex;
    align-items: center;
    margin-top: 16px;

    & .invoice-date {
        color: ${props => props.theme.palette.greyOne.main};
    }

    & p {
        font-family: 'Inter';
    }
`;

const InvoiceAction = styled('div')`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    cursor: pointer;
    
    & > p {
        color: ${props => props.theme.palette.primary.main};
        font-weight: 600;
        font-family: 'Inter';
    }
`;

const OverDueInfo = styled('div')`
    display: flex;
    align-items: center;
    gap: 5px;
`;

const Invoice = ({ single, invoiceNumber, isPaid, dueDate, pdfURL, billable }: { single: boolean, invoiceNumber: string, isPaid: boolean, dueDate: string, pdfURL: string, billable: string }) => {
    const date = new Date(dueDate);
    const dueAfter = dayjs().isBefore(dayjs(dueDate));

    return (
        <InvoiceContainer>
            <InvoiceInfo>
                <InvoiceInfoTop>
                    <Typography variant="body1">Invoice #{invoiceNumber}</Typography>

                    {single && (
                        <>
                            <Icon name="dot" size={4} masked={true} color="greyOne" />
                            {(!isPaid && !dueAfter) ? (
                                <>
                                    <OverDueInfo>
                                        <Icon name="warning" size={20} />
                                        <Typography variant="body1">Overdue</Typography>
                                    </OverDueInfo>
                                    <Typography className="invoice-date" variant="body1">(Past Due - {dayjs(date).format('DD/MM/YY')})</Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body1">{isPaid ? 'Paid' : 'Unpaid'}</Typography>
                                    <Typography className="invoice-date" variant="body1">(Due {date.toDateString().split(' ').splice(0, date.toDateString().split(' ').length - 1).join(' ')})</Typography>
                                </>
                            )}
                        </>
                    )}
                </InvoiceInfoTop>

                {!single && <InvoiceInfoBottom>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                        {(!isPaid && !dueAfter) ? (
                            <>
                                <OverDueInfo>
                                    <Icon name="warning" size={20} />
                                    <Typography variant="body1">Overdue</Typography>
                                </OverDueInfo>
                                <Typography className="invoice-date" variant="body1">(Past Due - {dayjs(date).format('DD/MM/YY')})</Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant="body1">{isPaid ? 'Paid' : 'Unpaid'}</Typography>
                                <Typography className="invoice-date" variant="body1">(Due {date.toDateString().split(' ').splice(0, date.toDateString().split(' ').length - 1).join(' ')})</Typography>
                            </>
                        )}

                        <Icon name="dot" size={4} masked={true} color="greyOne" />
                        <Typography variant="body1">${billable}</Typography>
                    </div>
                </InvoiceInfoBottom>}
            </InvoiceInfo>

            <InvoiceAction onClick={() => window.open(pdfURL, '_blank')} role="button">
                <Icon name="download-full" size={18} masked={true} color="primary" />
                <Typography variant="body1">{single ? 'Download Invoice' : 'Download'}</Typography>
            </InvoiceAction>
        </InvoiceContainer>
    )
}


export default Invoice;