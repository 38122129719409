import { styled } from '@mui/material/styles';
import { FormControl } from "@mui/material";
import Card from '@mui/material/Card';

export const ServicesContainer = styled('div')`
    margin-top: 42px;
`;

export const ServicesList = styled('div')`
    margin-top: 42px;
`;

export const SeriveFormControl = styled(FormControl)`
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 20px;

    & > div {
        width: 40px;
    }
`;

export const ServiceWarningCard = styled(Card)`
    padding: 20px;
    border-color: #E7E7E7;
    border-radius: 14px;
    display: flex;
    align-items: center;

    p {
       margin-left: 15px;
    }
`;

export const ServiceOptionContainer = styled('div')`
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
        margin-bottom: 32px;
    }
`;

export const ServiceCheckBoxContainer = styled('div')``;
export const ServiceInfoContainer = styled('div')``;