import { styled } from '@mui/material/styles';
import { UISelect } from 'components/UIForm';
import MenuItem from '@mui/material/MenuItem';

export const ShipmentActionsSelect = styled(UISelect)`
    min-width: 26px;
    height: 26px;
    background: #F9F9F9;

    fieldset {
      border-color: ${props => !props.error && props.theme.palette.greyOne.main} !important;
    }
    
    svg {
      right: 2px;
      color: ${props => !props.error && props.theme.palette.greyOne.main};
    }
`;

export const ShipmentActionsItem = styled(MenuItem)`
  display: flex;
  align-items: center;

  & > p {
    margin-left: 6px;
  }
`;