import * as React from "react";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Typography,
} from "@mui/material";
import UIButton from "components/UIButton";
import UICard from "components/UICard";
import UILink from "components/UILink";
import {
  SettingsCard,
  SettingsDesc,
} from "pages/OrganizationSettings/settings.styled";
import { UISelect, UICheckBox } from "components/UIForm";
import Icon from "components/Icon";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getProviderServices,
  getServiceOptions,
  updateServiceOptions,
} from "store/auth";
import {
  ServiceOptionContainer,
  ServiceCheckBoxContainer,
  ServiceInfoContainer,
} from "./carrier-config.styled";

const inviteSchema = Yup.object().shape({
  services: Yup.array(
    Yup.object({
      id: Yup.string(),
      provider_service: Yup.string().required(),
      provider_service_name: Yup.string(),
      organization_service_name: Yup.string().required(
        "Service name is required"
      ),
      is_generic: Yup.boolean().required(),
    })
  ),
  has_insurance: Yup.boolean(),
  has_signature_confirmation: Yup.boolean(),
  billing_reconciliation_assistant: Yup.boolean(),
  shipments_cancellation_days: Yup.string().required("Please select cancellation days"),
});

const Services = () => {
  const dispatch = useAppDispatch();
  const {providers, serviceOptions } =
    useAppSelector(({ auth }) => auth);

  const [prodviderId, setProdviderId] = React.useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      has_insurance: serviceOptions.has_insurance,
      has_signature_confirmation: serviceOptions.has_signature_confirmation,
      billing_reconciliation_assistant:
        serviceOptions.billing_reconciliation_assistant,
      // usage_limit_type:
      // usage_limit_type === "null" ? null : usage_limit_type,
      shipments_cancellation_days: serviceOptions.shipments_cancellation_days || 'null',
    },
    validationSchema: inviteSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      await dispatch(
        updateServiceOptions({
          provider: prodviderId,
          has_insurance: values.has_insurance,
          has_signature_confirmation: values.has_signature_confirmation,
          billing_reconciliation_assistant: values.billing_reconciliation_assistant,
          shipments_cancellation_days: values.shipments_cancellation_days === "null" ? null : values.shipments_cancellation_days,
        })
      );

      setSubmitting(false);
    },
  });

  const handleProviderChange = React.useCallback(() => {
    // BoxC
    const provider = providers.find((prv) => prv.name === "BoxC");

    if (provider) {
      dispatch(getProviderServices(provider.id));
      setProdviderId(provider.id);
    }
  }, [dispatch, providers]);


  React.useEffect(() => {
    dispatch(getServiceOptions());
    handleProviderChange();
  }, [handleProviderChange, dispatch]);

  return (
    <UICard>
      <FormikProvider value={formik}>
        <form id="services" onSubmit={formik.handleSubmit}>
          
            <SettingsCard>
              <Typography variant="body1">Service Options</Typography>
              <SettingsDesc variant="body1">Through your integration with BoxC, you have a number of additional options that you can enable for your customers. Please select which services you’d like to enable by checking the boxes below, or learn more about how each of these service options work in our <UILink href="https://carrierlink.notion.site/Carrier-Configuration-Guide-d0b48489538e41aa902159403b0c6cf6 ">Carrier Configuration Guide →</UILink></SettingsDesc>

              <ServiceOptionContainer style={{ marginTop: '42px' }}>
                <ServiceCheckBoxContainer>
                  <UICheckBox
                    icon={<Icon name="checkbox-un" size={30} />}
                    checkedIcon={<Icon name="checkbox" size={30} />}
                    color="success"
                    name="has_insurance"
                    id="has_insurance"
                    checked={formik.values.has_insurance}
                    onChange={formik.handleChange}
                  />
                </ServiceCheckBoxContainer>

                <ServiceInfoContainer>
                  <Typography variant="body1">Insurance</Typography>
                  <SettingsDesc variant="body1">Toggling this on will give your customers the option to enable Insurance when creating packages by API or in their dashboard.</SettingsDesc>
                </ServiceInfoContainer>
              </ServiceOptionContainer>

              <ServiceOptionContainer>
                <ServiceCheckBoxContainer>
                  <UICheckBox
                    icon={<Icon name="checkbox-un" size={30} />}
                    checkedIcon={<Icon name="checkbox" size={30} />}
                    color="success"
                    name="has_signature_confirmation"
                    id="has_signature_confirmation"
                    checked={formik.values.has_signature_confirmation}
                    onChange={formik.handleChange}
                  />
                </ServiceCheckBoxContainer>

                <ServiceInfoContainer>
                  <Typography variant="body1">Signature Confirmation</Typography>
                  <SettingsDesc variant="body1">Toggling this on will give your customers the option to enable Signature Confirmation when creating packages by API or in their dashboard.</SettingsDesc>
                </ServiceInfoContainer>
              </ServiceOptionContainer>
            </SettingsCard>


          <SettingsCard>
            <Typography variant="body1">
              Auto-cancel unprocessed labels
            </Typography>
            <SettingsDesc variant="body1">
              We recommend canceling labels that are not overpacked after 28
              days of sitting idle to avoid unnecessary billing and to improve
              your data reporting. These are called “unprocessed” labels.
            </SettingsDesc>

            <ServiceOptionContainer style={{ marginTop: "42px" }}>
              <FormControl
                error={Boolean(
                  formik.errors.shipments_cancellation_days &&
                  formik.touched.shipments_cancellation_days
                )}
              >
                <UISelect
                  labelId="shipments_cancellation_days-label"
                  id="shipments_cancellation_days"
                  name="shipments_cancellation_days"
                  value={formik.values.shipments_cancellation_days}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.errors.shipments_cancellation_days &&
                    formik.touched.shipments_cancellation_days
                  )}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Cancellation Days
                  </MenuItem>
                  {[
                    { name: "Never", value: "null" },
                    { name: "7 Days", value: "7" },
                    { name: "14 Days", value: "14" },
                    { name: "21 Days", value: "21" },
                    { name: "28 Days", value: "28" },
                  ].map((co) => (
                    <MenuItem key={co.value} value={co.value}>
                      {co.name}
                    </MenuItem>
                  ))}
                </UISelect>
                {formik.errors.shipments_cancellation_days &&
                  formik.touched.shipments_cancellation_days && (
                    <FormHelperText id="primary-error-text">
                      {formik.errors.shipments_cancellation_days}
                    </FormHelperText>
                  )}
              </FormControl>
            </ServiceOptionContainer>
          </SettingsCard>

          <SettingsCard>
            <Typography variant="body1">
              Billing Reconciliation Assistant
            </Typography>
            <SettingsDesc variant="body1">
              You can use Carrier Link’s Billing Reconciliation Assistant to
              make invoicing your customers easier. Enabling this will reduce
              the number of reference fields your customers can use on their
              shipments, and may have other effects on your shipment creation
              process. Learn more about the{" "}
              <UILink href="https://carrierlink.notion.site/Carrier-Configuration-Guide-d0b48489538e41aa902159403b0c6cf6 ">
                Billing Reconciliation Assistant →
              </UILink>
            </SettingsDesc>

            <ServiceOptionContainer style={{ marginTop: "42px" }}>
              <ServiceCheckBoxContainer>
                <UICheckBox
                  icon={<Icon name="checkbox-un" size={30} />}
                  checkedIcon={<Icon name="checkbox" size={30} />}
                  color="success"
                  name="billing_reconciliation_assistant"
                  id="billing_reconciliation_assistant"
                  checked={formik.values.billing_reconciliation_assistant}
                  onChange={formik.handleChange}
                />
              </ServiceCheckBoxContainer>

              <ServiceInfoContainer>
                <Typography variant="body1">
                  Billing Reconciliation Assistant
                </Typography>
                <SettingsDesc variant="body1">
                  Carrier Link Customer IDs will be added to each shipment’s
                  reference field
                </SettingsDesc>
              </ServiceInfoContainer>
            </ServiceOptionContainer>
          </SettingsCard>

          <SettingsCard>
            <UIButton
              type="submit"
              form="services"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Save Services
            </UIButton>
          </SettingsCard>
        </form >
      </FormikProvider >
    </UICard >
  );
};

export default Services;
