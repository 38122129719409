import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import UICard from 'components/UICard';
import { SettingsCard } from 'pages/Customers/settings.styled';
import { SettingsDesc, SettingsHeading } from 'pages/OrganizationSettings/settings.styled';
import UILink from 'components/UILink';
import { useAppSelector } from 'store/hooks';
import Invoice from 'components/Invoice';
import Icon from 'components/Icon';
import HeadingCrumb from 'components/HeadingCrumb';
import OrganizationSettingsBilling from 'pages/OrganizationSettings/Billing';

const InvoiceListContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 54px; 
`;

const InvoiceIcon = styled('div')`
    display: flex; 
    align-items: center; 
    gap: 8px;
    margin-bottom: 15px;
`;

const Billing = () => {
    const { billingInvoiceList } = useAppSelector(state => state.auth);

    return (
        <>
            {/* <ScrollRestoration /> */}
            <HeadingCrumb />

            <SettingsHeading variant="h5">Billing</SettingsHeading>

            <UICard style={{ marginBottom: 32 }}>
                <SettingsCard>
                    {/* <Typography variant="body1">Invoices</Typography> */}

                    <InvoiceIcon>
                        <Icon name="invoice" size={24} />
                        <Typography variant="body1">Invoices</Typography>
                    </InvoiceIcon>

                    <SettingsDesc variant="body1">
                        Make sure to stay current on your invoices to ensure there is no interruption to your service for your clients. We offer net-14 day payment terms to help you reconcile and stay current. Any cancelled services from a past invoice will be credited in the invoice for the period within which you cancelled the services. <UILink href="https://carrierlink.notion.site/How-do-billing-periods-and-invoices-work-e85bf33e31ba4c9c87844b681c532bc8">Learn more about Invoices →</UILink>
                    </SettingsDesc>

                    <InvoiceListContainer>
                        {billingInvoiceList.filter(inv => inv.invoice_number).map(inv => (
                            <Invoice
                                single={false}
                                key={inv.id}
                                invoiceNumber={inv.invoice_number}
                                isPaid={inv.is_paid}
                                dueDate={inv.due_date}
                                pdfURL={inv.pdf_url}
                                billable={inv.total}
                            />
                        ))}
                    </InvoiceListContainer>
                </SettingsCard>
            </UICard>

            <OrganizationSettingsBilling />
            {/* <ScrollRestoration /> */}
        </>
    )
}

export default Billing;