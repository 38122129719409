import * as React from 'react'
import { styled } from '@mui/material/styles';
import logoFull from 'assets/logo-full.svg';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

const AsideMain = styled('aside')`
    width: 228px;
    border-right: 1px solid #E7E7E7;
    height: 100vh;
`;

const AsideLogo = styled('img')` 
    width: 180px;
    padding: 18px 10px;
    margin-top: 10px;
`;

const AsideNav = styled('nav')`
    margin-top: 16px;
`;

const AsideNavList = styled('ul')``;
const AsideNavItem = styled('li')``;
const AsideNavLink = styled(NavLink)`
    display: inline-block;
    text-decoration: none;
    color: #000;
    font-weight: 600;
    padding: 16px 32px;
    width: 100%;
    font-family: 'Cabin';
    font-size: 12px;
    border-left: 8px solid transparent;
    transition: 0.4s all;

    &:hover,
    &.active {
        border-left-color: #740B1D;
        background-color: #F8D8DD;
    }
`;

const Aside = () => {
    const { organization } = useAppSelector(state => state.auth);

    return (
        <AsideMain>
            <AsideLogo src={logoFull} alt="logo" />
            <AsideNav>
                <AsideNavList>
                    <AsideNavItem>
                        <AsideNavLink to="/">Dashboard</AsideNavLink>
                    </AsideNavItem>
                    {organization?.approval_status === 'approved' && (
                        <>
                            <AsideNavItem>
                                <AsideNavLink to="/customers">Customers</AsideNavLink>
                            </AsideNavItem>
                            <AsideNavItem>
                                <AsideNavLink to="/warehousing">Warehousing</AsideNavLink>
                            </AsideNavItem>
                        </>
                    )}
                    <AsideNavItem>
                        <AsideNavLink to="/settings">Organization Settings</AsideNavLink>
                    </AsideNavItem>
                    {organization?.webhooks_enabled && (
                        <AsideNavItem>
                            <AsideNavLink to="/integrations">Integrations</AsideNavLink>
                        </AsideNavItem>
                    )}
                </AsideNavList>
            </AsideNav>
        </AsideMain>
    )
}

export default Aside;
