import * as React from 'react'
import { styled } from '@mui/material/styles';
// import { Link, LinkProps } from 'react-router-dom';

const LinkStyled = styled('a')`
    text-decoration: none;
    color: ${props => props.theme.palette.primary.main};
`;

interface Props {
    children: React.ReactNode
    href: string
}

const UILink: React.FC<Props> = ({ children, ...props }) => {
    return (
        <LinkStyled {...props}>{children}</LinkStyled>
    )
}

export default UILink;