import styled from "@emotion/styled";
import Card, { CardProps } from '@mui/material/Card';

const paddingLevels = {
    'p1': 10,
    'p2': 20,
    'p3': 30,
    'p4': 40,
}

const UICardStyled = styled(Card)<UICardProps>`
    /* width: 100%; */
    padding: ${(props) => props.padding ? paddingLevels[props.padding] : 0}px;
    border-color: #E7E7E7;
`;

interface UICardProps extends CardProps {
    padding?: "p1" | "p2" | "p3" | "p4";
}

const UICard: React.FC<UICardProps> = ({ children, ...props }) => {
    return (
        <UICardStyled variant="outlined" {...props}>
            {children}
        </UICardStyled>
    )
}

export default UICard;