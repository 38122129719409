import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography/Typography";
import Icon from 'components/Icon';
import UICard from "components/UICard";
import { SettingsCard, SettingsDesc } from "./settings.styled";
import { useAppSelector } from 'store/hooks';
import dayjs from 'dayjs';
import Invoice from 'components/Invoice';
import { useNavigate } from 'react-router-dom';

const InvoiceIcon = styled('div')`
    display: flex; 
    align-items: center; 
    gap: 8px;
    margin-top: 54px; 
    margin-bottom: 15px;
`;

const InvoiceListingInfo = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
`;

const InvoiceListingWarning = styled('div')`
    display: flex;
    align-items: center;
    gap: 14px;

    & > p {
        font-weight: 700;
    }
`;

const InvoiceListingViewAll = styled('div')`
    color: ${props => props.theme.palette.primary.main};
    cursor: pointer;

    & > p {
        font-weight: 700;
    }
`;

const BillingInvoices = () => {
    const { billingInvoiceList } = useAppSelector(state => state.auth);
    const navigate = useNavigate();

    const overDueIvoiceList = billingInvoiceList.filter(inv => !inv.is_paid && !dayjs().isBefore(dayjs(inv.due_date)))

    if(billingInvoiceList.length === 0) {
        return null
    }
    
    return (
        <UICard style={{ marginBottom: 32 }}>
            <SettingsCard>
                <Typography variant="body1">Invoices</Typography>

                <SettingsDesc variant="body1">
                    Make sure to stay current on your invoices to ensure there is no interruption to your service for your clients. We offer net-14 day payment terms to help you reconcile and stay current.
                </SettingsDesc>

                <InvoiceIcon>
                    <Icon name="invoice" size={24} />
                    <Typography variant="body1">Latest Invoice</Typography>
                </InvoiceIcon>

                {billingInvoiceList.length > 0 && (
                    <Invoice
                        single={true}
                        invoiceNumber={billingInvoiceList[0].invoice_number}
                        isPaid={billingInvoiceList[0].is_paid}
                        dueDate={billingInvoiceList[0].due_date}
                        pdfURL={billingInvoiceList[0].pdf_url}
                        billable={billingInvoiceList[0].total}
                    />
                )}

                <InvoiceListingInfo>
                    {overDueIvoiceList.length > 0 ? (
                        <InvoiceListingWarning>
                            <Icon name="warning" size={24} />
                            <Typography variant="body1">You have {overDueIvoiceList.length} overdue invoices.</Typography>
                        </InvoiceListingWarning>
                    ) : (
                        <div />
                    )}

                    <InvoiceListingViewAll onClick={() => navigate('/settings/billing')}>
                        <Typography variant="body1">View all invoices →</Typography>
                    </InvoiceListingViewAll>
                </InvoiceListingInfo>
            </SettingsCard>
        </UICard >
    )
}

export default BillingInvoices;