import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// interface ModalBodyProps {
//     children: React.ReactNode
// }

const UIModal = () => { };

const ModalBodyContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalBodyStyled = styled('div')`
    background: #fff;
    color: #000;
    padding: 40px;
    border-radius: 11px;
    width: 580px;
    position: absolute;
    top: 10%;
`;

// React.FC<ModalBodyProps>
// React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>

export const ModalBody = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(({ children, ...props }, ref) => {
    return (
        <ModalBodyContainer ref={ref} tabIndex={-1}>
            <ModalBodyStyled {...props}>
                {children}
            </ModalBodyStyled>
        </ModalBodyContainer>
    )
});

ModalBody.displayName = 'ModalBody';

export default UIModal;