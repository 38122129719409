import * as React from 'react';
import { PageHeading } from 'components/Heading';
import CardLink from 'components/CardLink';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getManifestList } from 'store/wms';
import bgFour from 'assets/images/link-bg-4.png';
import bgFive from 'assets/images/link-bg-5.png';
import UICard from 'components/UICard';
import { LinkContainer, DetailHeading } from './ware-housing.styled';
import {
    Column,
    useTable
} from "react-table";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import { ShipmentTableRow } from 'styles/table.styled';
import { Manifest } from 'store/wms/types';
import Button from 'components/UIButton';
import { withAsync } from 'utils';
import { getManifestDetailsFile } from 'api/wms';
import { Modal } from '@mui/material';
import { ModalBody } from 'components/UIModal';
import { ConfirmModalHeading } from 'styles/modal.styled';
import UICheckbox from 'components/UIForm/CheckBox';
import Icon from 'components/Icon';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { setWMSShowAgain, updateUserSetupGuideById } from 'store/auth';

const showSchema = Yup.object().shape({
    show_again: Yup.boolean()
});

const WareHousing = () => {
    const { manifestsList } = useAppSelector(state => state.wms);
    const { user, setupMeta: { wms_announcement } } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    // const [openAnnouncement, setAnnouncement] = React.useState(!!wms_announcement?.show_again);
    // const [openAnnouncement, setAnnouncement] = React.useState(true);

    const handleGetManifestDetailsFile = async (id: string) => {
        const { response } = await withAsync(() => getManifestDetailsFile(id));

        if (response) {
            window.open(response.data.uploaded_file_url, '_blank');
        }
    }

    const calculateOverPackListCubicMeters = (length: number, width: number, height: number) => {
        return Number((length * width * height) / 1000000).toFixed(3);
    }

    const overPackListColumns: Array<Column<Manifest>> = React.useMemo(() => [
        {
            Header: "Manifest ID",
            accessor: "id",
        },
        {
            Header: "# Overpacks",
            accessor: (originalRow) => originalRow.overpacks.length,
        },
        {
            Header: "# Shipments",
            accessor: (originalRow) => originalRow.total_shipments,
        },
        {
            Header: "Weight (kgs)",
            accessor: (originalRow) => originalRow.overpacks_details.reduce((a, b) => a + b.weight, 0),
        },
        {
            Header: "CBM",
            accessor: (originalRow) => {
                if (originalRow.overpacks_details.length > 0) {
                    return calculateOverPackListCubicMeters(
                        originalRow.overpacks_details[0].length,
                        originalRow.overpacks_details[0].width,
                        originalRow.overpacks_details[0].height
                    )
                }

                return 0;
            },
        },
        {
            Header: "Service",
            accessor: (originalRow) => originalRow?.service || '',
        },
        {
            Header: "Entrypoint",
            accessor: "entry_point",
        },
        {
            Header: "Destination",
            accessor: (originalRow) => originalRow.overpacks_details.length > 0 ? originalRow.overpacks_details[0].exit_point : '',
        },
        {
            id: 'download-button',
            Cell: ({ row }: any) => (
                <Button
                    color="primary"
                    onClick={() => handleGetManifestDetailsFile(row.original.id)}
                >
                    Download
                </Button>
            )
        }
    ], [])

    const {
        getTableProps,
        headerGroups,
        getTableBodyProps,
        rows,
        prepareRow,
    } = useTable(
        {
            data: manifestsList,
            columns: overPackListColumns
        }
    );

    const formik = useFormik({
        initialValues: { show_again: false },
        validationSchema: showSchema,
        onSubmit: async (values, { setStatus, setErrors, setSubmitting }) => {
            // console.log('values: ', values);

            if (values.show_again) {
                dispatch(updateUserSetupGuideById({
                    id: user?.id,
                    setup_metadata: {
                        wms_announcement: {
                            show_again: !values.show_again,
                            // show_again: true,
                            seen_at: new Date()
                        }
                    }
                }))
            } else {
                dispatch(setWMSShowAgain(false));
            }
        }
    });

    React.useEffect(() => {
        dispatch(getManifestList());
    }, [dispatch]);

    // console.log('!!wms_announcement?.show_again: ', !!wms_announcement?.show_again);
    return (
        <>
            <PageHeading variant="h4">Warehousing</PageHeading>

            <LinkContainer style={{ marginBottom: 34 }}>
                <CardLink
                    title="Start Overpacking"
                    bgURL={bgFour}
                    to="/wms/overpacking"
                    style={{ height: 240 }}
                />

                <CardLink
                    title="Start Manifesting"
                    bgURL={bgFive}
                    to="/wms/manifesting"
                    style={{ height: 240 }}
                />
            </LinkContainer>

            <PageHeading style={{ marginBottom: 32 }} variant="h4">Recent Manifests</PageHeading>

            <UICard padding="p4">
                <DetailHeading variant="h6">Manage Manifests</DetailHeading>

                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" {...getTableProps()}>
                        <TableHead>
                            {headerGroups.map(headerGroup => (
                                <TableRow {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <TableCell {...column.getHeaderProps()}>
                                            {/* {column.render('Header')} */}
                                            <Typography>{column.render('Header')}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);

                                return (
                                    <ShipmentTableRow {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <TableCell component="th" scope="row" align={cell.column.id === 'download-button' ? 'right' : 'left'} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                                        ))}
                                    </ShipmentTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </UICard>

            <Modal
                // open={openAnnouncement}
                // open={true}
                open={!!wms_announcement?.show_again}
                style={{ overflow: 'auto' }}
                aria-labelledby="wms-announcement-modal-title"
                aria-describedby="wms-announcement-modal-description"
                // keepMounted
            >
                <ModalBody>
                    <ConfirmModalHeading variant="h2">Carrier Link Warehousing</ConfirmModalHeading>

                    <div style={{ marginTop: 24, marginBottom: 24 }}>
                        <Typography variant="body1">
                            Thank you for choosing to use Carrier Link warehousing tools for overpacking and manifesting your shipments. This add on is free for test shipments. For live shipment pricing, please contact your account representative.
                        </Typography>
                    </div>

                    <form style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onSubmit={formik.handleSubmit}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <UICheckbox
                                icon={<Icon name="checkbox-un" size={24} />}
                                checkedIcon={<Icon name="checkbox" size={24} />}
                                checked={formik.values.show_again}
                                onChange={formik.handleChange}
                                id="show_again"
                                name="show_again"
                            />
                            <Typography>Do not show this message again</Typography>
                        </div>
                        <Button type="submit" color="primary">Continue</Button>
                        {/* <Button color="primary" onClick={() => setAnnouncement(false)}>Continue</Button> */}
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default WareHousing;