export function downloadURI(uri: string, name: string) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
}

export function download(data: File, type: string, name: string) {
    let blob = new Blob([data], { type });
    let url = window.URL.createObjectURL(blob);
    downloadURI(url, name);
    window.URL.revokeObjectURL(url);
}

export function saveFile() {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none;');

    return function (data: File, type: string, fileName: string) {
        var json = JSON.stringify(data),
            blob = new Blob([json], { type: type }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}