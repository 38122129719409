import Typography from "@mui/material/Typography";
import UICard from "components/UICard"
import { useAppSelector } from "store/hooks";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { ShipmentTableRow } from "styles/table.styled";

const LineItems = () => {
    const { currentShipment } = useAppSelector((state) => state.customers)

    return (
        <UICard padding="p4" style={{ marginTop: 32 }}>
            <Typography variant="body1">Line Items</Typography>

            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Entry Point Description</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Weight</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Tax</TableCell>
                            <TableCell>Duty</TableCell>
                            <TableCell>Origin</TableCell>
                            <TableCell>DG Code</TableCell>
                            <TableCell>HTS Code</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentShipment?.line_items.map((item, i) => (
                            <ShipmentTableRow key={"label" + i}>
                                <TableCell component="th" scope="row">
                                    {item.hts_code}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.description}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {/* {item.description} */}
                                    {item.origin_description}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.quantity}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.weight}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.currency}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.value}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.tax}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.duty}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {/* {item.origin_description} */}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.dg_code}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.hts_code}
                                </TableCell>
                            </ShipmentTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </UICard>
    )
}

export default LineItems;