import * as React from 'react';
import UICard from "components/UICard"
import { Modal, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/hooks";
import UIButton from "components/UIButton";
import Icon from "components/Icon";
import { withAsync } from "utils";
import { getBulkShipmentErrorFile, getBulkShipmentSummaryFile } from "api/customers";
import { createbulkUploadShipment, doBulkOperations, getCustomerBulkShipmentsById, getCustomerById, resetBulkShipmentStatus } from "store/customers";
import { useParams } from "react-router-dom";
import HeadingCrumb from "components/HeadingCrumb";
import { ModalBody } from 'components/UIModal';
import {
    ShipmentStatus
} from "./bulk-history.styled";
import { BulkOperation, BulkShipment } from 'store/customers/types';
import { DetailHeading, ShipmentHeader } from 'styles/headings.styled';
import { ShipmentTableRow, ShipmentNameTableCell, ShipmentTableActions, BulkActionCell } from 'styles/table.styled';
import { ShipmentActionsSelect, ShipmentActionsItem } from 'styles/select.styled';
import {
    BulkModalHeading,
    BulkUploadContainer,
    BulkModalInfo,
    BulkModalDiv,
    BulkModalActions,
    BulkModalGuide,
    ModalGuideContainer,
    UploadingInd,
    UploadingSuccess,
    ConfirmModalContent,
    ConfirmModalHeading
} from 'styles/modal.styled';

const status = {
    "processing": "Processing",
    "partial_success": "Partial Success",
    "success": "Success",
    "failure": "Failure"
}

const BulkUploadHistory: React.FC<{ handlePagination?: (cursor: string | null) => void }> = ({ handlePagination }) => {
    const { customerId } = useParams();
    const { bulkShipmentsList, bulkShipmentsListNext, bulkShipmentsListPrevious, currentCustomer, createbulkUploadShipmentStatus } = useAppSelector(({ customers }) => customers);
    const [openShipmentActions, setOpenShipmentActions] = React.useState('');
    const dispatch = useAppDispatch();
    const [currentShipment, setCurrentShipment] = React.useState<BulkShipment | null>(null);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const fileUploadRef = React.useRef<HTMLInputElement>(null);
    const [file, setFile] = React.useState<any>(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCloseConfirm = () => setOpenConfirm(false);

    const handleOpenShipmentActions = (id: string, disabled: boolean) => {
        if (!disabled) {
            setOpenShipmentActions(id);
        }
    }

    const handleGetErrorFile = async (id: string) => {
        const { response } = await withAsync(() => getBulkShipmentErrorFile(id));

        if (response) {
            window.open(response.data.uploaded_file_url, '_blank');
        }
    }

    const handleGetSummaryFile = async (id: string) => {
        const { response } = await withAsync(() => getBulkShipmentSummaryFile(id));

        if (response) {
            window.open(response.data.uploaded_file_url, '_blank');
        }
    }

    const handleBulkShipmentsPagination = (cursor: string | null) => {
        if (cursor && customerId) {
            dispatch(getCustomerBulkShipmentsById({ customerId, cursor }));
        }
    }

    const handleConfirmCancelLabel = React.useCallback((id: string) => {
        const shipment = bulkShipmentsList.find(ship => ship.id === id);

        if (shipment) {
            setCurrentShipment(shipment);
            setOpenConfirm(true);
        }
    }, [bulkShipmentsList])

    const handleBulkOpertion = ({ operation_type }: Pick<BulkOperation, 'operation_type'>, id: string) => {
        if (id) {
            dispatch(doBulkOperations({
                operation_type,
                bulk_shipment_metadata_ids: [id]
            })).then(() => {
                setOpenConfirm(false);

                const customEvent = new CustomEvent('bulk-polling:start');
                dispatchEvent(customEvent);
            });
        }
    }

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];

        setFile(file);
    }

    const handleFileRemove = () => {
        if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
        }
        setFile(null)
    }

    const handleFileUpload = () => {
        if (customerId) {
            dispatch(createbulkUploadShipment({
                file,
                customer_id: customerId
            }))
        }
    }

    const handleBulkShipmentSuccess = () => {
        dispatch(resetBulkShipmentStatus());
        setOpen(false)
        setFile(null);
        // navigate(`/customers/${customerId}/shipments/bulk`)
    }

    React.useEffect(() => {
        if (customerId) {
            dispatch(getCustomerBulkShipmentsById({ customerId }));
            dispatch(getCustomerById(customerId));
        }
    }, [dispatch, customerId]);

    return (
        <>
            {/* <HeadingCrumb limit={2} lastElement="Bulk Shipments Uploads" /> */}
            <HeadingCrumb alias={['Customers', currentCustomer?.company_name || '', 'Bulk Shipments Uploads']} limit={3} />

            <UICard padding="p4" style={{ marginTop: 32 }}>
                <ShipmentHeader>
                    <DetailHeading variant="h6">Bulk Upload History</DetailHeading>

                    <UIButton onClick={handleOpen} disabled={currentCustomer?.activation_status === 'disabled'}>Bulk Upload</UIButton>
                </ShipmentHeader>

                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell># Shipments</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Uploaded By</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bulkShipmentsList.map(shipment => (
                                <ShipmentTableRow key={shipment.id}>
                                    <TableCell component="th" scope="row">
                                        {shipment.id}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {shipment.shipment_count ? shipment.shipment_count : ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <ShipmentStatus status={shipment.status}>
                                            {status[shipment.status]}
                                        </ShipmentStatus>
                                    </TableCell>
                                    <ShipmentNameTableCell component="th" scope="row" primary={+shipment.is_created_by_an_admin}>
                                        {shipment.is_created_by_an_admin ? shipment.created_by_user : `Customer (${shipment.created_by_user})`}
                                    </ShipmentNameTableCell>
                                    <BulkActionCell component="th" scope="row" align="right">
                                        {shipment.status === 'failure' || shipment.status === 'partial_success' ? (
                                            <UIButton
                                                variant="outlined"
                                                style={{ marginRight: 10 }}
                                                startIcon={<Icon name="download" size={16} masked={true} color="black" />}
                                                onClick={() => handleGetErrorFile(shipment.id)}
                                            >
                                                Errors File
                                            </UIButton>
                                        ) : null}
                                        {shipment.status === 'success' || shipment.status === 'partial_success' || shipment.status === 'processing' ? (
                                            <UIButton
                                                variant={shipment.status === 'processing' ? 'contained' : 'outlined'}
                                                startIcon={<Icon name="download" size={16} masked={true} color="black" />}
                                                disabled={shipment.status === 'processing'}
                                                onClick={() => handleGetSummaryFile(shipment.id)}
                                            >
                                                Summary File
                                            </UIButton>
                                        ) : null}

                                        <FormControl style={{ width: 28, height: 28 }} size="small">
                                            <ShipmentActionsSelect
                                                labelId={"ship-select-small-" + shipment.id}
                                                id={"ship-select-small-" + shipment.id}
                                                open={openShipmentActions === shipment.id}
                                                onClick={() => handleOpenShipmentActions(openShipmentActions ? '' : shipment.id, shipment.status === 'processing' || shipment.status === 'failure')}
                                                disabled={shipment.status === 'processing' || shipment.status === 'failure'}
                                            >
                                                <ShipmentActionsItem
                                                    onClick={() => handleConfirmCancelLabel(shipment.id)}
                                                >
                                                    <Icon name="cancel" size={16} masked={true} color="black" />
                                                    <Typography>Cancel Shipments</Typography>
                                                </ShipmentActionsItem>
                                                <ShipmentActionsItem
                                                    onClick={() => handleBulkOpertion({ operation_type: 'download_labels' }, shipment.id)}
                                                >
                                                    <Icon name="download-full" size={16} masked={true} color="black" />
                                                    <Typography>Download Labels</Typography>
                                                </ShipmentActionsItem>
                                            </ShipmentActionsSelect>
                                        </FormControl>
                                    </BulkActionCell>
                                </ShipmentTableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <ShipmentTableActions>
                        <button onClick={() => handleBulkShipmentsPagination(bulkShipmentsListPrevious)} disabled={!Boolean(bulkShipmentsListPrevious)}>
                            <Typography variant="body1">{'<'} Prev</Typography>
                        </button>
                        <button onClick={() => handleBulkShipmentsPagination(bulkShipmentsListNext)} disabled={!Boolean(bulkShipmentsListNext)}>
                            <Typography variant="body1">Next {'>'}</Typography>
                        </button>
                    </ShipmentTableActions>
                </TableContainer>


                <Modal
                    open={openConfirm}
                    onClose={handleCloseConfirm}
                    style={{ overflow: 'auto' }}
                    aria-labelledby="confirm-cancel-ship-modal-title"
                    aria-describedby="confirm-cancel-ship-modal-description"
                    keepMounted
                >
                    <ModalBody>
                        <ConfirmModalHeading variant="h2">Confirmation</ConfirmModalHeading>

                        <ConfirmModalContent>
                            <Typography variant="body1">
                                Are you sure you want to cancel label under shipment <b>{currentShipment?.id}</b> ? This action cannot be undone, so you will need to re-create this shipment if you cancel its’ label.
                            </Typography>

                            <Typography variant="body1">
                                You will still be able to see this shipment in your shipments dashboard.
                            </Typography>
                        </ConfirmModalContent>

                        <UIButton onClick={() => handleBulkOpertion({ operation_type: 'cancel_labels' }, currentShipment?.id || '')} fullWidth>Cancel Label and Shipment</UIButton>
                        <UIButton variant="outlined" style={{ marginTop: 8 }} onClick={handleCloseConfirm} fullWidth>Keep Label and Shipment</UIButton>
                    </ModalBody>
                </Modal>
            </UICard>

            <Modal
                open={open}
                onClose={handleClose}
                style={{ overflow: 'auto' }}
                aria-labelledby="bulk-ship-modal-title"
                aria-describedby="bulk-ship-modal-description"
                keepMounted
            >
                <ModalBody>
                    <BulkModalHeading variant="h2">Bulk Shipment Upload</BulkModalHeading>

                    <BulkUploadContainer>
                        <input
                            ref={fileUploadRef}
                            type="file"
                            style={{ display: "none" }}
                            onChange={onFileChange}
                            accept=".csv, .xls, .xlsx"
                        />

                        <Icon name="file-xlsx" size={60} />
                        <BulkModalInfo variant="body1">
                            {file ? (
                                <>
                                    {file.name}
                                </>
                            ) : (
                                <>
                                    Drag a .csv, .xls or .xlsx <br /> here to upload
                                </>
                            )}
                        </BulkModalInfo>

                        {!file ? (
                            <>
                                <BulkModalDiv>
                                    <Typography variant="body1">OR</Typography>
                                </BulkModalDiv>
                                <UIButton onClick={() => fileUploadRef.current?.click()}>Browse Files</UIButton>
                            </>
                        ) : null}
                    </BulkUploadContainer>

                    {(createbulkUploadShipmentStatus === 'IDLE' && (
                        <>
                            {file ? (
                                <BulkModalActions>
                                    <UIButton onClick={handleFileUpload}>Confirm and Upload</UIButton>
                                    <UIButton variant="outlined" onClick={handleFileRemove}>Remove file</UIButton>
                                </BulkModalActions>
                            ) :
                                <Typography variant="body1" style={{ fontStyle: 'italic', fontWeight: 400, fontFamily: 'Inter', textAlign: 'end' }}>Accepts .csv and .xls files less than 10MB</Typography>
                            }

                            <ModalGuideContainer>
                                <Typography variant="body1">Upload Guidelines</Typography>

                                <BulkModalGuide variant="body1">
                                    Your file should follow our <a href="https://carrierlink.notion.site/Upload-Guidelines-for-Shipments-fd3ae26ff03346da8e797e00e31ddcc3" target="_blank" rel="noreferrer">Upload Guidelines</a> and will take a few moments to process after uploaded.
                                </BulkModalGuide>
                                <BulkModalGuide variant="body1">You can also download a sample CSV by pressing the download button below.</BulkModalGuide>
                            </ModalGuideContainer>

                            <UIButton
                                startIcon={<Icon name="download" size={16} masked={true} />}
                                onClick={() => window.open('https://carrierlink-live-bucket.s3.amazonaws.com/static/shipments_bulk_upload_sample.csv', '_blank')}
                            >
                                Download Sample CSV
                            </UIButton>
                        </>
                    )) || (createbulkUploadShipmentStatus === 'PENDING' && (
                        <UploadingInd>
                            <CircularProgress color="black" size={24} thickness={6} />
                            <Typography>Uploading...</Typography>
                        </UploadingInd>
                    )) || (createbulkUploadShipmentStatus === 'SUCCESS' && (
                        <>
                            <UploadingSuccess>
                                <Icon name="success" size={24} />
                                <Typography>Success! File uploaded</Typography>
                            </UploadingSuccess>
                            <UIButton onClick={handleBulkShipmentSuccess} fullWidth>See results</UIButton>
                        </>
                    ))}
                </ModalBody>
            </Modal>
        </>
    )
}

export default BulkUploadHistory;