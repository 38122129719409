import * as React from 'react';
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Icon from "components/Icon";
import UIButton from "components/UIButton";
import UICard from "components/UICard";
import { SettingsCard, SettingsDesc } from "./settings.styled";
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { updateUserOrganization } from 'store/auth';

const ApprovalItemContainer = styled('div')`
    margin-top: 44px;
`;

const ApprovalItemStyled = styled('div')`
    display: flex;
    align-items: center;

    & > p {
        margin-left: 13px;
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

const ApprovalSettingsCard = styled(SettingsCard)`
    display: flex;
    align-items: center;
`;

const ApprovalPendingInfo = styled('div')`
    display: flex;
    align-items: center;
    margin-left: 24px;

    & > p {
        font-style: italic;
        margin-left: 8px;
        color: ${props => props.theme.palette.greyOne.main};
    }
`;

const ApprovalItem: React.FC<{ name: string, isConnected: boolean }> = ({ name, isConnected }) => {
    return (
        <ApprovalItemStyled>
            <Icon name={isConnected ? 'success' : 'warning'} size={24} />
            <Typography variant="body1">{name}</Typography>
        </ApprovalItemStyled>
    )
}

const OrganizationApproval = () => {
    const organization = useAppSelector(({ auth }) => auth.organization);
    const dispatch = useAppDispatch();

    const handleSubmitApproval = () => {
        dispatch(updateUserOrganization({ approval_status: 'submitted' }));
    }

    if (organization?.approval_status === 'approved') {
        return null;
    }

    return (
        <UICard style={{ marginBottom: 32 }}>
            <SettingsCard>
                <Typography variant="body1">Organization Approval</Typography>
                <SettingsDesc variant="body1">Once you have confirmed your branding, team, billing information, and carrier configuration, you will need to submit your organization for approval by our team. We’ll check that everything is in good shape for your rollout to your customers.</SettingsDesc>

                <ApprovalItemContainer>
                    <ApprovalItem name="Provider Connected" isConnected={(organization?.auth_configs?.length || []) > 0} />
                    <ApprovalItem name="Carrier Configured" isConnected={!!(organization?.has_service_configs)} />
                    <ApprovalItem name="Billing Details Added" isConnected={!!(organization?.billing_address)} />
                    <ApprovalItem name="Hosted Domain Added" isConnected={!!(organization?.is_domain_verified)} />
                    <ApprovalItem name="Team Set up" isConnected={!!(organization?.has_team)} />
                </ApprovalItemContainer>
            </SettingsCard>

            <ApprovalSettingsCard>
                <UIButton
                    onClick={handleSubmitApproval}
                    disabled={
                        !((organization?.auth_configs?.length || []) > 0 &&
                            !!(organization?.has_service_configs) &&
                            !!(organization?.billing_address) &&
                            !!(organization?.is_domain_verified) &&
                            !!(organization?.has_team) &&
                            !(!!(organization?.approval_status !== 'pending')))
                    }
                >
                    Submit for Approval
                </UIButton>

                {organization?.approval_status === 'submitted' ? (
                    <ApprovalPendingInfo>
                        <Icon name="success" size={24} />
                        <Typography variant="body1">Your approval request is pending.</Typography>
                    </ApprovalPendingInfo>
                ) : null}
            </ApprovalSettingsCard>
        </UICard>
    )
}

export default OrganizationApproval;