import * as React from 'react';
// import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import DownloadingIcon from '@mui/icons-material/Downloading';
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
// import IconButton from '@mui/material/IconButton';
// import Button from '@mui/material/Button';
// import CloseIcon from '@mui/icons-material/Close';
// import SnackbarContent from '@mui/material/SnackbarContent';

interface Props {
    title: string
    children: React.ReactNode
    action: 'download' | 'cancel' | 'success'
}

const ActionIcon: React.FC<Pick<Props, 'action'>> = ({ action }) => {
    switch (action) {
        case 'download':
            return <DownloadingIcon fontSize="inherit" />;
        case 'cancel':
            return <PendingIcon fontSize="inherit" />;
        case 'success':
            return <TaskAltIcon fontSize="inherit" />;
        default:
            return <DownloadingIcon fontSize="inherit" />;
    }
}

const ActionSnackBar: React.FC<Props> = ({ title, children, action }) => {
    return (
        <Alert
            color="primary"
            variant="filled"
            icon={<ActionIcon action={action} />}
        >
            <AlertTitle>{title}</AlertTitle>
            {children}
        </Alert>
    )
}

export default ActionSnackBar;