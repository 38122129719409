import * as React from 'react';
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import TopBar from 'components/TopBar';

const WareLayoutStyled = styled('div')`
    display: flex;
`;

const Main = styled('main')`
    flex: 1 1;
    height: 100vh;
    overflow: auto;
    contain: content;
`;

const WareLayoutContent = styled('div')`
    padding: 48px 32px;
`;

const WareLayout = () => {
    return (
        <WareLayoutStyled>
            <Main>
                <TopBar showLogo={true} />
                <WareLayoutContent>
                    <Outlet />
                </WareLayoutContent>
            </Main>
        </WareLayoutStyled>
    )
}

export default WareLayout;