import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const InputStyled = styled(TextField) <{ bg: string }>`
    /* border-radius: 6px; */
    width: 100%;
    min-width: 256px;

    & input::placeholder {
        color: ${props => props.theme.palette.greyOne.main};
        font-weight: 600;
    }
    
    & input {
        padding: 10px 16px;
        font-weight: 600;
        border-radius: 6px;
    }

    fieldset {
        border-color: ${(props) => !props.error && '#E7E7E7'} !important;
    }

    .MuiInputBase-root {
        border-radius: 6px;
    }

    .Mui-disabled {
        ${props => props.bg ? 'background-color: #DDDDDD' : ''};
    }

    .MuiInputAdornment-root {
        background: #E7E7E7;
        padding: 21px 16px;
        margin: 0px;
        border-radius: 0px 6px 6px 0px;
        color: #808080;
    }

    .MuiInputBase-adornedEnd {
        padding: 0px;
        border-radius: 6px;
    }
`;

type Props = TextFieldProps & { bg?: boolean }

const Input: React.FC<Props> = React.forwardRef(({ bg = true, ...props }, ref) => {
    return (
        <InputStyled ref={ref} bg={bg ? `${bg}` : ''} {...props} />
    )
})

export default Input;