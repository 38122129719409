import * as React from "react";
// import { PageHeading } from "components/Heading";
import Services from "./Services";
import { SettingsHeading } from "pages/OrganizationSettings/settings.styled";
import HeadingCrumb from "components/HeadingCrumb";

const CarrierConfig = () => {
    return (
        <>
            {/* <PageHeading variant="h4">Carrier Configuration</PageHeading> */}
            <HeadingCrumb />

            <SettingsHeading variant="h5">Products</SettingsHeading>
            <Services />
        </>
    )
}

export default CarrierConfig;