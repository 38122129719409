import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';

const UIDatePicker = styled(DatePicker)`
    fieldset {
        border-color: ${(props: any) => props.InputProps && !props.InputProps.error && '#E7E7E7'} !important;
    }

    .MuiIconButton-root {
        margin: 0px;
        padding: 0px
    }
`;

export default UIDatePicker;