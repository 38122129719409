import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from 'store/auth';
import logoFull from 'assets/logo-full.svg';

const TopBarMain = styled('div')`
    /* position: fixed; */
    /* top: 0; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    padding: 20px;
    margin-left: 32px;
    margin-right: 32px;
    border-bottom: 1px solid #E7E7E7;
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    /* background-color: #fff; */
`;

const AsideLogo = styled('img')` 
    width: 180px;
    /* padding: 18px 10px; */
    /* margin-top: 10px; */
`;

const UserMenuButton = styled(Button)`
    display: flex;
    align-items: center;
    border: none !important;
    text-transform: capitalize;
`;

const UserMenuInfo = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 16px;
`;

const UserMenuName = styled(Typography)``;

const UserMenuDesc = styled(Typography)`
  color: ${props => props.theme.palette.greyOne.main};
`;

const IconMenu = styled(Icon)`
    color: ${props => props.theme.palette.greyOne.main};
`;

const TopBar = ({ showLogo }: { showLogo: boolean }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const user = useAppSelector(state => state.auth.user);
    const organization = useAppSelector(state => state.auth.organization);
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <TopBarMain>
            {showLogo ? <AsideLogo src={logoFull} alt="logo" /> : <div />}

            <UserMenuButton variant="outlined" color="black" onClick={handleClick}>
                <UserMenuInfo>
                    <UserMenuName variant="body1">{`${user?.first_name} ${user?.last_name}`}</UserMenuName>
                    <UserMenuDesc variant="body1">{`${organization?.business_name}`}</UserMenuDesc>
                </UserMenuInfo>
                <div>
                    {open ? (
                        <IconMenu>
                            <KeyboardArrowUpIcon />
                        </IconMenu>
                    ) : (
                        <IconMenu>
                            <KeyboardArrowDownIcon />
                        </IconMenu>
                    )}
                </div>
            </UserMenuButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => navigate('/account/settings')}>
                    Account Settings
                </MenuItem>
                <MenuItem onClick={() => dispatch(logoutUser())}>
                    Logout
                </MenuItem>
            </Menu>
        </TopBarMain>
    )
}

export default TopBar;