import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Icon from "components/Icon";
import UICard from "components/UICard";

const SigupCompleteHeading = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    & > h5 {
        color: #000;
        margin-left: 8px;
    }
`;

const TypographyInfo = styled(Typography)`
    font-size: 20px;

    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
`;

const SigupComplete = () => {
    return (
        <UICard padding="p4" style={{ textAlign: 'center' }}>
            <SigupCompleteHeading>
                <Icon name="success" size={24} />
                <Typography variant="h5">Almost there</Typography>
            </SigupCompleteHeading>

            <TypographyInfo variant="h5">You should have received an email from us.</TypographyInfo>
            <TypographyInfo variant="h5">Click the link in that email to get started.</TypographyInfo>
        </UICard>
    )
}

export default SigupComplete;