import * as React from 'react';
import { CircularProgress, Modal, Typography } from "@mui/material";
import Menu from '@mui/material/Menu';
import UIButton from "components/UIButton";
import UICard from "components/UICard"
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ModalBody } from 'components/UIModal';
import Icon from 'components/Icon';
import { createbulkUploadShipment, doBulkOperations, getCustomerById, getCustomerShipmentsById, removeShipmentById, resetBulkShipmentStatus } from 'store/customers';
import { useNavigate, useParams } from 'react-router-dom';
import { withAsync } from 'utils';
import { cancelShipmentLabel } from 'api/customers';
import { BulkOperation, ShipmentListSingle } from 'store/customers/types';
import HeadingCrumb from 'components/HeadingCrumb';
import { ShipmentDataHeader } from "./shipments.styled";
import { CustomerHeading, DetailHeading, ShipmentHeader } from 'styles/headings.styled';
import { ShipmentActionsItem } from 'styles/select.styled';
import {
  BulkModalHeading,
  BulkUploadContainer,
  BulkModalInfo,
  BulkModalDiv,
  BulkModalActions,
  BulkModalGuide,
  ModalGuideContainer,
  UploadingInd,
  UploadingSuccess,
  ConfirmModalContent,
  ConfirmModalHeading
} from 'styles/modal.styled';
import { ShipmentFilters } from './shipments.types';
import ShipmentTable from './ShipmentTable';

const Shipments = () => {
  const { customerId } = useParams();
  const [filterList, setFilterList] = React.useState<ShipmentFilters[]>([]);
  const { shipmentsList, createbulkUploadShipmentStatus, currentCustomer, getCustomerShipmentsByIdStatus } = useAppSelector(({ customers }) => customers);
  const [currentShipment, setCurrentShipment] = React.useState<ShipmentListSingle | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openFilters, setOpenFilters] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const [enableBulkAction, setEnableBulkAction] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openBulkActions = Boolean(anchorEl);
  const [file, setFile] = React.useState<any>(null);
  const [selectedRowIds, setSelectedRowIds] = React.useState({});
  const [triggerHideColumn, setTriggerHideColumn] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleOpenFilters = () => setOpenFilters(true);
  const handleCloseFilters = () => setOpenFilters(false);

  const handleOpenBulkActions = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const handleCloseBulkActions = () => setAnchorEl(null);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    setFile(file);
  }

  const handleFileRemove = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.value = '';
    }
    setFile(null)
  }

  const handleFileUpload = () => {
    if (customerId) {
      dispatch(createbulkUploadShipment({
        file,
        customer_id: customerId
      }))
    }
  }

  const handleBulkShipmentSuccess = () => {
    dispatch(resetBulkShipmentStatus());
    setOpen(false)
    setFile(null);
    navigate(`/customers/${customerId}/shipments/bulk`)
  }

  const handleCancelLabel = async () => {
    if (currentShipment) {
      const { response } = await withAsync(() => cancelShipmentLabel(currentShipment?.tracking_number));

      console.log('response: ', response);
      if (response) {
        dispatch(removeShipmentById(currentShipment.id));
      }

      setOpenConfirm(false);
    }
  }

  const toggleBulkAction = () => {
    setEnableBulkAction(!enableBulkAction);
    setTriggerHideColumn(!triggerHideColumn);
  }

  const handleBulkOpertion = ({ operation_type }: Pick<BulkOperation, 'operation_type'>) => {
    const Ids = Object.keys(selectedRowIds);

    if (Ids.length > 0) {
      dispatch(doBulkOperations({
        operation_type,
        single_shipment_ids: Ids
      })).then(() => {
        toggleBulkAction();

        const customEvent = new CustomEvent('bulk-polling:start');
        dispatchEvent(customEvent);
      });
    }
  }

  React.useEffect(() => {
    if (customerId) {
      dispatch(getCustomerShipmentsById({ customerId }));
      dispatch(getCustomerById(customerId));
    }
  }, [dispatch, customerId]);

  return (
    <>
      <HeadingCrumb alias={['Customers', currentCustomer?.company_name || '', 'Shipments']} />
      <CustomerHeading variant="h6">Shipments</CustomerHeading>

      <UICard padding="p4">
        <ShipmentHeader>
          <DetailHeading variant="h6">Manage Shipments</DetailHeading>
          <UIButton onClick={handleOpen} disabled={currentCustomer?.activation_status === 'disabled'}>Bulk Upload</UIButton>
        </ShipmentHeader>

        {(getCustomerShipmentsByIdStatus !== 'PENDING' && shipmentsList.length > 0) && (
          <ShipmentDataHeader>
            {!enableBulkAction ? (
              <UIButton variant="outlined" onClick={toggleBulkAction} disabled={shipmentsList.length === 0}>Bulk Actions</UIButton>
            ) : (
              <>
                <UIButton
                  id="bulk-action-button"
                  aria-controls={openBulkActions ? 'bulk-action-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openBulkActions ? 'true' : undefined}
                  onClick={handleOpenBulkActions}
                  disableElevation
                  endIcon={<Icon name="arrow-fill-down" masked={true} size={10} />}
                >
                  Bulk Actions
                </UIButton>
                <Menu
                  anchorEl={anchorEl}
                  id="bulk-action-menu"
                  open={openBulkActions}
                  onClose={handleCloseBulkActions}
                  onClick={handleCloseBulkActions}
                  transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                  MenuListProps={{
                    'aria-labelledby': 'bulk-action-button',
                  }}
                  keepMounted
                >
                  <ShipmentActionsItem onClick={() => handleBulkOpertion({ operation_type: 'cancel_labels' })}>
                    <Icon name="cancel" size={16} masked={true} color="black" />
                    <Typography>Cancel Shipments</Typography>
                  </ShipmentActionsItem>
                  <ShipmentActionsItem onClick={() => handleBulkOpertion({ operation_type: 'download_labels' })}>
                    <Icon name="download-full" size={16} masked={true} color="black" />
                    <Typography>Download Labels</Typography>
                  </ShipmentActionsItem>
                  <ShipmentActionsItem onClick={toggleBulkAction} >
                    <Icon name="x-mark" size={14} masked={true} color="black" />
                    <Typography>Close Bulk Action</Typography>
                  </ShipmentActionsItem>
                </Menu>
              </>
            )}

            <div>
              <UIButton
                variant="outlined"
                color="primary"
                onClick={handleOpenFilters}
                startIcon={<Icon name="filters" masked={true} size={16} color="primary" />}
              >
                Filters ({filterList.length})
              </UIButton>
            </div>
          </ShipmentDataHeader>
        )}

        <ShipmentTable
          customerId={customerId}
          filterList={filterList}
          openFilters={openFilters}
          handleCloseFilters={handleCloseFilters}
          setFilterList={setFilterList}
          isTriggerHideColumn={triggerHideColumn}
          setCurrentShipment={setCurrentShipment}
          setOpenConfirm={setOpenConfirm}
          setOpenFilters={setOpenFilters}
          onRowSelectStateChange={setSelectedRowIds}
        />

        <Modal
          open={open}
          onClose={handleClose}
          style={{ overflow: 'auto' }}
          aria-labelledby="bulk-ship-modal-title"
          aria-describedby="bulk-ship-modal-description"
          keepMounted
        >
          <ModalBody>
            <BulkModalHeading variant="h2">Bulk Shipment Upload</BulkModalHeading>

            <BulkUploadContainer>
              <input
                ref={fileUploadRef}
                type="file"
                style={{ display: "none" }}
                onChange={onFileChange}
                accept=".csv, .xls, .xlsx"
              />

              <Icon name="file-xlsx" size={60} />
              <BulkModalInfo variant="body1">
                {file ? (
                  <>
                    {file.name}
                  </>
                ) : (
                  <>
                    Drag a .csv, .xls or .xlsx <br /> here to upload
                  </>
                )}
              </BulkModalInfo>

              {!file ? (
                <>
                  <BulkModalDiv>
                    <Typography variant="body1">OR</Typography>
                  </BulkModalDiv>
                  <UIButton onClick={() => fileUploadRef.current?.click()}>Browse Files</UIButton>
                </>
              ) : null}
            </BulkUploadContainer>

            {(createbulkUploadShipmentStatus === 'IDLE' && (
              <>
                {file ? (
                  <BulkModalActions>
                    <UIButton onClick={handleFileUpload}>Confirm and Upload</UIButton>
                    <UIButton variant="outlined" onClick={handleFileRemove}>Remove file</UIButton>
                  </BulkModalActions>
                ) :
                  <Typography variant="body1" style={{ fontStyle: 'italic', fontWeight: 400, fontFamily: 'Inter', textAlign: 'end' }}>Accepts .csv and .xls files less than 10MB</Typography>
                }

                <ModalGuideContainer>
                  <Typography variant="body1">Upload Guidelines</Typography>

                  <BulkModalGuide variant="body1">
                    Your file should follow our <a href="https://carrierlink.notion.site/Upload-Guidelines-for-Shipments-fd3ae26ff03346da8e797e00e31ddcc3" target="_blank" rel="noreferrer">Upload Guidelines</a> and will take a few moments to process after uploaded.
                  </BulkModalGuide>
                  <BulkModalGuide variant="body1">You can also download a sample CSV by pressing the download button below.</BulkModalGuide>
                </ModalGuideContainer>

                <UIButton
                  startIcon={<Icon name="download" size={16} masked={true} />}
                  onClick={() => window.open('https://carrierlink-live-bucket.s3.amazonaws.com/static/shipments_bulk_upload_sample.csv', '_blank')}
                >
                  Download Sample CSV
                </UIButton>
              </>
            )) || (createbulkUploadShipmentStatus === 'PENDING' && (
              <UploadingInd>
                <CircularProgress color="black" size={24} thickness={6} />
                <Typography>Uploading...</Typography>
              </UploadingInd>
            )) || (createbulkUploadShipmentStatus === 'SUCCESS' && (
              <>
                <UploadingSuccess>
                  <Icon name="success" size={24} />
                  <Typography>Success! File uploaded</Typography>
                </UploadingSuccess>
                <UIButton onClick={handleBulkShipmentSuccess} fullWidth>See results</UIButton>
              </>
            ))}
          </ModalBody>
        </Modal>

        <Modal
          open={openConfirm}
          onClose={handleCloseConfirm}
          style={{ overflow: 'auto' }}
          aria-labelledby="confirm-cancel-ship-modal-title"
          aria-describedby="confirm-cancel-ship-modal-description"
          keepMounted
        >
          <ModalBody>
            <ConfirmModalHeading variant="h2">Confirmation</ConfirmModalHeading>

            <ConfirmModalContent>
              <Typography variant="body1">
                Are you sure you want to cancel label <b>{currentShipment?.tracking_number}</b> under shipment <b>{currentShipment?.id}</b> ? This action cannot be undone, so you will need to re-create this shipment if you cancel its’ label.
              </Typography>

              <Typography variant="body1">
                You will still be able to see this shipment in your shipments dashboard.
              </Typography>
            </ConfirmModalContent>

            <UIButton onClick={handleCancelLabel} fullWidth>Cancel Label and Shipment</UIButton>
            <UIButton variant="outlined" style={{ marginTop: 8 }} onClick={handleCloseConfirm} fullWidth>Keep Label and Shipment</UIButton>
          </ModalBody>
        </Modal>
      </UICard >
    </>
  )
}

export default Shipments;