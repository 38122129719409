import { Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";

const UICheckbox = styled(Checkbox)`
    /* width: 32px; */
    /* height: 32px; */
    margin-right: 16px;
    padding: 0px;
    
    .MuiSvgIcon-root {
        font-size: 32px;
    }
`;

export default UICheckbox;