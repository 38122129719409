import axios from 'axios';
import { withAsync } from 'utils';
import api from '../index';
import {
    GetCustomersResponse,
    GetCustomerResponse,
    CustomerActivationPayload,
    CustomerActivationStatusResponse,
    GetCustomerShipmentsPayload,
    GetCustomerShipmentsResponse,
    BulkUploadShipmentPayload,
    BulkUploadShipmentFileResponse,
    BulkUploadShipmentResponse,
    GetCustomerBulkShipmentsResponse,
    GetCustomerBulkShipmentsPayload,
    GetShipmentResponse,
    BulkOperationPayload,
    BulkOperationResponse,
    GetBulkOperationResponse,
    UpdateCustomerPayload
} from './types';

export const getAllCustomers = (cursor?: string) => {
    return api.get<GetCustomersResponse>('/customers', { params: { cursor } }).then(res => res.data);
}

export const getCustomer = (customer_id?: string) => {
    return api.get<GetCustomerResponse>(`/customers/${customer_id}`).then(res => res.data);
}

export const updateCustomer = (payload: UpdateCustomerPayload) => {
    return api.patch<GetCustomerResponse>(`/customers/${payload.customer_id}`, payload.data).then(res => res.data);
}

export const updateCustomerActivationStatus = (data: CustomerActivationPayload) => {
    return api.put<CustomerActivationStatusResponse>(`/customers/${data.id}/update_access`, { activation_status: data.activation_status }).then(res => res.data);
}

export const getCustomerShipments = ({ customerId, cursor, ...filter }: GetCustomerShipmentsPayload) => {
    return api.get<GetCustomerShipmentsResponse>('/shipments', {
        params: { cursor, customer: customerId, status: 'created', ...filter }
    }).then(res => res.data);
}

export const bulkUploadShipment = async (data: BulkUploadShipmentPayload) => {
    const { response } = await withAsync(() => api.post<BulkUploadShipmentFileResponse>('/shipments/files/create', { file_path: `${data.file.name}` }));

    if (response) {
        const { error } = await withAsync(() => axios.put(response.data.presigned_url, data.file, { headers: { 'Content-Type': data.file.type } }));

        if (error) {
            throw new Error('Error updating organization!');
        } else {
            return api.post<BulkUploadShipmentResponse>('/bulk_shipments', {
                file: response.data.id,
                customer: data.customer_id
            }).then(res => res.data);
        }
    }
}

export const getCustomerBulkShipments = ({ customerId, cursor }: GetCustomerBulkShipmentsPayload) => {
    return api.get<GetCustomerBulkShipmentsResponse>('/bulk_shipments', {
        params: {
            cursor,
            customer: customerId,
        }
    }).then(res => res.data);
}

export const getShipment = (id: string) => {
    return api.get<GetShipmentResponse>(`/shipments/${id}`).then(res => res.data);
}

export const getBulkShipmentSummaryFile = (id: string) => {
    return api.get<{ id: string, uploaded_file_url: string }>(`/bulk_shipments/${id}/summary_file`);
}

export const getBulkShipmentErrorFile = (id: string) => {
    return api.get<{ id: string, uploaded_file_url: string }>(`/bulk_shipments/${id}/error_file`);
}

export const getShipmentLabel = (id: string) => {
    return api.get<File>(`/labels/${id}/download`, {
        responseType: 'blob',
    });
}

export const cancelShipmentLabel = (id: string) => {
    return api.put(`/labels/${id}/cancel`, {});
}

export const postBulkOperations = (data: BulkOperationPayload) => {
    return api.post<BulkOperationResponse>('/bulk_operations', data).then(res => {
        return {
            ...res.data,
            ...data
        }
    });
}

export const getBulkOperation = (id: string) => {
    return api.get<GetBulkOperationResponse>(`/bulk_operations/${id}`).then(res => res.data);
}