const {
    REACT_APP_API_BASE_URL,
    REACT_APP_BOXC_CLIENT_ID,
    REACT_APP_BOXC_REDIRECT_URI
} = process.env;

const config = {
    backendAPIURL: REACT_APP_API_BASE_URL,
    boxcClientId: REACT_APP_BOXC_CLIENT_ID,
    boxcRedirectURI: REACT_APP_BOXC_REDIRECT_URI
}

export default config;