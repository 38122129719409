import { styled } from '@mui/material/styles';

export const ShipmentView = styled('div')`
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.primary.main};

  a { 
    text-decoration: none;
    color: inherit;
    margin-left: 4px;
  }
`;

export const ShipmentDataHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

export const NoShipmentIconContainer = styled('div')`
  display: flex;
  justify-content: center;
`;