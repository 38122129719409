import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import UIButton from 'components/UIButton';
import UICard from 'components/UICard';
import { Input, UISelect } from 'components/UIForm';
import { SettingsCard, SettingsDesc, SettingBottomCard } from './settings.styled';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { updateUserOrganization } from 'store/auth';
import { default as countriesList } from 'countries.json';

const brandingSchema = Yup.object().shape({
    name: Yup.string()
        .required('Company Name is required'),
    email: Yup.string()
        .required('Billing Email Address is required'),
    line_1: Yup.string()
        .required('Address Line 1 is required'),
    line_2: Yup.string(),
    postal_code: Yup.string()
        .required('Postal Code is required'),
    city: Yup.string()
        .required('City is required'),
    region: Yup.string()
        .required('State is required'),
    country: Yup.string()
        .required('Country is required'),
});

const Billing = () => {
    const organization = useAppSelector(state => state.auth.organization);
    const dispatch = useAppDispatch();
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: organization?.billing_address?.email || '',
            name: organization?.billing_address?.name || '',
            line_1: organization?.billing_address?.line_1 || '',
            line_2: organization?.billing_address?.line_2 || '',
            postal_code: organization?.billing_address?.postal_code || '',
            city: organization?.billing_address?.city || '',
            region: organization?.billing_address?.region || '',
            country: organization?.billing_address?.country || ''
        },
        validationSchema: brandingSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            await dispatch(updateUserOrganization({ billing_address: values }));
            setSubmitting(false);
        }
    });

    return (
        <UICard>
            <form onSubmit={formik.handleSubmit}>
                <SettingsCard>
                    <Typography variant="body1">Invoicing Details</Typography>
                    <SettingsDesc variant="body1">These will be the details shown on your invoices from Carrier Link.</SettingsDesc>

                    <SettingBottomCard>
                        <FormControl error={Boolean(formik.errors.name && formik.touched.name)}>
                            <Input
                                name="name"
                                id="name"
                                placeholder="Company Name"
                                aria-describedby="primary-error-text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.name && formik.touched.name)}
                            />
                            {(formik.errors.name && formik.touched.name) && <FormHelperText id="primary-error-text">{formik.errors.name}</FormHelperText>}
                        </FormControl>
                    </SettingBottomCard>

                    <Typography variant="body1" style={{ marginTop: 32 }}>Billing Address</Typography>

                    <SettingBottomCard direction="column" gap={10}>
                        <FormControl error={Boolean(formik.errors.line_1 && formik.touched.line_1)}>
                            <Input
                                name="line_1"
                                id="line_1"
                                placeholder="Address Line 1"
                                aria-describedby="primary-error-text"
                                value={formik.values.line_1}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.line_1 && formik.touched.line_1)}
                            />
                            {(formik.errors.line_1 && formik.touched.line_1) && <FormHelperText id="primary-error-text">{formik.errors.line_1}</FormHelperText>}
                        </FormControl>

                        <FormControl error={Boolean(formik.errors.line_2 && formik.touched.line_2)}>
                            <Input
                                name="line_2"
                                id="line_2"
                                placeholder="Address Line 2"
                                aria-describedby="primary-error-text"
                                value={formik.values.line_2}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.line_2 && formik.touched.line_2)}
                            />
                            {(formik.errors.line_2 && formik.touched.line_2) && <FormHelperText id="primary-error-text">{formik.errors.line_2}</FormHelperText>}
                        </FormControl>

                        <FormControl error={Boolean(formik.errors.postal_code && formik.touched.postal_code)}>
                            <Input
                                name="postal_code"
                                id="postal_code"
                                placeholder="Postal Code"
                                aria-describedby="primary-error-text"
                                value={formik.values.postal_code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.postal_code && formik.touched.postal_code)}
                            />
                            {(formik.errors.postal_code && formik.touched.postal_code) && <FormHelperText id="primary-error-text">{formik.errors.postal_code}</FormHelperText>}
                        </FormControl>

                        <FormControl error={Boolean(formik.errors.city && formik.touched.city)}>
                            <Input
                                name="city"
                                id="city"
                                placeholder="City"
                                aria-describedby="primary-error-text"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.city && formik.touched.city)}
                            />
                            {(formik.errors.city && formik.touched.city) && <FormHelperText id="primary-error-text">{formik.errors.city}</FormHelperText>}
                        </FormControl>

                        <FormControl error={Boolean(formik.errors.region && formik.touched.region)}>
                            <Input
                                name="region"
                                id="region"
                                placeholder="State"
                                aria-describedby="primary-error-text"
                                value={formik.values.region}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.region && formik.touched.region)}
                            />
                            {(formik.errors.region && formik.touched.region) && <FormHelperText id="primary-error-text">{formik.errors.region}</FormHelperText>}
                        </FormControl>

                        <FormControl error={Boolean(formik.errors.country && formik.touched.country)}>
                            <UISelect
                                labelId="country-label"
                                id="country"
                                name="country"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.country && formik.touched.country)}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>Select Country</MenuItem>
                                {countriesList.map(co => (
                                    <MenuItem key={co.code} value={co.code}>{co.name}</MenuItem>
                                ))}
                            </UISelect>
                            {(formik.errors.country && formik.touched.country) && <FormHelperText id="primary-error-text">{formik.errors.country}</FormHelperText>}
                        </FormControl>
                    </SettingBottomCard>
                </SettingsCard>

                <SettingsCard>
                    <Typography variant="body1">Billing Email Address</Typography>
                    <SettingsDesc variant="body1">This is the email address that your organization will receive invoices and billing updates at.</SettingsDesc>

                    <SettingBottomCard>
                        <FormControl error={Boolean(formik.errors.email && formik.touched.email)}>
                            <Input
                                name="email"
                                id="email"
                                placeholder="Email Address"
                                aria-describedby="primary-error-text"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.email && formik.touched.email)}
                            />
                            {(formik.errors.email && formik.touched.email) && <FormHelperText id="primary-error-text">{formik.errors.email}</FormHelperText>}
                        </FormControl>
                    </SettingBottomCard>
                </SettingsCard>

                <SettingsCard>
                    <UIButton type="submit" disabled={formik.isSubmitting || !formik.isValid}>Save Billing Information</UIButton>
                </SettingsCard>
            </form>
        </UICard>
    )
}

export default Billing;