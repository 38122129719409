import { styled } from '@mui/material/styles';

export const DetailsContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  & > div {
      width: 100%;
  }
`;

export const DetailsForm = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  & > div {
      width: 100%;
  }

  /* & > div:not(:last-child) {
      margin-bottom: 16px;
  } */
`;