import { AxiosError } from 'axios';
// import { withAsync } from 'utils';
import api from '../index';
import { CreateManifestPayload, CreateManifestResponse, CreateOverLabelPayload, CreateOverLabelResponse, CreateOverPackPayload, CreateOverPackResponse, GetOverLabelResponse, GetOverPackResponse, UpateOverPackDimentionsPayload, UpateOverPackPayload } from './types';

export type ApiError = AxiosError<CreateOverPackResponse>;

export const createOverpack = async (data: CreateOverPackPayload, thunkApi: any) => {
    return api.post<CreateOverPackResponse>('/overpacks', {
        ...data,
        shipments: data.shipments.map(shp => {
            return {
                tracking_number: shp.tracking_number
            }
        })
    }).then(res => {
        return {
            overpack: res.data,
            shipments: data.shipments
        }
    }).catch(err => {
        throw thunkApi.rejectWithValue((err as ApiError).response?.data)
    })
}

export const updateOverpack = (data: UpateOverPackPayload, thunkApi: any) => {
    return api.put<CreateOverPackResponse>('/overpacks/' + data.id, {
        ...data.payload,
        shipments: data.payload.shipments.map(shp => {
            return {
                tracking_number: shp.tracking_number
            }
        })

    }).then(res => {
        return {
            overpack: res.data,
            shipments: data.payload.shipments
        }
    }).catch(err => {
        throw thunkApi.rejectWithValue((err as ApiError).response?.data)
    })
}

export const updateOverpackDimentions = (data: UpateOverPackDimentionsPayload, thunkApi: any) => {
    return api.put<CreateOverPackResponse>('/overpacks/' + data.id, data.payload.dimensions).then(res => res.data).catch(err => {
        throw thunkApi.rejectWithValue((err as ApiError).response?.data)
    })
}

export const getOverpackFromId = (overpackId: string) => {
    return api.get<GetOverPackResponse>('/overpacks/' + overpackId).then(res => res.data);
}

export const getOverpackFromProviderId = (providerOverpackId: string, thunkApi: any) => {
    return api.get<GetOverPackResponse>('/overpacks/' + providerOverpackId).then(res => res.data).catch(err => {
        throw thunkApi.rejectWithValue((err as ApiError).response?.data)
    });
}

export const createManifest = (data: CreateManifestPayload, thunkApi: any) => {
    return api.post<CreateManifestResponse>('/manifests', data).then(res => res.data).catch(err => {
        throw thunkApi.rejectWithValue((err as ApiError).response?.data)
    });
}

export const getAllManifest = () => {
    return api.get<CreateManifestResponse[]>('/manifests').then(res => res.data);
}

export const getManifestDetailsFile = (id: string) => {
    return api.get<{ id: string, uploaded_file_url: string }>(`/manifests/${id}/download`);
}

export const createOverLabel = (data: CreateOverLabelPayload) => {
    return api.post<CreateOverLabelResponse>("overlabels", data).then(res => res.data)
}

export const getOverLabel = (id: string) => {
    return api.get<GetOverLabelResponse>(`/overlabels/${id}`).then(res => res.data);
}

export const printOverLabel = (id: string) => {
    return api.get<{ file_url: string }>(`/overlabels/${id}/print`).then(res => res.data);
}