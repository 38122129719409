import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';

export const SettingsCard = styled('div')`
  padding: 40px;
  
  &:not(:last-child) {
    border-bottom: 1px solid #E7E7E7;
  }
`;

export const TeamHeader = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const InviteHeading = styled(Typography)`
    font-size: 28px;
`;

export const InviteActions = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
`;

export const InviteModalHeading = styled(Typography)`
  font-weight: 700;
  margin-top: 26px;
`;

export const InviteLinkContainer = styled('div')`
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 40px;

    & > div {
        margin-right: 16px;
    }
`;

export const InviteLinkIcon = styled('div')`
    padding: 12px;
    border-radius: 50%;
    background-color: #E7E7E7;

    & > div {
        background-color: #000;
    }
`;

export const InviteLinkInfo = styled('div')`
    p {
        font-size: 12px;
        color: #808080;

        &:nth-of-type(1) {
            color: #000;
            font-weight: 700;
        }
    }

`;

export const CustomerTable = styled(Table)``;

export const CustomerTableRow = styled(TableRow)`
    border: 2px solid #E7E7E7;
    
    & td, th {
        font-weight: 500;
        font-family: 'Cabin';
    }

    &:nth-of-type(even) {
        background-color:#F9F9F9
    }

    &:last-child td, 
    &:last-child th {
        border: 0,
    }
`;

export const CustomerTableActions = styled('div')`
    display: flex;
    gap: 10px;
    margin-top: 16px;
    justify-content: flex-end;

    button {
        background: none;
        border: none;
        cursor: pointer;
        font-weight: 700;

        &:disabled {
            cursor: not-allowed
        }
    }
`;

export const CustomerDisableContainer = styled('div')`
    display: flex;
    align-items: center;

    & > p {
        margin-left: 4px;
    }
`;