import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Modal, Typography } from "@mui/material";
import { ModalBody } from "components/UIModal";
import Icon from 'components/Icon';
import { useAppSelector } from 'store/hooks';

const DomainModalHeading = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
`;

const DomainDetailModalHeading = styled(Typography)`
  font-weight: 700;
  margin-top: 26px;
`;

const DomainDetailInfo = styled(Typography)`
  font-weight: 500;

  & > a {
    color: #000;
  }
`;

const RecordCard = styled('div')`
    position: relative;
    padding: 16px;
    background: #1D1C27;
    color: #fff;
    border-radius: 6px;
    margin-top: 16px;
    
    p {
        font-weight: 500;
        font-size: 16px;

        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }
`;

const GuideRecordCard = styled(RecordCard)`
    height: 120px;
    overflow-y: scroll;

    /* &:hover,
    &:active {
        height: 100%;
    } */
`;

const CopyEl = styled('button')`
    position: absolute;
    right: 16px;
    background-color: ${props => props.theme.palette.primary.main};
    padding: 6px 8px;
    color: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;

    & > p {
        font-weight: 500;
        font-size: 14px;
        margin-left: 4px;
    }
`;

const DNSConfigModal: React.FC<{ open: boolean, handleClose: () => void }> = ({ open, handleClose }) => {
    const textAreaRef = React.useRef<HTMLInputElement>(null);
    const { organization } = useAppSelector(state => state.auth);

    function copyToClipboard() {
        navigator.clipboard.writeText(`
            The administrator has configured Carrier Link at ship.expeditors.com, and the API alias will be configured at api.ship.expeditors.com.

            To set up DNS for Carrier Link, please ensure the DNS for the above domains match the below:
            
            1. CNAME record for the primary domain
            CNAME ${organization?.domain}
            
            2. CNAME record for the API domain
            CNAME ${organization?.api_domain}
            
            When these updates are complete, please refresh the DNS records in your Carrier Link dashboard. Please be aware, it may take between 24-72 hours for these records to propagate. If it has already been longer than this, please check our documentation at docs.carrierlink.io for more information.
        `);
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{ overflow: 'auto' }}
            aria-labelledby="domain-conf-modal-title"
            aria-describedby="domain-conf-modal-description"
            keepMounted
        >
            <ModalBody>
                <DomainModalHeading variant="h2">DNS Configuration</DomainModalHeading>

                <DomainDetailModalHeading variant="body1">Point CNAME record to custom.carrierlink.io</DomainDetailModalHeading>
                <DomainDetailInfo variant="body1">Log in to the account you have with your DNS provider, and add a CNAME record pointing to custom.carrierlink.io</DomainDetailInfo>

                <RecordCard style={{ marginBottom: 16 }}>
                    <Typography variant="body1" style={{ wordSpacing: 5 }}>CNAME custom.carrierlink.io</Typography>
                </RecordCard>

                <RecordCard style={{ marginBottom: 16 }}>
                    <Typography variant="body1" style={{ wordSpacing: 5 }}>CNAME api.custom.carrierlink.io</Typography>
                </RecordCard>

                <DomainDetailInfo variant="body1">If you’ve already done this, allow up to 24 hours for the changes to propagate, or check our <a href="https://carrierlink.notion.site/DNS-Configuration-35f70cd2572d4f078d8ed68249b5014e">documentation</a> for more information.</DomainDetailInfo>
                <DomainDetailModalHeading variant="body1">Share details with a developer</DomainDetailModalHeading>

                <GuideRecordCard>
                    <CopyEl onClick={copyToClipboard}>
                        <Icon name="copy" size={14} />
                        <Typography variant="body1">Copy</Typography>
                    </CopyEl>

                    <div ref={textAreaRef}>
                        <Typography variant="body1">
                            The administrator has configured Carrier Link at {organization?.domain}, and the API alias will be configured at {organization?.api_domain}.
                        </Typography>

                        <Typography variant="body1">
                            To set up DNS for Carrier Link, please ensure the DNS for the above domains match the below:
                        </Typography>

                        <Typography variant="body1">
                            1. CNAME record for the primary domain
                            CNAME custom.carrierlink.io
                        </Typography>

                        <Typography variant="body1">
                            2. CNAME record for the API domain
                            CNAME api.custom.carrierlink.io
                        </Typography>

                        <Typography variant="body1">
                            When these updates are complete, please refresh the DNS records in your Carrier Link dashboard. Please be aware, it may take between 24-72 hours for these records to propagate. If it has already been longer than this, please check our documentation at docs.carrierlink.io for more information.
                        </Typography>
                    </div>
                </GuideRecordCard>
            </ModalBody>
        </Modal>
    )
}


export default DNSConfigModal;