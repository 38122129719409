import { styled } from '@mui/material/styles';

export const DataFilterContainer = styled('div')`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
`;

export const FilterInfo = styled('div')`
    display: flex;
    align-items: center;
`;

export const FilterAction = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    margin-left: 40px;
`;

export const FilterButton = styled('button')`
    all: unset;
    cursor: pointer;
`;