import { styled } from '@mui/material/styles';

export const SignUpInfo = styled('div')`
    border-top: 1px solid #E7E7E7;
    padding-top: 24px;
    margin-top: 24px;

    h6 {
        /* font-size: 20px; */
        color: ${props => props.theme.palette.primary.main};
        font-family: 'Cabin';
        margin-bottom: 16px;
    }
`;