import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UICard from 'components/UICard';
import Button from 'components/UIButton';
import {
    AuthHeading,
    AuthInfoHeading,
    AuthFormContainer,
    AuthForm,
    AuthActions,
    AuthLink
} from 'pages/SignIn/sigin.styled';
import { FormControl, FormHelperText } from '@mui/material';
import { Input } from 'components/UIForm';

const passwordSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

interface SerPasswordProps {
    handleSignUp: (data: any) => any;
}

const SetPassword: React.FC<SerPasswordProps> = ({ handleSignUp }) => {
    const formik = useFormik({
        initialValues: { password: '', confirmPassword: '' },
        validationSchema: passwordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            await handleSignUp(values);
            setSubmitting(false)
        }
    });

    return (
        <UICard padding="p4" style={{ display: 'flex', flexDirection: 'column', gap: 40, textAlign: 'center' }}>
            <div>
                <AuthHeading variant="h5">Set Password</AuthHeading>
                <AuthInfoHeading variant="h6">Make sure it’s secure</AuthInfoHeading>
            </div>

            <AuthFormContainer>
                <AuthForm id="set-password-form" onSubmit={formik.handleSubmit}>
                    <FormControl error={Boolean(formik.errors.password && formik.touched.password)}>
                        <Input
                            name="password"
                            id="password"
                            type="password"
                            placeholder="Password"
                            aria-describedby="password-error-text"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.errors.password && formik.touched.password)}
                        />
                        {(formik.errors.password && formik.touched.password) && <FormHelperText id="password-error-text">{formik.errors.password}</FormHelperText>}
                    </FormControl>

                    <FormControl error={Boolean(formik.errors.confirmPassword && formik.touched.confirmPassword)}>
                        <Input
                            name="confirmPassword"
                            id="confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                            aria-describedby="confirmPassword-error-text"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.errors.confirmPassword && formik.touched.confirmPassword)}
                        />
                        {(formik.errors.confirmPassword && formik.touched.confirmPassword) && <FormHelperText id="confirmPassword-error-text">{formik.errors.confirmPassword}</FormHelperText>}
                    </FormControl>
                </AuthForm>
            </AuthFormContainer>

            <AuthActions>
                <AuthLink to="/auth/signin"></AuthLink>
                <Button type="submit" variant="contained" color="primary" form="set-password-form" disabled={formik.isSubmitting || !formik.isValid}>Sign Up</Button>
            </AuthActions>
        </UICard>
    )
}

export default SetPassword;