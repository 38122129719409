import * as React from 'react';
import styled from '@emotion/styled';
import Button, { ButtonProps } from '@mui/material/Button';

const ButtonStyled = styled(Button)`
    border-radius: 8px;
    text-transform: capitalize;
`;

interface Props extends ButtonProps {
    children?: React.ReactNode
}

const UIButton: React.FC<Props> = ({ children, ...props }) => (
    <ButtonStyled variant="contained" color="black" {...props}>{children}</ButtonStyled>
);

export default UIButton;