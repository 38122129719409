import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const BulkModalHeading = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
`;

export const BulkUploadContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  /* margin-bottom: 24px; */
  padding: 32px;
  background-color: #E7E7E7;
  border: 2px dashed #000;
`;

export const BulkModalInfo = styled(Typography)`
  font-family: 'Inter';
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
`;

export const BulkModalDiv = styled('div')`
  position: relative;
  color: #808080;
  margin-bottom: 16px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 72px;
    border: 1px solid #808080;
  }

  &::before {
    left: 32px;
    top: 50%;
  }

  &::after {
    right: 32px;
    top: 50%;
  }
`;

export const BulkModalActions = styled('div')`
  margin-top: 32px;
  display: flex;
  gap: 10px;
`;

export const ModalGuideContainer = styled('div')`
  margin-top: 42px;

  & > p:nth-of-type(1) {
    font-weight: 700;
    margin-bottom: 8px;
  }
`;

export const BulkModalGuide = styled(Typography)`
  font-weight: 500;
  margin-bottom: 16px;

  & > a {
    color: #000;
  }
`;

export const UploadingInd = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 18px;

  & > p {
    margin-left: 15px;
  }
`;

export const UploadingSuccess = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 18px;

  & > p {
    margin-left: 15px;
  }
`;

export const ConfirmModalHeading = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
`;

export const ConfirmModalContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;
  margin-bottom: 24px;

  b {
    color: ${props => props.theme.palette.primary.main};
  }
`;