import * as React from "react";
import { useParams } from "react-router-dom";
import { getShipmentById } from "store/customers";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ShipmentDetail from "./ShipmentDetail";
import { ShipmentHeading, ShipmentInfo, ShipmentMode } from './shipment-view.styled';
import Labels from "./Labels";
import LineItems from "./LineItems";
import HeadingCrumb from "components/HeadingCrumb";

const ShipmentView = () => {
    const { shipmentId } = useParams();
    const dispatch = useAppDispatch();
    const { currentShipment } = useAppSelector((state) => state.customers)

    React.useEffect(() => {
        if (shipmentId) {
            dispatch(getShipmentById(shipmentId));
        }
    }, [dispatch, shipmentId]);

    return (
        <div>
            {/* <h1>ShipmentView {shipmentId}</h1> */}
            <HeadingCrumb limit={2} lastElement="View Shipment" />
            <ShipmentInfo>
                <ShipmentHeading variant="h6">Shipment Details</ShipmentHeading>
                <ShipmentMode testMode={Boolean(currentShipment?.test)}>Test Mode</ShipmentMode>
            </ShipmentInfo>
             
            <ShipmentDetail />

            <Labels />
            <LineItems />
        </div>
    )
}

export default ShipmentView;