import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
    getAllCustomers,
    updateCustomerActivationStatus,
    getCustomerShipments,
    bulkUploadShipment,
    getCustomerBulkShipments,
    getCustomer,
    getShipment,
    postBulkOperations,
    getBulkOperation,
    updateCustomer
} from 'api/customers';
import { CustomersState } from "./types";
import { getSearchParamFromURL } from 'utils';

export const getCustomersList = createAsyncThunk('customers/getAllCustomers', getAllCustomers);
export const setCustomerActivation = createAsyncThunk('customers/updateCustomerActivationStatus', updateCustomerActivationStatus);
export const getCustomerShipmentsById = createAsyncThunk('customers/getCustomerShipments', getCustomerShipments);
export const createbulkUploadShipment = createAsyncThunk('customers/bulkUploadShipment', bulkUploadShipment);
export const getCustomerBulkShipmentsById = createAsyncThunk('customers/getCustomerBulkShipments', getCustomerBulkShipments);
export const getCustomerById = createAsyncThunk('customers/getCustomer', getCustomer);
export const updateCustomerById = createAsyncThunk('customers/updateCustomer', updateCustomer);
export const updateCustomerLimitById = createAsyncThunk('customers/updateCustomerLimit', updateCustomer);
export const getShipmentById = createAsyncThunk('customers/getShipment', getShipment);
export const doBulkOperations = createAsyncThunk('customers/postBulkOperations', postBulkOperations);
export const getBulkOperationById = createAsyncThunk('customers/getBulkOperation', getBulkOperation);
export const pollBulkOperation = createAsyncThunk('customers/pollGetBulkOperation', getBulkOperation);

const initialState: CustomersState = {
    customersList: [],
    customersListNext: null,
    customersListPrevious: null,
    shipmentsList: [],
    shipmentsListNext: null,
    shipmentsListPrevious: null,
    bulkShipmentsList: [],
    bulkShipmentsListNext: null,
    bulkShipmentsListPrevious: null,
    currentCustomer: null,
    currentShipment: null,
    bulkOperations: [],
    getCustomersListStatus: 'IDLE',
    setCustomerActivationStatus: 'IDLE',
    getCustomerShipmentsByIdStatus: 'IDLE',
    createbulkUploadShipmentStatus: 'IDLE',
    getCustomerBulkShipmentsByIdStatus: 'IDLE',
    getCustomerByIdStatus: 'IDLE',
    getShipmentByIdStatus: 'IDLE',
    bulkOperationsStatus: 'IDLE',
    updateCustomerByIdStatus: 'IDLE',
    updateCustomerLimitByIdStatus: 'IDLE'
}

export const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        resetBulkShipmentStatus: (state) => {
            state.createbulkUploadShipmentStatus = 'IDLE';
        },
        removeShipmentById: (state, data) => {
            state.shipmentsList = state.shipmentsList.filter(ship => ship.id !== data.payload)
        },
        updateBulkIntervalId: (state, data) => {
            state.bulkOperations = state.bulkOperations.map(op => {
                if (op.id === data.payload.id) {
                    op.intervalId = data.payload.intervalId;
                }

                return op;
            })
        }
    },
    extraReducers(builder) {
        builder.addCase(getCustomersList.pending, (state, action) => {
            state.getCustomersListStatus = 'PENDING';
        });
        builder.addCase(getCustomersList.fulfilled, (state, action) => {
            state.customersList = action.payload.results;
            state.customersListNext = getSearchParamFromURL(action.payload.next, 'cursor');
            state.customersListPrevious = getSearchParamFromURL(action.payload.previous, 'cursor');
            state.getCustomersListStatus = 'SUCCESS';
        });
        builder.addCase(getCustomersList.rejected, (state, action) => {
            state.getCustomersListStatus = 'ERROR';
        });
        builder.addCase(getCustomerShipmentsById.pending, (state, action) => {
            state.getCustomerShipmentsByIdStatus = 'PENDING';
        });
        builder.addCase(getCustomerShipmentsById.fulfilled, (state, action) => {
            state.shipmentsList = action.payload.results;
            state.shipmentsListNext = getSearchParamFromURL(action.payload.next, 'cursor');
            state.shipmentsListPrevious = getSearchParamFromURL(action.payload.previous, 'cursor');
            state.getCustomerShipmentsByIdStatus = 'SUCCESS';
        });
        builder.addCase(getCustomerShipmentsById.rejected, (state, action) => {
            state.getCustomerShipmentsByIdStatus = 'ERROR';
        });
        builder.addCase(getCustomerBulkShipmentsById.pending, (state, action) => {
            state.getCustomerBulkShipmentsByIdStatus = 'PENDING';
        });
        builder.addCase(getCustomerBulkShipmentsById.fulfilled, (state, action) => {
            state.bulkShipmentsList = action.payload.results;
            state.bulkShipmentsListNext = getSearchParamFromURL(action.payload.next, 'cursor');
            state.bulkShipmentsListPrevious = getSearchParamFromURL(action.payload.previous, 'cursor');
            state.getCustomerBulkShipmentsByIdStatus = 'SUCCESS';
        });
        builder.addCase(getCustomerBulkShipmentsById.rejected, (state, action) => {
            state.getCustomerBulkShipmentsByIdStatus = 'ERROR';
        });
        builder.addCase(getCustomerById.pending, (state, action) => {
            state.getCustomerByIdStatus = 'PENDING';
        });
        builder.addCase(getCustomerById.fulfilled, (state, action) => {
            state.currentCustomer = action.payload;
            state.getCustomerByIdStatus = 'SUCCESS';
        });
        builder.addCase(getCustomerById.rejected, (state, action) => {
            state.getCustomerByIdStatus = 'ERROR';
        });
        builder.addCase(updateCustomerById.pending, (state, action) => {
            state.updateCustomerByIdStatus = 'PENDING';
        });
        builder.addCase(updateCustomerById.fulfilled, (state, action) => {
            state.currentCustomer = action.payload;
            state.updateCustomerByIdStatus = 'SUCCESS';
        });
        builder.addCase(updateCustomerById.rejected, (state, action) => {
            state.updateCustomerByIdStatus = 'ERROR';
        });
        builder.addCase(updateCustomerLimitById.pending, (state, action) => {
            state.updateCustomerLimitByIdStatus = 'PENDING';
        });
        builder.addCase(updateCustomerLimitById.fulfilled, (state, action) => {
            state.currentCustomer = { ...state.currentCustomer, ...action.payload };
            state.updateCustomerLimitByIdStatus = 'SUCCESS';
        });
        builder.addCase(updateCustomerLimitById.rejected, (state, action) => {
            state.updateCustomerLimitByIdStatus = 'ERROR';
        });
        builder.addCase(getShipmentById.pending, (state, action) => {
            state.getShipmentByIdStatus = 'PENDING';
        });
        builder.addCase(getShipmentById.fulfilled, (state, action) => {
            state.currentShipment = action.payload;
            state.getShipmentByIdStatus = 'SUCCESS';
        });
        builder.addCase(getShipmentById.rejected, (state, action) => {
            state.getShipmentByIdStatus = 'ERROR';
        });
        builder.addCase(createbulkUploadShipment.pending, (state, action) => {
            state.createbulkUploadShipmentStatus = 'PENDING';
        });
        builder.addCase(createbulkUploadShipment.fulfilled, (state, action) => {
            if (action.payload) {
                state.bulkShipmentsList.push(action.payload)
            };
            state.createbulkUploadShipmentStatus = 'SUCCESS';
        });
        builder.addCase(createbulkUploadShipment.rejected, (state, action) => {
            state.createbulkUploadShipmentStatus = 'ERROR';
        });
        builder.addCase(setCustomerActivation.pending, (state, action) => {
            state.setCustomerActivationStatus = 'PENDING';
        });
        builder.addCase(setCustomerActivation.fulfilled, (state, action) => {
            state.customersList = state.customersList.map((customer) => {
                if (customer.id === action.payload.id) {
                    customer.activation_status = action.payload.activation_status
                }

                return customer;
            });
            state.setCustomerActivationStatus = 'SUCCESS';
        });
        builder.addCase(setCustomerActivation.rejected, (state, action) => {
            state.setCustomerActivationStatus = 'ERROR';
        });
        builder.addCase(doBulkOperations.pending, (state, action) => {
            state.bulkOperationsStatus = 'PENDING';
        });
        builder.addCase(doBulkOperations.fulfilled, (state, action) => {
            state.bulkOperations.push({
                ...action.payload,
                operation_file: null,
                labels_file: null,
                success_count: 0,
                failure_count: 0,
                intervalId: null
            });

            // 
            state.bulkOperationsStatus = 'SUCCESS';
        });
        builder.addCase(doBulkOperations.rejected, (state, action) => {
            state.bulkOperationsStatus = 'ERROR';
        });
        builder.addCase(pollBulkOperation.pending, (state, action) => {
            // state.bulkOperationsStatus = 'PENDING';
        });
        builder.addCase(pollBulkOperation.fulfilled, (state, action) => {
            // state.bulkOperationsStatus = 'SUCCESS';
            if (action.payload.status === 'failure' || action.payload.status === 'success') {
                state.bulkOperations = state.bulkOperations.map(op => {
                    if (op.id === action.payload.id) {
                        if (op.intervalId) {
                            clearInterval(op.intervalId)
                        }

                        if (op.operation_type === 'cancel_labels' && action.payload.status === 'success') {
                            window.open(action.payload.operation_file, '_blank')

                            if (op.single_shipment_ids && op.single_shipment_ids.length > 0) {
                                state.shipmentsList = state.shipmentsList.filter(shp => !op.single_shipment_ids?.includes(shp.id));
                            }

                            if (op.bulk_shipment_metadata_ids && op.bulk_shipment_metadata_ids.length > 0) {
                                state.bulkShipmentsList = state.bulkShipmentsList.filter(shp => !op.bulk_shipment_metadata_ids?.includes(shp.id));
                            }
                        } else if (op.operation_type === 'download_labels' && action.payload.status === 'success') {
                            window.open(action.payload.labels_file, '_blank')
                        } else if (action.payload.status === 'success') {
                            window.open(action.payload.operation_file, '_blank')
                        }
                    }

                    return op;
                }).filter(op => op.id !== action.payload.id);
            }
        });
        builder.addCase(pollBulkOperation.rejected, (state, action) => {
            // state.bulkOperationsStatus = 'ERROR';
        });
    }
});

export const { resetBulkShipmentStatus, removeShipmentById, updateBulkIntervalId } = customersSlice.actions;

export default customersSlice.reducer;