import UICard from "components/UICard"
import Grid from '@mui/material/Grid';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useAppSelector } from "store/hooks";
import Icon from "components/Icon";
import { ShipmentDetailsTable, AccordionTable, ShipmentAccordion, ClaimButton, ClaimTableCell } from './shipment-view.styled';

const ShipmentDetail = () => {
    const { currentShipment } = useAppSelector((state) => state.customers)

    return (
        <UICard padding="p4">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="body1">Details</Typography>
                    <TableContainer>
                        <ShipmentDetailsTable aria-label="simple table" border={0}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="right">Entry Point</TableCell>
                                    <TableCell>{currentShipment?.entry_point}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Created</TableCell>
                                    <TableCell>{currentShipment?.created}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Tax</TableCell>
                                    <TableCell>${currentShipment?.tax}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Duty</TableCell>
                                    <TableCell>${currentShipment?.duty}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Service</TableCell>
                                    <TableCell>{currentShipment?.service}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Shipping Terms</TableCell>
                                    <TableCell>{currentShipment?.terms}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Weight</TableCell>
                                    <TableCell>{currentShipment?.weight} kg</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Volumetric Weight</TableCell>
                                    <TableCell>{currentShipment?.volumetric_weight} kg</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Signature Confirmation</TableCell>
                                    <TableCell>{currentShipment?.signature_confirmation}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Insurance</TableCell>
                                    <TableCell>{currentShipment?.insurance}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Insured Value</TableCell>
                                    <ClaimTableCell>
                                        <div>
                                            ${currentShipment?.insured_value}
                                        </div>
                                        {currentShipment?.claim_url ? (
                                            <ClaimButton variant="outlined" onClick={() => window.open(currentShipment?.claim_url, '_blank')}>
                                                <Icon name="link-out" size={16} />
                                                <Typography variant="body1">Insurance Claim</Typography>
                                            </ClaimButton>
                                        ) : null}
                                    </ClaimTableCell>
                                </TableRow>
                                {currentShipment?.comments.map((comment, i) => (
                                    <TableRow key={comment + i}>
                                        <TableCell align="right">Comment {i + 1}</TableCell>
                                        <TableCell>{comment}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </ShipmentDetailsTable>
                    </TableContainer>
                </Grid>
                <Grid item xs={6}>
                    <ShipmentAccordion>
                        <AccordionSummary
                            expandIcon={<Icon name="arrow-fill-down" size={12} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Delivery Address</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <AccordionTable aria-label="simple table" border={0}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right">Company Name</TableCell>
                                            <TableCell>{currentShipment?.to_address.company_name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Name</TableCell>
                                            <TableCell>{currentShipment?.to_address.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Phone</TableCell>
                                            <TableCell>{currentShipment?.to_address.phone}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Email</TableCell>
                                            <TableCell>{currentShipment?.to_address.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Street 1</TableCell>
                                            <TableCell>{currentShipment?.to_address.line_1}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Street 2</TableCell>
                                            <TableCell>{currentShipment?.to_address.line_2}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">City</TableCell>
                                            <TableCell>{currentShipment?.to_address.city}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">State / Province</TableCell>
                                            <TableCell>{currentShipment?.to_address.region}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">ZIP / Postal Code</TableCell>
                                            <TableCell>{currentShipment?.to_address.postal_code}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Country</TableCell>
                                            <TableCell>{currentShipment?.to_address.country}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </AccordionTable>
                            </TableContainer>
                        </AccordionDetails>
                    </ShipmentAccordion>

                    <ShipmentAccordion>
                        <AccordionSummary
                            expandIcon={<Icon name="arrow-fill-down" size={12} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Return Address</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <AccordionTable aria-label="simple table" border={0}>
                                    <TableBody>
                                        {/* <TableRow>
                                            <TableCell align="right">Company Name</TableCell>
                                            <TableCell>{currentShipment?.from_address.name}</TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            <TableCell align="right">Name</TableCell>
                                            <TableCell>{currentShipment?.from_address?.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Phone</TableCell>
                                            <TableCell>{currentShipment?.from_address?.phone}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Email</TableCell>
                                            <TableCell>{currentShipment?.from_address?.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Street 1</TableCell>
                                            <TableCell>{currentShipment?.from_address?.street1}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Street 2</TableCell>
                                            <TableCell>{currentShipment?.from_address?.street2}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">City</TableCell>
                                            <TableCell>{currentShipment?.from_address?.city}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">State / Province</TableCell>
                                            <TableCell>{currentShipment?.from_address?.region}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">ZIP / Postal Code</TableCell>
                                            <TableCell>{currentShipment?.from_address?.postal_code}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Country</TableCell>
                                            <TableCell>{currentShipment?.from_address?.country}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </AccordionTable>
                            </TableContainer>
                        </AccordionDetails>
                    </ShipmentAccordion>

                    <ShipmentAccordion>
                        <AccordionSummary
                            expandIcon={<Icon name="arrow-fill-down" size={12} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Consignee</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <AccordionTable aria-label="simple table" border={0}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right">Name</TableCell>
                                            <TableCell>{currentShipment?.consignee.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Phone</TableCell>
                                            <TableCell>{currentShipment?.consignee.phone}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Email</TableCell>
                                            <TableCell>{currentShipment?.consignee.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Passport / Tax ID</TableCell>
                                            <TableCell>{currentShipment?.consignee.tax_id}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Street 1</TableCell>
                                            <TableCell>{currentShipment?.consignee.street1}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Street 2</TableCell>
                                            <TableCell>{currentShipment?.consignee.street2}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">City</TableCell>
                                            <TableCell>{currentShipment?.consignee.city}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">State / Province</TableCell>
                                            <TableCell>{currentShipment?.consignee.region}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">ZIP / Postal Code</TableCell>
                                            <TableCell>{currentShipment?.consignee.postal_code}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Country</TableCell>
                                            <TableCell>{currentShipment?.consignee.country}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </AccordionTable>
                            </TableContainer>
                        </AccordionDetails>
                    </ShipmentAccordion>

                    <ShipmentAccordion>
                        <AccordionSummary
                            expandIcon={<Icon name="arrow-fill-down" size={12} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Consignor</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <AccordionTable aria-label="simple table" border={0}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right">Name</TableCell>
                                            <TableCell>{currentShipment?.consignor.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Phone</TableCell>
                                            <TableCell>{currentShipment?.consignor.phone}</TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell align="right">Email</TableCell>
                                            <TableCell>{currentShipment?.consignor.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Passport / Tax ID</TableCell>
                                            <TableCell>{currentShipment?.consignor.tax_id}</TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            <TableCell align="right">Street 1</TableCell>
                                            <TableCell>{currentShipment?.consignor.street1}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Street 2</TableCell>
                                            <TableCell>{currentShipment?.consignor.street2}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">City</TableCell>
                                            <TableCell>{currentShipment?.consignor.city}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">State / Province</TableCell>
                                            <TableCell>{currentShipment?.consignor.region}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">ZIP / Postal Code</TableCell>
                                            <TableCell>{currentShipment?.consignor.postal_code}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Country</TableCell>
                                            <TableCell>{currentShipment?.consignor.country}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </AccordionTable>
                            </TableContainer>
                        </AccordionDetails>
                    </ShipmentAccordion>
                </Grid>
            </Grid>
        </UICard>
    )
}

export default ShipmentDetail;