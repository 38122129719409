import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#740B1D',
    },
    secondary: {
      main: '#F8D8DD'
    },
    success: {
      main: '#2E8049'
    },
    black: {
      main: '#141414',
      contrastText: '#fff'
    },
    greyOne: {
      main: '#808080'
    },
    disabled: {
      main: '#00000042'
    },
    stroke: {
      main: '#e4e6f2'
    },
    delete: {
      main: '#FF453A',
    },
    // error: {
    //   main: '#ff0000',
    // },
  },
  typography: {
    allVariants: {
      fontFamily: "'Inter', 'Cabin'",
      fontWeight: 600
    },
    body1: {
      fontFamily: 'Cabin',
    },
    h5: {
      color: '#808080'
    },
  },
});

export default theme;