import * as React from 'react';
import { PageHeading } from "components/Heading"
import { BackLink, InputContainer, OverPackDescription, OverPackInfo, OverPackListContainer, TrackingItem } from "pages/OverPacking/overpacking.styled"
import Icon from 'components/Icon';
import { FormControl, Grid, Typography } from '@mui/material';
import { Input } from 'components/UIForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createNewOverLabel, resetOverLabelInfo, resetOverLabelSession, useGetOverLabelQuery } from 'store/wms';

function extractShipmentId(label: string) {
    // Use a regular expression to match any prefix followed by digits and capture the digits part
    const match = label.match(/[^0-9]*(\d+)$/);

    if (match) {
        // Return the captured group, which is the shipment_id
        return match[1];
    } else {
        // If the string doesn't match the expected format, return null or an appropriate message
        return null;
    }
}

const OverLabel = () => {
    const [id, setId] = React.useState('');
    const [trackingNumber, setTrackingNumber] = React.useState('');
    const trackingRef = React.useRef<HTMLInputElement>(null);
    const overLabelListRef = React.useRef<HTMLDivElement>(null);
    const {
        currentOverLabelSession,
        createNewOverLabelStatus
    } = useAppSelector(state => state.wms);
    const dispatch = useAppDispatch();
    const { data, isLoading, refetch } = useGetOverLabelQuery(id, { pollingInterval: 2000, skip: !!!id });

    const handleTrackingNumberSubmit = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            const shipmentId = extractShipmentId(trackingNumber);

            if (shipmentId) {
                dispatch(createNewOverLabel({ shipment_id: shipmentId })).unwrap().then((res) => setId(res.id))
            }
        }
    }

    React.useEffect(() => {
        if (data && data.status !== "PROCESSING") {
            setId('');
            
            setTrackingNumber('');
            trackingRef.current?.focus();

            if (overLabelListRef.current) {
                overLabelListRef.current.scrollTop = -overLabelListRef.current.scrollHeight
            }

            let intervalId = setInterval(() => {
                dispatch(resetOverLabelInfo(intervalId));
            }, 3000)
        }
    }, [data, dispatch])

    React.useEffect(() => {
        if (id) {
            refetch()
        }
    }, [id, refetch])

    React.useEffect(() => {
        dispatch(resetOverLabelSession());
        trackingRef.current?.focus();
    }, [dispatch]);

    return (
        <>
            <BackLink to="/">
                <Icon name="back-arrow" size={12} />
                <Typography>Back to Dashboard</Typography>
            </BackLink>

            <PageHeading variant="h4">Overlabel</PageHeading>

            <Grid container columnSpacing={10} style={{ marginTop: 64 }}>
                <Grid item xs={6}>
                    <InputContainer>
                        <FormControl>
                            <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />

                            <Input
                                name="tracking_number"
                                id="tracking_number"
                                placeholder="Barcode / Tracking Number"
                                aria-describedby="tracking_number-error-text"
                                value={trackingNumber}
                                onChange={e => setTrackingNumber(e.target.value.trim())}
                                onKeyDown={handleTrackingNumberSubmit}
                                disabled={createNewOverLabelStatus === 'PENDING' || isLoading}
                                inputRef={trackingRef}
                                autoComplete="off"
                            />
                        </FormControl>
                    </InputContainer>

                    <OverPackDescription>
                        <Typography variant="body1">
                            Overlabel an existing Tracking Number
                        </Typography>
                        <Typography variant="body1" >
                            Scan a shipment’s tracking number to cancel the label and replace it with a valid label. The shipment will be assigned to the same consignee and consignor and be ready for shipment.
                        </Typography>
                    </OverPackDescription>
                </Grid>
                <Grid item xs={6}>
                    <OverPackInfo status={currentOverLabelSession.currentShipmentInfo.type}>
                        <Typography variant="body1">
                            {currentOverLabelSession.currentShipmentInfo.type === 'pending' && 'Scan Package'}
                            {currentOverLabelSession.currentShipmentInfo.type !== 'pending' && currentOverLabelSession.currentShipmentInfo.message}
                        </Typography>

                        {/* {!!currentOverpackSession.overPack && (
                            <OverPackMeta>
                                <Typography variant="body1">
                                    {currentOverpackSession.overPack.provider_overpack_id} / {currentOverpackSession.overPack.exit_point} / {currentOverpackSession.overPack.carrier}
                                </Typography>
                            </OverPackMeta>
                        )} */}
                    </OverPackInfo>

                    <OverPackListContainer id="cont" ref={overLabelListRef}>
                        <div className="fix" />
                        {currentOverLabelSession?.overLabelHistory.map((shp, i) => (
                            <TrackingItem key={shp._id} status={shp.type}>
                                {shp.tracking_number ? <Typography variant="body1">{shp.info} - {shp.tracking_number}</Typography> : <Typography variant="body1">{shp.info}</Typography>}
                            </TrackingItem>
                        ))}
                    </OverPackListContainer>
                </Grid>
            </Grid>
        </>
    )
}

export default OverLabel;