import * as React from 'react';
import { Outlet, useLocation } from "react-router-dom";
import Aside from 'components/Aside'
import TopBar from 'components/TopBar'
import { styled } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    getCurrentUserDetails,
    getProviders,
    logoutUser
} from 'store/auth';
import ActionSnackBar from 'components/ActionSnackBar';
import { Snackbar } from '@mui/material';
import { pollBulkOperation, updateBulkIntervalId } from 'store/customers';
import { getUserSetupGuideById } from 'store/auth';

const MainLayoutStyled = styled('div')`
    display: flex;
`;

const MainLayoutContent = styled('div')`
    padding: 48px 32px;
`;

const Main = styled('main')`
    /* position: relative; */
    flex: 1 1;
    height: 100vh;
    overflow: auto;
    contain: content;
`;

const MainLayout = () => {
    const dispatch = useAppDispatch();
    const { bulkOperations } = useAppSelector(state => state.customers);
    const { user } = useAppSelector(state => state.auth);
    const { pathname } = useLocation();

    const handleStartPolling = React.useCallback(() => {
        bulkOperations.forEach(op => {
            if (!op.intervalId) {
                const intervalId = setInterval(() => {
                    dispatch(pollBulkOperation(op.id))
                }, 5000);

                dispatch(updateBulkIntervalId({ id: op.id, intervalId }))
            }
        });
    }, [bulkOperations, dispatch]);

    React.useEffect(() => {
        const promise = dispatch(getProviders());
        const promiseSetupGuide = dispatch(getUserSetupGuideById(user?.id));
        const promiseUser = dispatch(getCurrentUserDetails({ token: undefined }));
        window.addEventListener('bulk-polling:start', handleStartPolling);

        promiseUser.unwrap().catch((err) => {
            if (err && err.name !== 'AbortError') {
                dispatch(logoutUser())
            }
        })

        return () => {
            window.removeEventListener('bulk-polling:start', handleStartPolling);
            promise.abort();
            promiseSetupGuide.abort();
            promiseUser.abort();
        }
    }, [dispatch, handleStartPolling, user?.id]);

    React.useEffect(() => {
        const main = document.querySelector('#main');

        if (main) {
            main.scroll(0, 0);
        }
    }, [pathname]);


    return (
        <MainLayoutStyled>
            <Aside />
            <Main id="main">
                <TopBar showLogo={false} />
                <MainLayoutContent>
                    <Outlet />
                </MainLayoutContent>
            </Main>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={true}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    {bulkOperations.map(op => (
                        <ActionSnackBar
                            key={op.id}
                            title={op.operation_type === 'download_labels' ? 'Preparing Download' : 'Preparing Cancel'}
                            action={op.operation_type === 'download_labels' ? 'download' : 'cancel'}
                        >
                            {op.operation_type === 'download_labels' ?
                                ('Your labels are being prepared to download') : ('Your labels are being prepared to cancel')
                            }
                        </ActionSnackBar>
                    ))}
                </div>
            </Snackbar>
        </MainLayoutStyled >
    )
}

export default MainLayout;