import {
    createSlice,
    createAsyncThunk,
} from '@reduxjs/toolkit';
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { createOverpack, updateOverpack, getOverpackFromProviderId, updateOverpackDimentions, createManifest, getAllManifest, createOverLabel, getOverLabel, printOverLabel } from 'api/wms';
import { CreateOverPackError, CreateOverPackValidationError } from 'api/wms/types';
// import axios from 'axios';
import { OverLabelHistory, OverPackHistory, wmsState } from './types';
import { randomID } from 'utils';
import printJS from 'print-js';
import { isApiError } from 'api';

const dingSoundFile = require('assets/sounds/ding.mp3');
const errorSoundFile = require('assets/sounds/error.mp3');

export const createNewOverpack = createAsyncThunk('wms/inviteUser', createOverpack);
export const updateOverpackById = createAsyncThunk('wms/updateOverpack', updateOverpack);
export const getOverpackByProviderId = createAsyncThunk('wms/getOverpackFromProviderId', getOverpackFromProviderId);
export const updateOverpackDimentionsById = createAsyncThunk('wms/updateOverpackDimentions', updateOverpackDimentions);
export const createNewManifest = createAsyncThunk('wms/createManifest', createManifest);
export const getManifestList = createAsyncThunk('wms/getAllManifest', getAllManifest);
export const createNewOverLabel = createAsyncThunk('wms/createOverLabel', createOverLabel);

const errorPrefix = '[Error]';

const printFileFromURL = (url: string) => {
    printJS(url);

    // axios.get(url, { responseType: 'blob' })
    //     .then((res) => {
    //         const file = new File([res.data], 'overpack_label');

    //         const pdfUrl = URL.createObjectURL(file);
    //         printJS(pdfUrl);
    //     })
    //     .catch(err => {
    //         console.log('err: ', err);
    //     });
}

const initialState: wmsState = {
    currentOverpackSession: {
        overPack: null,
        overPackHistory: [],
        currentShipmentInfo: {
            message: '',
            type: 'pending'
        }
    },
    currentManifestSession: {
        overPackList: [],
        currentOverpackInfo: {
            message: '',
            type: 'pending'
        }
    },
    currentOverLabelSession: {
        overLabelHistory: [],
        currentShipmentInfo: {
            message: '',
            type: 'pending'
        }
    },
    manifestsList: [],
    createNewOverpackStatus: 'IDLE',
    updateOverpackByIdStatus: 'IDLE',
    getOverpackByProviderIdStatus: 'IDLE',
    updateOverpackDimentionsByIdStatus: 'IDLE',
    createNewManifestStatus: 'IDLE',
    getManifestListStatus: 'IDLE',
    createNewOverLabelStatus: 'IDLE'
}

export const wmsApiSlice = createApi({
    reducerPath: 'wmsApi',
    baseQuery: fakeBaseQuery(),
    endpoints: builder => ({
        getOverLabel: builder.query({
            queryFn: async (data) => {
                return getOverLabel(data).then(data => {
                    return {
                        data
                    }
                }).catch(error => {
                    if (isApiError(error)) {
                        return {
                            error: error.response?.data
                        }
                    }

                    return {
                        error
                    }
                })

            }
        })
    })
})

export const wmsSlice = createSlice({
    name: 'wms',
    initialState: initialState,
    reducers: {
        resetSession: (state) => {
            state.currentOverpackSession = {
                overPack: null,
                overPackHistory: [],
                currentShipmentInfo: {
                    message: 'Scan Package',
                    type: 'pending'
                }
            };
        },
        resetShipmentInfo: (state, action) => {
            state.currentOverpackSession.currentShipmentInfo = {
                message: '',
                type: 'pending'
            }

            clearInterval(action.payload)
        },
        resetOverpackInfo: (state, action) => {
            state.currentManifestSession.currentOverpackInfo = {
                message: '',
                type: 'pending'
            }

            clearInterval(action.payload)
        },
        printCurrentOverpack: (state) => {
            if (state.currentOverpackSession.overPack?.overpack_label) {
                printFileFromURL(state.currentOverpackSession.overPack?.overpack_label)
            }
        },
        clearOverPackList: (state) => {
            state.currentManifestSession.overPackList = [];
        },
        deleteManifestOverpack: (state, action) => {
            state.currentManifestSession.overPackList = state.currentManifestSession.overPackList.filter(op => op._id !== action.payload);
        },
        resetCreateNewManifestStatus: (state, action) => {
            state.createNewManifestStatus = 'IDLE';

            clearInterval(action.payload)
        },
        duplicateOverpackError: (state) => {
            // if (action.payload) {
            state.currentManifestSession.currentOverpackInfo = {
                message: 'Overpack already added',
                type: 'error'
            };
            // }

            const audio = new Audio(errorSoundFile);
            audio.play();
            // state.updateOverpackDimentionsByIdStatus = 'ERROR';
        },
        resetOverLabelInfo: (state, action) => {
            state.currentOverLabelSession.currentShipmentInfo = {
                message: '',
                type: 'pending'
            }

            clearInterval(action.payload)
        },
        resetOverLabelSession: (state) => {
            state.currentOverLabelSession = {
                overLabelHistory: [],
                currentShipmentInfo: {
                    message: 'Scan Package',
                    type: 'pending'
                }
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(createNewOverpack.pending, (state, action) => {
            state.createNewOverpackStatus = 'PENDING';
        });
        builder.addCase(createNewOverpack.fulfilled, (state, action) => {
            const overPackHistory = action.payload.shipments.map(shp => {
                shp.type = 'success';
                shp.info = 'Scanned';
                return shp;
            });

            const otherHistory: OverPackHistory[] = [{
                _id: randomID.uniqueId(),
                historyType: 'overpack',
                info: 'Overpack created',
                type: 'success',
                tracking_number: action.payload.overpack.provider_overpack_id
            }]

            state.currentOverpackSession.overPack = action.payload.overpack;
            state.currentOverpackSession.overPackHistory = [
                ...state.currentOverpackSession.overPackHistory,
                ...otherHistory,
                ...overPackHistory
            ];
            state.currentOverpackSession.currentShipmentInfo = {
                message: 'Successfully added to Overpack',
                type: 'success'
            }

            const audio = new Audio(dingSoundFile);
            audio.play();

            printFileFromURL(action.payload.overpack.overpack_label);

            state.createNewOverpackStatus = 'SUCCESS';
        });
        builder.addCase(createNewOverpack.rejected, (state, action) => {
            if (action.payload && (action.payload as CreateOverPackError).overpack) {
                const overPackHistory = action.meta.arg.shipments.map(shp => {
                    const shipment = (action.payload as CreateOverPackError).overpack.shipments.find(s => s.tracking_number === shp.tracking_number);

                    shp.type = 'error';
                    shp.info = `${errorPrefix} ${shipment?.error}`;

                    return shp;
                });

                state.currentOverpackSession.overPackHistory = [...state.currentOverpackSession.overPackHistory, ...overPackHistory];
                state.currentOverpackSession.currentShipmentInfo = {
                    message: (action.payload as CreateOverPackError).overpack.shipments[0]?.error || 'Error',
                    type: 'error'
                }
            } else if ((action.payload as CreateOverPackValidationError).message) {
                // const otherHistory: OverPackHistory[] = [{
                //     _id: randomID.uniqueId(),
                //     historyType: 'tracking',
                //     info: (action.payload as CreateOverPackValidationError).message,
                //     type: 'error',
                //     tracking_number: ''
                // }];

                // state.currentOverpackSession.overPackHistory = [...state.currentOverpackSession.overPackHistory, ...overPackHistory];
                state.currentOverpackSession.currentShipmentInfo = {
                    message: (action.payload as CreateOverPackValidationError).message || 'Error',
                    type: 'error'
                }
            }

            const audio = new Audio(errorSoundFile);
            audio.play();
            state.createNewOverpackStatus = 'ERROR';
        });
        builder.addCase(updateOverpackById.pending, (state, action) => {
            state.updateOverpackByIdStatus = 'PENDING';
        });
        builder.addCase(updateOverpackById.fulfilled, (state, action) => {
            const overPackHistory = action.payload.shipments.map(shp => {
                shp.type = 'success';
                shp.info = 'Scanned';
                return shp;
            });

            state.currentOverpackSession.overPack = action.payload.overpack;
            state.currentOverpackSession.overPackHistory = [...state.currentOverpackSession.overPackHistory, ...overPackHistory];
            state.currentOverpackSession.currentShipmentInfo = {
                message: 'Successfully added to Overpack',
                type: 'success'
            }

            const audio = new Audio(dingSoundFile);
            audio.play();
            state.updateOverpackByIdStatus = 'SUCCESS';
        });
        builder.addCase(updateOverpackById.rejected, (state, action) => {
            // console.log('action: ', action);
            if (action.payload && (action.payload as CreateOverPackError).overpack) {
                const overPackHistory = action.meta.arg.payload.shipments.map(shp => {
                    const shipment = (action.payload as CreateOverPackError).overpack.shipments.find(s => s.tracking_number === shp.tracking_number);

                    shp.type = 'error';
                    shp.info = `${errorPrefix} ${shipment?.error}`;

                    return shp;
                });

                state.currentOverpackSession.overPackHistory = [...state.currentOverpackSession.overPackHistory, ...overPackHistory];
                state.currentOverpackSession.currentShipmentInfo = {
                    message: (action.payload as CreateOverPackError).overpack.shipments[0]?.error || 'Error',
                    type: 'error'
                }
            } else if ((action.payload as CreateOverPackValidationError).message) {
                const otherHistory: OverPackHistory[] = [{
                    _id: randomID.uniqueId(),
                    historyType: 'tracking',
                    info: `${errorPrefix} ${(action.payload as CreateOverPackValidationError).message}`,
                    type: 'error',
                    tracking_number: action.meta.arg.id
                }];

                state.currentOverpackSession.overPackHistory = [...state.currentOverpackSession.overPackHistory, ...otherHistory];

                state.currentOverpackSession.currentShipmentInfo = {
                    message: (action.payload as CreateOverPackValidationError).message || 'Error',
                    type: 'error'
                }
            }

            const audio = new Audio(errorSoundFile);
            audio.play();
            state.updateOverpackByIdStatus = 'ERROR';
        });
        builder.addCase(getOverpackByProviderId.pending, (state, action) => {
            state.getOverpackByProviderIdStatus = 'PENDING';
        });
        builder.addCase(getOverpackByProviderId.fulfilled, (state, action) => {
            const otherHistory: OverPackHistory[] = [{
                _id: randomID.uniqueId(),
                historyType: 'overpack',
                info: 'Overpack changed',
                type: 'success',
                tracking_number: action.payload.provider_overpack_id
            }];

            state.currentOverpackSession.overPackHistory = [
                ...state.currentOverpackSession.overPackHistory,
                ...otherHistory
            ];
            state.currentOverpackSession.overPack = action.payload;
            state.getOverpackByProviderIdStatus = 'SUCCESS';
        });
        builder.addCase(getOverpackByProviderId.rejected, (state, action) => {
            // console.log('action: ', action);
            // if (action.payload && (action.payload as CreateOverPackError).overpack) {
            //     const overPackHistory = action.meta.arg.payload.shipments.map(shp => {
            //         const shipment = (action.payload as CreateOverPackError).overpack.shipments.find(s => s.tracking_number === shp.tracking_number);

            //         shp.type = 'error';
            //         shp.info = `${errorPrefix} ${shipment?.error}`;

            //         return shp;
            //     });

            //     state.currentOverpackSession.overPackHistory = [...state.currentOverpackSession.overPackHistory, ...overPackHistory];
            //     state.currentOverpackSession.currentShipmentInfo = {
            //         message: (action.payload as CreateOverPackError).overpack.shipments[0]?.error || 'Error',
            //         type: 'error'
            //     }
            // } else if ((action.payload as CreateOverPackValidationError).message) {
            const otherHistory: OverPackHistory[] = [{
                _id: randomID.uniqueId(),
                historyType: 'tracking',
                info: `${errorPrefix} ${(action.payload as CreateOverPackValidationError).message}`,
                type: 'error',
                tracking_number: action.meta.arg
            }];

            state.currentOverpackSession.overPackHistory = [...state.currentOverpackSession.overPackHistory, ...otherHistory];

            state.currentOverpackSession.currentShipmentInfo = {
                message: (action.payload as CreateOverPackValidationError).message || 'Error',
                type: 'error'
            }
            // }

            const audio = new Audio(errorSoundFile);
            audio.play();
            // state.updateOverpackByIdStatus = 'ERROR';
            state.getOverpackByProviderIdStatus = 'ERROR';
        });
        builder.addCase(updateOverpackDimentionsById.pending, (state, action) => {
            state.updateOverpackDimentionsByIdStatus = 'PENDING';
        });
        builder.addCase(updateOverpackDimentionsById.fulfilled, (state, action) => {
            state.currentManifestSession.currentOverpackInfo = {
                message: 'Overpack successfully added to Manifest',
                type: 'success'
            };

            state.currentManifestSession.overPackList.push({
                _id: action.payload.provider_overpack_id,
                overpack: action.payload.provider_overpack_id,
                destination: action.payload.exit_point,
                service: action.payload.service,
                weight: action.payload.weight,
                height: action.payload.height,
                length: action.payload.length,
                width: action.payload.width
            });

            const audio = new Audio(dingSoundFile);
            audio.play();
            state.updateOverpackDimentionsByIdStatus = 'SUCCESS';
        });
        builder.addCase(updateOverpackDimentionsById.rejected, (state, action) => {
            if (action.payload) {
                state.currentManifestSession.currentOverpackInfo = {
                    message: (action.payload as { code: string, http_status: string, message: string }).message || 'Error',
                    type: 'error'
                };
            }

            const audio = new Audio(errorSoundFile);
            audio.play();
            state.updateOverpackDimentionsByIdStatus = 'ERROR';
        });
        builder.addCase(createNewManifest.pending, (state, action) => {
            state.createNewManifestStatus = 'PENDING';
        });
        builder.addCase(createNewManifest.fulfilled, (state, action) => {
            // state.manifestsList.push(action.payload);
            state.createNewManifestStatus = 'SUCCESS';
        });
        builder.addCase(createNewManifest.rejected, (state, action) => {
            state.createNewManifestStatus = 'ERROR';
        });
        builder.addCase(getManifestList.pending, (state, action) => {
            state.getManifestListStatus = 'PENDING';
        });
        builder.addCase(getManifestList.fulfilled, (state, action) => {
            state.manifestsList = action.payload;
            state.getManifestListStatus = 'SUCCESS';
        });
        builder.addCase(getManifestList.rejected, (state, action) => {
            state.getManifestListStatus = 'ERROR';
        });
        builder.addCase(createNewOverLabel.pending, (state, action) => {
            const otherHistory: OverLabelHistory[] = [{
                _id: randomID.uniqueId(),
                info: 'Generating new label',
                type: 'success'
            }]

            state.createNewOverLabelStatus = 'PENDING';
            state.currentOverLabelSession.overLabelHistory = [
                ...state.currentOverLabelSession.overLabelHistory,
                ...otherHistory
            ];
        });
        builder.addCase(createNewOverLabel.fulfilled, (state, action) => {
            if (action.payload.status === "FAILED") {
                const otherHistory: OverLabelHistory[] = [{
                    _id: randomID.uniqueId(),
                    info: `${errorPrefix} ${action.payload.metadata?.error}`,
                    type: 'error',
                    tracking_number: action.meta.arg.shipment_id
                }];

                state.currentOverLabelSession.overLabelHistory = [
                    ...state.currentOverLabelSession.overLabelHistory,
                    ...otherHistory
                ]

                state.currentOverLabelSession.currentShipmentInfo = {
                    message: action.payload.metadata?.error as string,
                    type: 'error'
                }

                const audio = new Audio(errorSoundFile);
                audio.play();
            }

            // else {
            //     const otherHistory: OverLabelHistory[] = [{
            //         _id: randomID.uniqueId(),
            //         info: 'New label',
            //         type: 'success',
            //         tracking_number: action.meta.arg.shipment_id
            //     }];

            //     state.currentOverLabelSession.overLabelHistory = [
            //         ...state.currentOverLabelSession.overLabelHistory,
            //         ...otherHistory
            //     ]

            //     state.currentOverLabelSession.currentShipmentInfo = {
            //         message: 'Successful scan - printing new label',
            //         type: 'success'
            //     }

            //     const audio = new Audio(dingSoundFile);
            //     audio.play();
            // }

            state.createNewOverLabelStatus = 'SUCCESS';
        });
        builder.addCase(createNewOverLabel.rejected, (state, action) => {
            state.createNewOverLabelStatus = 'ERROR'
        });
        builder.addMatcher(wmsApiSlice.endpoints.getOverLabel.matchFulfilled, (state, action) => {
            if (action.payload.status === "FAILED") {
                const otherHistory: OverLabelHistory[] = [{
                    _id: randomID.uniqueId(),
                    info: `${errorPrefix} ${action.payload.metadata?.error}`,
                    type: 'error',
                    tracking_number: action.payload.shipment_id
                }];

                state.currentOverLabelSession.overLabelHistory = [
                    ...state.currentOverLabelSession.overLabelHistory,
                    ...otherHistory
                ]

                state.currentOverLabelSession.currentShipmentInfo = {
                    message: action.payload.metadata?.error as string,
                    type: 'error'
                }

                const audio = new Audio(errorSoundFile);
                audio.play();
            }

            if (action.payload.status === "COMPLETED") {
                const otherHistory: OverLabelHistory[] = [{
                    _id: randomID.uniqueId(),
                    info: 'New label',
                    type: 'success',
                    tracking_number: action.payload.created_label_tn
                }];

                state.currentOverLabelSession.overLabelHistory = [
                    ...state.currentOverLabelSession.overLabelHistory,
                    ...otherHistory
                ]

                state.currentOverLabelSession.currentShipmentInfo = {
                    message: 'Successful scan - printing new label',
                    type: 'success'
                }

                const audio = new Audio(dingSoundFile);
                audio.play();

                printOverLabel(action.payload.created_label_tn).then(res => {
                    printFileFromURL(res.file_url)
                }).catch(err => {
                    console.log('Error printing label');
                })
            }
        })
    },
});

export const {
    resetSession,
    printCurrentOverpack,
    resetShipmentInfo,
    resetOverpackInfo,
    clearOverPackList,
    deleteManifestOverpack,
    resetCreateNewManifestStatus,
    duplicateOverpackError,
    resetOverLabelInfo,
    resetOverLabelSession
} = wmsSlice.actions;

export const { useGetOverLabelQuery, useLazyGetOverLabelQuery } = wmsApiSlice;

export default wmsSlice.reducer;