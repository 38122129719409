/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Table, TableBody, TableCell, TableRow, Modal } from "@mui/material";
import UIButton from "components/UIButton";
import UICard from "components/UICard";
import { Input } from "components/UIForm";
import UILink from "components/UILink";
import {
  SettingsCard,
  SettingsDesc,
  SettingBottomCard,
  LogoUploadContainer,
  LogoLoadedContainer,
  LogoLoadedActions,
  LogoLoadedDisplay,
  DomainVerificationContainer,
  DomainAction,
  DomainActionName,
  DomainDNSInfo,
  DomainSubList,
  // DomainSubItem,
  DomainSubItemInfo,
  DomainModalHeading,
  DomainDetailModalHeading,
  DomainUpdateForm,
  DomainUpdateFormActions,
  DomainUpdateWarning,
  ConfigView,
} from "./settings.styled";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { removeLogo, updateUserOrganization } from "store/auth";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { ModalBody } from "components/UIModal";
import DNSConfigModal from "./DNSConfigModal";
import CircularProgress from "@mui/material/CircularProgress";
import { verifyDomainDNS } from "store/auth";

const brandingSchema = Yup.object().shape({
  logo: Yup.string().required("Logo is required"),
  primary_color: Yup.string().required("Primary color is required"),
  domain: Yup.string().matches(
    /^([a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?\.)+([a-zA-Z0-9]{1,2}([-a-zA-Z0-9]{0,252}[a-zA-Z0-9])?)\.([a-zA-Z]{2,63})$/,
    "You must enter a subdomain (e.g. subdomain.domain.com)"
  ),
});

const domainSchema = Yup.object().shape({
  domain: Yup.string().matches(
    /^([a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?\.)+([a-zA-Z0-9]{1,2}([-a-zA-Z0-9]{0,252}[a-zA-Z0-9])?)\.([a-zA-Z]{2,63})$/,
    "You must enter a subdomain (e.g. subdomain.domain.com)"
  ),
});

const Branding = () => {
  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const [file, setFile] = React.useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [openDNSConfigModal, setOpenDNSConfigModal] = React.useState(false);

  const { organization, verifyDNSStatus } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDNSConfigModal = () => setOpenDNSConfigModal(true);
  const handleCloseDNSConfigModal = () => setOpenDNSConfigModal(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      logo: organization?.logo?.uploaded_file_url,
      primary_color: organization?.primary_color || "",
      domain: organization?.domain || "",
    },
    validationSchema: brandingSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      await dispatch(
        updateUserOrganization({
          ...values,
          api_domain: values.domain ? `api.${values.domain}` : values.domain,
          file,
        })
      );
      setSubmitting(false);
      setFile(null);
    },
  });

  const domainFormik = useFormik({
    enableReinitialize: true,
    initialValues: { domain: organization?.domain || "" },
    validationSchema: domainSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      await dispatch(
        updateUserOrganization({
          ...values,
          api_domain: values.domain ? `api.${values.domain}` : values.domain,
        })
      );
      setSubmitting(false);
      handleClose();
    },
  });

  const onFileChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    // console.log(file);
    formik.setFieldValue("logo", file.name);
    setFile(file);
  };

  const handleRemoveLogo = () => {
    dispatch(removeLogo());
    setFile(null);
  };

  const handleCheckDNS = () => {
    dispatch(verifyDomainDNS());
  };

  return (
    <UICard>
      <form onSubmit={formik.handleSubmit}>
        <SettingsCard>
          <Typography variant="body1">Dashboard Logo</Typography>
          <SettingsDesc variant="body1">
            This will be shown as the logo on your custom sign in page, in your
            customer dashboard, and on receipts to your customers each month.
            The uploaded image must be 600px by 200px, and make sure to
            left-align your logo, ensuring that it does not have a background
            color. For full guidelines on logo specifications, refer to the{" "}
            <UILink href="https://carrierlink.notion.site/Organization-Guide-fbe1e31298e44d418a83e449f7854a38">
              Organization Guide →
            </UILink>
          </SettingsDesc>

          <SettingBottomCard>
            <FormControl
              error={Boolean(formik.errors.logo && formik.touched.logo)}
            >
              <input
                ref={fileUploadRef}
                type="file"
                style={{ display: "none" }}
                onChange={onFileChange}
                accept=".svg, .png"
              />

              {file ||
              (organization?.logo && organization?.logo?.uploaded_file_url) ? (
                <LogoLoadedContainer>
                  <LogoLoadedActions>
                    <UIButton
                      variant="outlined"
                      onClick={() => fileUploadRef.current?.click()}
                    >
                      Replace Image
                    </UIButton>
                    <UIButton variant="outlined" onClick={handleRemoveLogo}>
                      Remove Image
                    </UIButton>
                  </LogoLoadedActions>

                  <LogoLoadedDisplay>
                    <img
                      src={
                        (file && URL.createObjectURL(file)) ||
                        organization?.logo?.uploaded_file_url
                      }
                      alt="file"
                    />
                  </LogoLoadedDisplay>
                </LogoLoadedContainer>
              ) : (
                <LogoUploadContainer>
                  <UIButton
                    variant="outlined"
                    onClick={() => fileUploadRef.current?.click()}
                  >
                    Upload Logo
                  </UIButton>
                  <SettingsDesc variant="body1" fontStyle="italic">
                    {file ? file.name : "Supported formats: .png or .svg"}
                  </SettingsDesc>
                </LogoUploadContainer>
              )}
              {formik.errors.logo && formik.touched.logo && (
                <FormHelperText id="primary-error-text">
                  {formik.errors.logo}
                </FormHelperText>
              )}
            </FormControl>
          </SettingBottomCard>
        </SettingsCard>

        <SettingsCard>
          <Typography variant="body1">Dashboard Primary Color</Typography>
          <SettingsDesc variant="body1">
            This color will be used to apply a theme to your dashboard
            experience and your customer’s dashboard. Please refer to the{" "}
            <UILink href="https://carrierlink.notion.site/Organization-Guide-fbe1e31298e44d418a83e449f7854a38">
              Organization Guide →
            </UILink>
          </SettingsDesc>

          <SettingBottomCard>
            <FormControl
              error={Boolean(
                formik.errors.primary_color && formik.touched.primary_color
              )}
            >
              <Input
                name="primary_color"
                id="primary_color"
                placeholder="Hex (e.g. FF11FF)"
                aria-describedby="primary-error-text"
                value={formik.values.primary_color}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.errors.primary_color && formik.touched.primary_color
                )}
              />
              {formik.errors.primary_color && formik.touched.primary_color && (
                <FormHelperText id="primary-error-text">
                  {formik.errors.primary_color}
                </FormHelperText>
              )}
            </FormControl>
          </SettingBottomCard>
        </SettingsCard>

        <SettingsCard>
          <Typography variant="body1">Dashboard Hosted Domain</Typography>
          <SettingsDesc variant="body1">
            This is the subdomain that your customer dashboard will be hosted
            on. After entering a domain name, you will need to complete DNS
            configuration. Please note, this must be a subdomain and not a
            top-level domain name. Read more about setting up your customer
            dashboard in the{" "}
            <UILink href="https://carrierlink.notion.site/Organization-Guide-fbe1e31298e44d418a83e449f7854a38">
              Organization Guide →{" "}
            </UILink>
          </SettingsDesc>

          <SettingBottomCard>
            {organization && organization.domain ? (
              <DomainVerificationContainer>
                <DomainAction>
                  <DomainActionName>
                    <Icon name="success" size={24} />
                    <Typography variant="body1">
                      {organization.domain}
                    </Typography>
                  </DomainActionName>
                  <UIButton variant="outlined" onClick={handleOpen}>
                    Update Domain
                  </UIButton>
                </DomainAction>

                <DomainDNSInfo>
                  <Typography variant="body1">DNS Configuration</Typography>
                  <SettingsDesc variant="body1">
                    For your carrier services to be live for your customers, you
                    will need both of the below domains to show check marks.
                  </SettingsDesc>

                  <DomainSubList>
                    <Table aria-label="customized table">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <SettingsDesc variant="body1">
                              Customer Dashboard
                            </SettingsDesc>
                          </TableCell>
                          <TableCell>
                            <DomainSubItemInfo>
                              {verifyDNSStatus === "PENDING" ? (
                                <CircularProgress color="black" size={24} />
                              ) : (
                                <Icon
                                  name={
                                    organization.is_domain_verified
                                      ? "success"
                                      : "warning"
                                  }
                                  size={24}
                                />
                              )}
                              <Typography variant="body1">
                                {organization.domain}
                              </Typography>
                              {!organization.is_domain_verified ? (
                                <Link to="#" onClick={handleCheckDNS}>
                                  Check DNS Information
                                </Link>
                              ) : null}
                            </DomainSubItemInfo>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <SettingsDesc variant="body1">
                              Customer API
                            </SettingsDesc>
                          </TableCell>
                          <TableCell>
                            <DomainSubItemInfo>
                              {verifyDNSStatus === "PENDING" ? (
                                <CircularProgress color="black" size={24} />
                              ) : (
                                <Icon
                                  name={
                                    organization.is_api_domain_verified
                                      ? "success"
                                      : "warning"
                                  }
                                  size={24}
                                />
                              )}
                              <Typography variant="body1">
                                {organization.api_domain}
                              </Typography>
                              {!organization.is_api_domain_verified ? (
                                <Link to="#" onClick={handleCheckDNS}>
                                  Check DNS Information
                                </Link>
                              ) : null}
                            </DomainSubItemInfo>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </DomainSubList>
                </DomainDNSInfo>

                {!organization.is_domain_verified ||
                !organization.is_api_domain_verified ? (
                  <ConfigView onClick={handleDNSConfigModal}>
                    <Icon name="eye" size={15} />
                    <Typography variant="body1">
                      Configuration Information →
                    </Typography>
                  </ConfigView>
                ) : null}
              </DomainVerificationContainer>
            ) : (
              <FormControl
                error={Boolean(formik.errors.domain && formik.touched.domain)}
              >
                <Input
                  name="domain"
                  id="domain"
                  placeholder="Domain (e.g. sellerdirectcompete.com)"
                  aria-describedby="domain-error-text"
                  value={formik.values.domain}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.domain && formik.touched.domain)}
                  style={{ width: "400px" }}
                />
                {formik.errors.domain && formik.touched.domain && (
                  <FormHelperText id="domain-error-text">
                    {formik.errors.domain}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </SettingBottomCard>
        </SettingsCard>

        <SettingsCard>
          <UIButton
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Save Branding
          </UIButton>
        </SettingsCard>
      </form>

      <Modal
        open={open}
        onClose={handleClose}
        style={{ overflow: "auto" }}
        aria-labelledby="domain-modal-title"
        aria-describedby="domain-modal-description"
        keepMounted
      >
        <ModalBody>
          <DomainModalHeading variant="h2">
            Update Hosted Domain
          </DomainModalHeading>

          <DomainDetailModalHeading variant="body1">
            Current Domain Configuration
          </DomainDetailModalHeading>
          <DomainSubList>
            <Table aria-label="customized table">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <SettingsDesc variant="body1">
                      Customer Dashboard
                    </SettingsDesc>
                  </TableCell>
                  <TableCell>
                    <DomainSubItemInfo>
                      <Icon
                        name={
                          organization?.is_domain_verified
                            ? "success"
                            : "warning"
                        }
                        size={24}
                      />
                      <Typography variant="body1">
                        {organization?.domain}
                      </Typography>
                    </DomainSubItemInfo>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <SettingsDesc variant="body1">Customer API</SettingsDesc>
                  </TableCell>
                  <TableCell>
                    <DomainSubItemInfo>
                      <Icon
                        name={
                          organization?.is_api_domain_verified
                            ? "success"
                            : "warning"
                        }
                        size={24}
                      />
                      <Typography variant="body1">
                        {organization?.api_domain}
                      </Typography>
                    </DomainSubItemInfo>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DomainSubList>

          <DomainDetailModalHeading variant="body1" style={{ marginTop: 0 }}>
            WARNING:
          </DomainDetailModalHeading>
          <Typography variant="body1">
            Your existing domains have not yet updated or propagated, so any
            changes you make here will apply instantly, and your customer
            dashboard will not serve on your old domain.
          </Typography>

          <DomainDetailModalHeading variant="body1">
            Update Hosted Domain
          </DomainDetailModalHeading>

          <DomainUpdateForm onSubmit={domainFormik.handleSubmit}>
            <FormControl
              error={Boolean(
                domainFormik.errors.domain && domainFormik.touched.domain
              )}
            >
              <Input
                name="domain"
                id="domain"
                placeholder="Domain (e.g. sellerdirectcompete.com)"
                aria-describedby="domain-update-error-text"
                value={domainFormik.values.domain}
                onChange={domainFormik.handleChange}
                onBlur={domainFormik.handleBlur}
                error={Boolean(
                  domainFormik.errors.domain && domainFormik.touched.domain
                )}
              />
              {domainFormik.errors.domain && domainFormik.touched.domain && (
                <FormHelperText id="domain-update-error-text">
                  {domainFormik.errors.domain}
                </FormHelperText>
              )}
            </FormControl>

            {domainFormik.dirty ? (
              <>
                <DomainUpdateFormActions>
                  <UIButton
                    type="submit"
                    disabled={
                      domainFormik.isSubmitting || !domainFormik.isValid
                    }
                  >
                    Save Domain
                  </UIButton>
                </DomainUpdateFormActions>

                <DomainUpdateWarning>
                  <Icon name="confirm" size={24} />
                  <Typography variant="body1">
                    This action is final. Please make sure you understand the
                    implications of this change.
                  </Typography>
                </DomainUpdateWarning>
              </>
            ) : null}
          </DomainUpdateForm>
        </ModalBody>
      </Modal>

      <DNSConfigModal
        open={openDNSConfigModal}
        handleClose={handleCloseDNSConfigModal}
      />
    </UICard>
  );
};

export default Branding;
