import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import UICard from 'components/UICard';
import { SettingsCard, SettingsDesc } from './settings.styled';
import UILink from 'components/UILink';
import UIButton from 'components/UIButton';
import { authorizeBoxC } from 'integrations/boxc';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateOrganizationProvider } from 'store/auth';
import Icon from 'components/Icon';

const CarrierConfigAction = styled('div')`
    margin-top: 42px;
    /* margin-bottom: 42px; */
`;

const CarrierInfoCard = styled(Card)`
    padding: 20px;
    border-color: #E7E7E7;
    border-radius: 14px;
    display: flex;
    align-items: center;
    margin-top: 42px;

    p {
       margin-left: 15px;
    }
`;

const ConnectionSuccess = styled('div')`
    display: flex;
    align-items: center;
`;

const ConnectionSuccessIcon = styled('div')`
    margin-right: 8px;
`;

const ConnectionSuccessInfo = styled('div')`
    p:nth-of-type(2) {
        color: ${props => props.theme.palette.greyOne.main};
    }
`;

const getSearchParams = (params: URLSearchParams) => Object.fromEntries(params);

const CarrierConfiguration = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { organization, providers } = useAppSelector(state => state.auth);

    const handleConnectAccount = () => authorizeBoxC();

    const saveBoxcConfig = React.useCallback(() => {
        const params = getSearchParams(searchParams);

        if (params.action === 'auth' && params.provider === 'boxc') {
            const provider = providers.find(prv => prv.slug === params.provider);

            if (provider) {
                const data = {
                    access_token: params.access_token,
                    access_token_expired_at: new Date(parseInt(params.expires_in)).toISOString(),
                    provider: provider.id,
                    providerInfo: provider
                }

                return dispatch(updateOrganizationProvider(data));
            }
        }
    }, [dispatch, searchParams, providers]);

    React.useEffect(() => {
        const promise = saveBoxcConfig();

        return () => {
            promise?.abort();
        }
    }, [saveBoxcConfig]);

    return (
        <UICard style={{ marginBottom: 32 }}>
            <SettingsCard>
                <Typography variant="body1">Connect a Provider</Typography>
                <SettingsDesc variant="body1">BoxC provides critical carrier infrastructure to our customers and enables you to offer shipment services to your customers. Learn more about the integration in the <UILink href="https://carrierlink.notion.site/Carrier-Configuration-Guide-d0b48489538e41aa902159403b0c6cf6">Carrier Configuration Guide →</UILink> </SettingsDesc>

                <CarrierConfigAction>
                    {(organization?.auth_configs?.length || []) > 0 ? (
                        <ConnectionSuccess>
                            <ConnectionSuccessIcon>
                                <Icon name="success" size={24} />
                            </ConnectionSuccessIcon>
                            <ConnectionSuccessInfo>
                                <Typography variant="body1">BoxC account connected</Typography>
                                <Typography variant="body1">Connected since {new Date(organization?.auth_configs[0].created || '').toLocaleDateString()}</Typography>
                            </ConnectionSuccessInfo>
                        </ConnectionSuccess>
                    ) : (
                        <UIButton color="success" onClick={handleConnectAccount}>Connect BoxC Account</UIButton>
                    )}
                </CarrierConfigAction>
                {(organization?.auth_configs?.length || []) === 0 ? (
                    <CarrierInfoCard variant="outlined">
                        <Icon name="info" size={32} />
                        <Typography variant="body1">You will need to connect your BoxC account before your customer dashboard is enabled for customers.</Typography>
                    </CarrierInfoCard>
                ) : null}
            </SettingsCard>
            {(organization?.auth_configs?.length || []) > 0 ? (
                <SettingsCard>
                    <UIButton onClick={() => navigate('/settings/carrier')}>Manage Carrier Configuration</UIButton>
                </SettingsCard>
            ) : null}
        </UICard>
    )
}

export default CarrierConfiguration;