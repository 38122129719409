import { Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout';
import WareLayout from 'layouts/WareLayout';

import Home from 'pages/Home';
import Shipments from 'pages/Shipments';
import Customers from 'pages/Customers';
import OrganizationSettings from 'pages/OrganizationSettings';
import AccountSettings from 'pages/AccountSettings';
import CarrierConfig from 'pages/CarrierConfig';
import CustomerView from 'pages/CustomerView';
import BulkHistory from 'pages/BulkHistory';
import WareHousing from 'pages/WareHousing';
import ShipmentView from 'pages/ShipmentView';
import OverPacking from 'pages/OverPacking';
import ChangePassword from 'pages/ChangePassword';
import Billing from 'pages/Billing';

import { useAppSelector } from 'store/hooks';
import Manifesting from 'pages/Manifesting';
import Webhooks from 'pages/Webhooks';
import OverLabeling from 'pages/OverLabeling';

const WMSRoutes = () => {
    const { organization } = useAppSelector(state => state.auth);

    return (
        <Routes>
            <Route element={<WareLayout />}>
                <Route path='overpacking' element={<OverPacking />} />
                <Route path='manifesting' element={<Manifesting />} />
                {organization?.overlabeling_enabled && <Route path='overlabeling' element={<OverLabeling />} />}
                <Route path='/*' element={<Navigate to='/warehousing' />} />
            </Route>
        </Routes>
    )
}

const PrivateRoutes = () => {
    const { organization } = useAppSelector(state => state.auth);

    return (
        <Routes>
            {organization?.approval_status === 'approved' && (
                <Route path='wms/*' element={<WMSRoutes />} />
            )}
            <Route path='/*' element={<MainLayout />}>
                <Route path='auth/*' element={<Navigate to='/' />} />
                <Route path='' element={<Home />} />
                <Route path='settings' element={<OrganizationSettings />} />
                {organization?.approval_status === 'approved' && (
                    <>
                        <Route path='customers' element={<Customers />} />
                        <Route path='warehousing' element={<WareHousing />} />
                        <Route path='customers/:customerId' element={<CustomerView />} />
                        <Route path='customers/:customerId/shipments' element={<Shipments />} />
                        <Route path='customers/:customerId/shipments/bulk' element={<BulkHistory />} />
                        <Route path='customers/shipments/:shipmentId' element={<ShipmentView />} />
                    </>
                )}
                {organization?.webhooks_enabled && (
                    <Route path='integrations' element={<Webhooks />} />
                )}
                <Route path='settings/carrier' element={<CarrierConfig />} />
                <Route path='settings/billing' element={<Billing />} />
                <Route path='account/settings' element={<AccountSettings />} />
                <Route path='account/settings/reset_password' element={<ChangePassword />} />
                <Route path='/*' element={<Navigate to='/' />} />
            </Route>
        </Routes>
    )
}

export default PrivateRoutes;