import * as React from 'react';
import Typography from '@mui/material/Typography';
import { UICheckBox } from 'components/UIForm';
import Icon from 'components/Icon';
import { DataFilterContainer, FilterAction, FilterButton, FilterInfo } from './data-filter.styled';

interface DataFilterProps {
	name: string
	active: boolean
	visible: boolean
	children: React.ReactNode
	columnId: string
	shouldCheck: boolean
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const DataFilter = ({ name, active, children, visible, columnId, onChange, shouldCheck }: DataFilterProps) => {
	const [isChecked, setIsChecked] = React.useState(visible);
	const [isFilterVisible, setIsFilterVisible] = React.useState(false);

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if ((!shouldCheck && e.target.checked) || (shouldCheck && !e.target.checked) || (shouldCheck && e.target.checked)) {
			setIsChecked(e.target.checked);

			if (isChecked) {
				setIsFilterVisible(e.target.checked);
			}

			onChange?.(e);
		}
	}

	return (
		<DataFilterContainer>
			<FilterInfo>
				<UICheckBox
					icon={<Icon name="checkbox-un" size={24} />}
					checkedIcon={<Icon name="checkbox" size={24} />}
					checked={isChecked}
					onChange={handleFilterChange}
					id={columnId}
				/>
				<Typography>{name}</Typography>
				{/* {active && <Icon style={{ marginLeft: 8 }} name="filter-active" masked={true} color="primary" size={20} />} */}
				{active && (
					<FilterButton type="button" onClick={() => setIsFilterVisible(!isFilterVisible)}>
						<Icon style={{ marginLeft: 8 }} name="filter-active" masked={true} color="primary" size={20} />
					</FilterButton>
				)}
				{/* {(!active && visible) */}
				{(!active && visible)
					&& (
						<FilterButton type="button" onClick={() => setIsFilterVisible(!isFilterVisible)}>
							<Icon style={{ marginLeft: 8 }} name="filter-inactive" masked={true} color="greyOne" size={12} />
						</FilterButton>
					)}
			</FilterInfo>

			{isFilterVisible && (
				<FilterAction>
					{children}
				</FilterAction>
			)}
		</DataFilterContainer>
	)
}

export default DataFilter;