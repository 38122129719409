import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    // createBrowserRouter,
    // createRoutesFromElements,
    // RouterProvider,
} from 'react-router-dom';
import { shallowEqual } from 'react-redux'
import App from 'components/App';

import AuthRoutes from './AuthRoutes';
import PrivateRoutes from './PrivateRoutes';

import { useAppSelector } from 'store/hooks';

const AppRoutes = () => {
    const isAuthorized = useAppSelector(state => state.auth.isAuthorized, shallowEqual);

    // const router = createBrowserRouter(
    //     createRoutesFromElements(
    //         <Route element={<App />}>
    //             {isAuthorized ? (
    //                 <Route path='/*' element={<PrivateRoutes />} />
    //             ) : (
    //                 <>
    //                     <Route path='auth/*' element={<AuthRoutes />} />
    //                     <Route path='*' element={<Navigate to='/auth/signin' />} />
    //                 </>
    //             )}
    //         </Route>
    //     )
    // );

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<App />}>
                    {isAuthorized ? (
                        <Route path='/*' element={<PrivateRoutes />} />
                    ) : (
                        <>
                            <Route path='auth/*' element={<AuthRoutes />} />
                            <Route path='*' element={<Navigate to='/auth/signin' />} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )

    // return (
    //     <RouterProvider router={router} />
    // )
}

export default AppRoutes;