import * as React from 'react';
// import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UICard from 'components/UICard';
import Button from 'components/UIButton';
import {
    AuthContnetContainer,
    AuthHeading,
    AuthInfoHeading,
    AuthFormContainer,
    AuthForm,
    AuthActions,
    AuthLink
} from 'pages/SignIn/sigin.styled';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import { Input } from 'components/UIForm';
import SetPassword from './password';
import { signUpOrganization } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import SigupComplete from './complete';
import { SignUpInfo } from './signup.styled';

const signInSchema = Yup.object().shape({
    business_name: Yup.string()
        .required('Company name is required'),
    first_name: Yup.string()
        .required('First name is required'),
    last_name: Yup.string()
        .required('Last name is required'),
    email: Yup.string()
        .email('Wrong email format')
        .required('Email is required')
});

const SignUp = () => {
    const [isNext, setIsNext] = React.useState(false);
    const dispatch = useAppDispatch();
    const { signUpOrganizationStatus } = useAppSelector(state => state.auth);

    const formik = useFormik({
        initialValues: { business_name: '', first_name: '', last_name: '', email: '' },
        validationSchema: signInSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setIsNext(true);
        }
    });

    const handleSignUp = async (data: any) => {
        const { business_name, email, first_name, last_name } = formik.values;

        await dispatch(signUpOrganization({
            business_name: business_name,
            admin: {
                email: email,
                first_name: first_name,
                last_name: last_name,
                password: data.password
            }
        }));
    }

    return (
        <AuthContnetContainer>
            {!isNext ? (
                <>
                    <UICard padding="p4" style={{ display: 'flex', flexDirection: 'column', gap: 40, textAlign: 'center' }}>
                        <div>
                            <AuthHeading variant="h5">Sign Up</AuthHeading>
                            <AuthInfoHeading variant="h6">To continue to portal</AuthInfoHeading>
                        </div>

                        <AuthFormContainer>
                            <AuthForm id="sigup-form" onSubmit={formik.handleSubmit}>
                                <FormControl error={Boolean(formik.errors.business_name && formik.touched.business_name)}>
                                    <Input
                                        name="business_name"
                                        id="business_name"
                                        placeholder="Company Name"
                                        aria-describedby="business_name-error-text"
                                        value={formik.values.business_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.errors.business_name && formik.touched.business_name)}
                                    />
                                    {(formik.errors.business_name && formik.touched.business_name) && <FormHelperText id="business_name-error-text">{formik.errors.business_name}</FormHelperText>}
                                </FormControl>

                                <FormControl error={Boolean(formik.errors.first_name && formik.touched.first_name)}>
                                    <Input
                                        name="first_name"
                                        id="first_name"
                                        placeholder="First Name"
                                        aria-describedby="first_name-error-text"
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.errors.first_name && formik.touched.first_name)}
                                    />
                                    {(formik.errors.first_name && formik.touched.first_name) && <FormHelperText id="first_name-error-text">{formik.errors.first_name}</FormHelperText>}
                                </FormControl>

                                <FormControl error={Boolean(formik.errors.last_name && formik.touched.last_name)}>
                                    <Input
                                        name="last_name"
                                        id="last_name"
                                        placeholder="Last Name"
                                        aria-describedby="last_name-error-text"
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.errors.last_name && formik.touched.last_name)}
                                    />
                                    {(formik.errors.last_name && formik.touched.last_name) && <FormHelperText id="last_name-error-text">{formik.errors.last_name}</FormHelperText>}
                                </FormControl>

                                <FormControl error={Boolean(formik.errors.email && formik.touched.email)}>
                                    <Input
                                        name="email"
                                        id="email"
                                        placeholder="Work Email"
                                        aria-describedby="email-error-text"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.errors.email && formik.touched.email)}
                                    />
                                    {(formik.errors.email && formik.touched.email) && <FormHelperText id="email-error-text">{formik.errors.email}</FormHelperText>}
                                </FormControl>
                            </AuthForm>
                        </AuthFormContainer>

                        <AuthActions>
                            <AuthLink to="/auth/signin">Sign In Instead</AuthLink>
                            <Button type="submit" variant="contained" color="primary" form="sigup-form" disabled={formik.isSubmitting || !formik.isValid}>Get Started</Button>
                        </AuthActions>
                    </UICard>
                    
                    <SignUpInfo>
                        <Typography variant="h6">For existing organizations and admins</Typography>
                        <Typography variant="body1">If you would like to add another user to your organization, you should Invite another Admin to your organization, and not sign up using this form.</Typography>
                    </SignUpInfo>
                </>
            ) : (
                <>
                    {signUpOrganizationStatus === 'SUCCESS' ? (
                        <SigupComplete />
                    ) : (
                        <SetPassword handleSignUp={handleSignUp} />
                    )}
                </>
            )}
        </AuthContnetContainer>
    )
}

export default SignUp;