import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

export const ShipmentTableRow = styled(TableRow)`
  border: 2px solid #E7E7E7;
  
  & td, th {
      font-weight: 500;
      font-family: 'Cabin';
  }

  &:nth-of-type(even) {
      background-color:#F9F9F9
  }

  &:last-child td, 
  &:last-child th {
      border: 0,
  }
`;

export const ShipmentNameTableCell = styled(TableCell) <{ primary: number }>`
  color: ${props => props.primary ? props.theme.palette.primary.main : '#000'};
`;

export const ShipmentTableActions = styled('div')`
  display: flex;
  gap: 10px;
  margin-top: 16px;
  justify-content: flex-end;

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 700;

    &:disabled {
      cursor: not-allowed
    }
  }
`;

export const BulkActionCell = styled(TableCell)`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 10px;
`;

export const DataHeaderCell = styled(TableCell)`
  & > p, & > div {
    display: inline-block;
    vertical-align: middle;
  }
`;