import * as React from 'react';
import { Link, Outlet } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ContentContainer from 'components/ContentContainer';
import logoFull from 'assets/logo-full.svg';

const AuthLayouttStyled = styled('div')`
    display: flex;
    overflow: auto;
`;

export const AuthNav = styled('nav')`
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 28px;
    padding-bottom: 28px;
    border-bottom: 1px solid #E7E7E7;
`;

export const AuthLogo = styled('img')` 
    width: 180px;
`;

export const AuthNavHeading = styled(Typography)`
    margin-left: auto;
    font-family: 'Cabin';
    font-weight: 600;
`;

const AuthLayout = () => {
    return (
        <AuthLayouttStyled>
            <ContentContainer>
                <AuthNav>
                    <Link to="/">
                        <AuthLogo src={logoFull} alt="logo" />
                    </Link>
                    <AuthNavHeading variant="h5">Client Portal</AuthNavHeading>
                </AuthNav>
                <Outlet />
            </ContentContainer>
        </AuthLayouttStyled>
    )
}

export default AuthLayout;