import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const BackLink = styled(Link)`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    color: #808080;
    margin-bottom: 10px;
`;

export const InputContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 8px;
`;

export const OverPackDescription = styled('div')`
    margin-top: 24px;
    margin-bottom: 20px;

    & p:nth-of-type(1) {
        font-weight: 700;
    }
`;

const overPackInfomColors = {
    success: {
        bg: '#AEF3AC',
        border: '#3CB73A'
    },
    warning: {
        bg: '#FFE492',
        border: '#F9CA26'
    },
    error: {
        bg: '#FF5555',
        border: '#CB0000'
    },
    pending: {
        bg: '#F9F9F9',
        border: '#E7E7E7'
    }
}

export const OverPackInfo = styled('div') <{ status: 'success' | 'warning' | 'error' | 'pending' }>`
    position: relative;
    text-align: center;
    padding: 48px 32px;

    background: ${props => overPackInfomColors[props.status].bg};
    border: 2px solid ${props => overPackInfomColors[props.status].border};

    & > p {
        font-size: 24px;
    }
`;

export const OverPackMeta = styled('div')`
    position: absolute;
    right: 8px;
    top: 8px;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 5px;
    padding: 4px 16px;

    & > p {
        font-family: 'SF Mono';
    }
`

export const OverPackListContainer = styled('div')`
    display: flex;
    flex-direction: column-reverse;
    /* justify-content: first baseline; */
    background: #F9F9F9;
    border: 2px solid #E7E7E7;
    padding: 16px;
    margin-top:  16px;
    height: 256px;
    overflow: auto;

    & .fix {
        flex: 1 1 auto;
    }
`;

const trackingItemColors = {
    success: '#3CB73A',
    warning: '#EFCC52',
    error: '#FF5555',
    pending: '#000'
}

export const TrackingItem = styled('div') <{ status: 'success' | 'warning' | 'error' | 'pending' }>`
    color: ${props => trackingItemColors[props.status] || '#000'};

    & > p {
        font-family: 'SF Mono';
    }
`;