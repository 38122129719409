import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const SettingsCard = styled('div')`
  padding: 40px;
  
  &:not(:last-child) {
    border-bottom: 1px solid #E7E7E7;
  }
`;

export const SettingsDesc = styled(Typography)`
  color: ${props => props.theme.palette.greyOne.main};
`;

export const SettingBottomCard = styled('div') <{ direction?: string, gap?: number }>`
  display: flex;
  align-items: center;
  margin-top: 40px;
  flex-direction: ${(props) => props.direction === 'column' ? 'column' : 'inline'};
  align-items: flex-start;
  gap: ${props => props.gap || 0}px;
`;

export const SettingsHeading = styled(Typography)`
  color: #000;
  margin-bottom: 32px;

  /* &:not(:first-child) { */
  margin-top: 32px;
  /* } */
`;

export const LogoUploadContainer = styled('div')`
  display: flex;
  align-items: center;
  /* flex: 1; */

  button {
    margin-right: 30px;
  }
`;
export const LogoLoadedContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const LogoLoadedActions = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* justify-content: space-around; */

  /* button:not(:last-child) {
    margin-bottom: 10px;
  } */
`;

export const LogoLoadedDisplay = styled('div')`
  border: 2px solid #000000;
  width: 300px;
  height: 100px;
  margin-left: 16px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const DomainVerificationContainer = styled('div')``;

export const DomainAction = styled('div')`
  display: flex;
  align-items: center;

  & > button {
    margin-left: 24px;
  }
`;

export const DomainActionName = styled('div')`
  display: flex;
  align-items: center;

  & > p {
    margin-left: 8px;
  }
`;

export const DomainDNSInfo = styled('div')`
  margin-top: 30px;
`;

export const DomainSubList = styled('div')`
  margin-top: 22px;

  table {
    td {
      border: 0;
      padding: 0px;
      padding-bottom: 18px;
    }
  }
`;

export const DomainSubItem = styled('div')`
  display: flex;
  align-items: center;
  width: 650px;

  &:not(:last-child) {
    margin-bottom: 18px;
  }
`;

export const DomainSubItemInfo = styled('div')`
  display: flex;
  align-items: center;
  /* margin-left: 105px; */
  /* margin-left: auto; */

  & > p {
    margin-left: 13px;
  }

  & > a {
    color: ${props => props.theme.palette.greyOne.main};
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    margin-left: 32px;
  }
`;

export const DomainModalHeading = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
`;

export const DomainDetailModalHeading = styled(Typography)`
  font-weight: 700;
  margin-top: 26px;
`;

export const DomainUpdateForm = styled('form')`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  & > div {
    width: 100%;
  }
`;

export const DomainUpdateFormActions = styled('div')`
  display: flex;
  margin-top: 16px;
`;

export const DomainUpdateWarning = styled('div')`
  display: flex;
  align-items: center;
  padding: 10px 8px;
  border: 1px solid #E7E7E7;
  border-radius: 8px;
  margin-top: 16px;

  & > p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #808080;
    margin-left: 10px;
  }
`;

export const ConfigView = styled('button')`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;

  & > p {
    margin-left: 8px;
  }
`;

export const ResetInfo = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 24px;

  & > p {
    font-style: italic;
    color: ${props => props.theme.palette.greyOne.main};
    margin-left: 8px;
  }
`;