import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BulkShipmentStatus } from 'store/customers/types';

export const ShipmentStatus = styled(Typography) <{ status: BulkShipmentStatus }>`
  color: ${props => {
        switch (props.status) {
            case "failure":
                return '#F9CA26'
            case "partial_success":
                return props.theme.palette.success.main
            case "success":
                return props.theme.palette.success.main
            default:
                return '#000'
        }
    }};
`;