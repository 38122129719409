import * as React from 'react';
import {
    // Navigate,
    useNavigate,
    useSearchParams
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch } from 'store/hooks';
import { verifyUser } from 'store/auth';

const getSearchParams = (params: URLSearchParams) => Object.fromEntries(params);

const Verify = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    // const { verifyUserStatus } = useAppSelector(state => state.auth);

    const handleVerfiy = React.useCallback(() => {
        const params = getSearchParams(searchParams);

        if (params.code) {
            return dispatch(verifyUser({
                code: params.code
            }));
        }
    }, [searchParams, dispatch]);

    React.useEffect(() => {
        const promise = handleVerfiy();
        promise?.then(() => navigate('/auth/signin'))

        // return () => {
        //     promise?.abort();
        // }
    }, [handleVerfiy, dispatch, navigate])

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CircularProgress color="black" size={24} thickness={6} />
            {/* {(verifyUserStatus === 'SUCCESS' || verifyUserStatus === 'ERROR') ? (
                <Navigate to='/auth/signin' />
            ): null} */}
        </div>
    )
}

export default Verify;