import * as React from "react";
import {
	CellProps,
	Column,
	HeaderProps,
	useRowSelect,
	useTable
} from "react-table";
import { Modal, FormControl, FormHelperText, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress } from "@mui/material";
import { ShipmentListSingle } from "store/customers/types";
import { ShipmentTableRow, ShipmentTableActions, DataHeaderCell } from 'styles/table.styled';
import { ShipmentCheckBox } from 'styles/checkbox.styled';
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useNavigate, Link } from "react-router-dom";
import { downloder, withAsync } from "utils";
import { getShipmentLabel } from "api/customers";
import { getCustomerShipmentsById } from "store/customers";
import Icon from "components/Icon";
import { NoShipmentIconContainer, ShipmentView } from "./shipments.styled";
import { ShipmentActionsSelect, ShipmentActionsItem } from 'styles/select.styled';
import { ShipmentFilters } from "./shipments.types";
import DataFilter from 'components/DataFilter';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { default as countriesList } from 'countries.json';
import InputAdornment from '@mui/material/InputAdornment';
import UIButton from "components/UIButton";
import { Input, UISelect, UIDatePicker } from 'components/UIForm';
import { ModalBody } from 'components/UIModal';
import { ConfirmModalHeading } from 'styles/modal.styled';
import { Dayjs } from 'dayjs';

const getDate = (date: string) => {
	const d = new Date(date);

	return `0${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
}

const getTime = (date: string) => {
	const d = new Date(date);

	return d.toLocaleTimeString()
}

const filterSchema = Yup.object().shape({
	id: Yup.string(),
	comment_1: Yup.string(),
	comment_2: Yup.string(),
	comment_3: Yup.string(),
	consignee_name: Yup.string(),
	weight: Yup.number(),
	status: Yup.string(),
	dest_country: Yup.string(),
	entry_point: Yup.string(),
	tracking_number: Yup.string(),
	service: Yup.string(),
	created_after: Yup.date(),
	created_before: Yup.date()
});

interface Props {
	customerId: string | undefined
	setCurrentShipment: (shipment: any) => any
	setOpenConfirm: (isActive: boolean) => any
	setOpenFilters: (isActive: boolean) => any
	setFilterList: (list: ShipmentFilters[]) => any
	handleCloseFilters: () => void
	onRowSelectStateChange: (ids: any) => void
	filterList: ShipmentFilters[]
	isTriggerHideColumn: boolean
	openFilters: boolean
}

const IndeterminateCheckbox = React.forwardRef<any, any>(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef<any>()
		const resolvedRef = ref || defaultRef

		React.useEffect(() => {
			if ((resolvedRef as any).current) {
				(resolvedRef as any).current.indeterminate = indeterminate
			}
		}, [resolvedRef, indeterminate])

		return (
			<>
				<ShipmentCheckBox
					icon={<Icon name="checkbox-un" size={30} />}
					checkedIcon={<Icon name="checkbox" size={30} />}
					color="success"
					ref={resolvedRef}
					{...rest}
				/>
			</>
		)
	}
)

const ShipmentTable: React.FC<Props> = (props) => {
	const {
		setCurrentShipment,
		setOpenConfirm,
		filterList,
		customerId,
		isTriggerHideColumn,
		openFilters,
		handleCloseFilters,
		setFilterList,
		setOpenFilters,
		onRowSelectStateChange
	} = props;
	const { shipmentsList, shipmentsListNext, shipmentsListPrevious, getCustomerShipmentsByIdStatus } = useAppSelector(({ customers }) => customers);
	const [openShipmentActions, setOpenShipmentActions] = React.useState('');
	const [hiddenColumns, setHiddenColumns] = React.useState(['_selector', 'entry_point', 'tracking_number', 'date']);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleGetLabel = React.useCallback(async (id: string) => {
		const { response } = await withAsync(() => getShipmentLabel(id));

		if (response) {
			downloder.download(response.data, 'application/pdf', `${id}`);
		}
	}, [])

	const handleConfirmCancelLabel = React.useCallback((id: string) => {
		const shipment = shipmentsList.find(ship => ship.id === id);

		if (shipment) {
			setCurrentShipment(shipment);
			setOpenConfirm(true);
		}
	}, [shipmentsList, setCurrentShipment, setOpenConfirm])

	const shipmentColumns: Array<Column<ShipmentListSingle> & { filterkey?: string | string[] }> = React.useMemo(
		() => [
			{
				Header: "ID",
				accessor: "id",
				id: 'id',
				filterkey: 'id',
				Cell: ({ row }) => (
					<ShipmentView>
						<Icon name="eye" size={16} masked={true} color="primary" />
						<Link to={`/customers/shipments/${row.id}`}>
							{row.id}
						</Link>
					</ShipmentView>
				)
			},
			{
				Header: "Comments",
				accessor: (originalRow) => originalRow.comments.join(', '),
				id: 'comments',
				filterkey: ['comment_1', 'comment_2', 'comment_3']
			},
			{
				Header: "Consignee Name",
				accessor: (originalRow) => originalRow.consignee.name,
				id: 'consignee_name',
				filterkey: "consignee_name"
			},
			{
				Header: "Status",
				accessor: "status",
				id: 'status',
				filterkey: "status"
			},
			{
				Header: "Weight (kg)",
				accessor: "weight",
				id: 'weight',
				filterkey: "weight"
			},
			{
				Header: "Dest. Country",
				accessor: (originalRow) => originalRow.to_address.country,
				id: 'dest_country',
				filterkey: "dest_country"
			},
			{
				Header: "Service",
				accessor: "service",
				id: 'service',
				filterkey: "service"
			},
			{
				Header: "Tracking Number",
				accessor: "tracking_number",
				id: 'tracking_number',
				filterkey: "tracking_number"
			},
			{
				Header: "Created Date",
				// accessor: (originalRow) => originalRow.created,
				accessor: (originalRow) => `${getDate(originalRow.created)} ${getTime(originalRow.created)}`,
				id: 'date',
				filterkey: ['created_after', 'created_before']
			},
			{
				Header: "Entry Point",
				accessor: "entry_point",
				id: 'entry_point',
				filterkey: "entry_point"
			},
			{
				Header: "",
				accessor: "test",
				Cell: ({ row }) => (
					<FormControl style={{ width: 28, height: 28 }} size="small">
						<ShipmentActionsSelect
							labelId={"ship-select-small-" + row.id}
							id={"ship-select-small-" + row.id}
							open={openShipmentActions === row.id}
							onClick={() => setOpenShipmentActions(openShipmentActions ? '' : row.id)}
						>
							<ShipmentActionsItem onClick={() => navigate(`/customers/shipments/${row.id}`)}>
								<Icon name="eye" size={16} masked={true} color="black" />
								<Typography>View Details</Typography>
							</ShipmentActionsItem>
							<ShipmentActionsItem onClick={() => handleConfirmCancelLabel(row.id)}>
								<Icon name="cancel" size={16} masked={true} color="black" />
								<Typography>Cancel Shipment</Typography>
							</ShipmentActionsItem>
							<ShipmentActionsItem onClick={() => handleGetLabel(row.original.tracking_number)} disabled={!row.original.tracking_number}>
								<Icon name="download-full" size={16} masked={true} color="black" />
								<Typography>Download Label</Typography>
							</ShipmentActionsItem>
						</ShipmentActionsSelect>
					</FormControl>
				)
			}
		],
		[openShipmentActions, handleConfirmCancelLabel, handleGetLabel, navigate]
	);

	const handleShipmentsPagination = (cursor: string | null) => {
		if (cursor && customerId) {
			dispatch(getCustomerShipmentsById({ customerId, cursor }));
		}
	}

	const {
		getTableProps,
		headerGroups,
		getTableBodyProps,
		toggleHideColumn,
		rows,
		prepareRow,
		state: { selectedRowIds }
	} = useTable(
		{
			initialState: { hiddenColumns },
			data: shipmentsList,
			columns: shipmentColumns,
			getRowId: (row) => (row as any).id
		},
		useRowSelect,
		hook => {
			hook.allColumns.push(columns => [
				{
					id: '_selector',
					Header: (row: HeaderProps<any>) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleAllRowsSelectedProps()} />
						</div>
					),
					Cell: ({ row }: CellProps<any>) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					)
				},
				...columns
			]);
		});

	const handleFilterCheck = (e: React.ChangeEvent<HTMLInputElement>, key: string | string[]) => {
		const id = e.currentTarget.id;

		if (Array.isArray(key)) {
			if (!e.target.checked) {
				formik.setValues(key.reduce((a, v) => ({ ...a, [v]: '' }), {}) as any)
			}
		} else {
			if (!e.target.checked) {
				formik.setFieldValue(key, '')
			}
		}

		if (hiddenColumns.includes(id)) {
			setHiddenColumns(hiddenColumns.filter(cl => cl !== id))
		} else if (!hiddenColumns.includes(id)) {
			setHiddenColumns([...hiddenColumns, id])
		}

		toggleHideColumn(id);
	}

	const formik = useFormik({
		initialValues: {
			id: '',
			comment_1: '',
			comment_2: '',
			comment_3: '',
			consignee_name: '',
			weight: '',
			status: '',
			dest_country: '',
			entry_point: '',
			tracking_number: '',
			service: '',
			created_before: '',
			created_after: ''
		},
		validationSchema: filterSchema,
		onSubmit: async (values, { setStatus, setErrors, setSubmitting }) => {
			const val = Object.entries(values).filter(([_, v]) => v !== '');
			const fil = val.map(el => Object.fromEntries([el]));
			// console.log('values: ', values);

			const updateVal: any = { ...Object.fromEntries(val) }

			if (updateVal.created_after && updateVal.created_before) {
				updateVal['created_after'] = (updateVal.created_after as Dayjs).subtract(19, 'hour').toDate();
				updateVal['created_before'] = (updateVal.created_before as Dayjs).subtract(19, 'hour').add(23, 'hour').toDate();
			}

			if (customerId) {
				dispatch(getCustomerShipmentsById({ customerId, ...updateVal }))
					.then(() => {
						setFilterList(fil);
						setOpenFilters(false);
					});
			}
		}
	});

	React.useEffect(() => {
		toggleHideColumn('_selector')
		toggleHideColumn('test')
	}, [isTriggerHideColumn, toggleHideColumn])

	React.useEffect(() => {
		if (selectedRowIds) {
			onRowSelectStateChange(selectedRowIds);
		}
	}, [selectedRowIds, onRowSelectStateChange])

	return (
		<>
			{getCustomerShipmentsByIdStatus === 'PENDING' ? (<div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
				<CircularProgress color="black" size={24} thickness={6} />
			</div>) : (
				<>
					{shipmentsList.length > 0 ? (
						<>
							<TableContainer>
								<Table sx={{ minWidth: 650 }} aria-label="simple table" {...getTableProps()}>
									<TableHead>
										{headerGroups.map(headerGroup => (
											<TableRow {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map(column => (
													<DataHeaderCell {...column.getHeaderProps()}>
														<Typography>{column.render('Header')}</Typography>
														{(!Array.isArray((column as any).filterkey) ?
															filterList.some(fl => fl[(column as any).filterkey]) :
															filterList.some(fl => Object.keys(fl).some(st => (column as any).filterkey.includes(st)))) &&
															<Icon style={{ marginLeft: 8 }} name="filter-active" masked={true} color="primary" size={20} />}
													</DataHeaderCell>
												))}
											</TableRow>
										))}
									</TableHead>
									<TableBody {...getTableBodyProps()}>
										{rows.map((row, i) => {
											prepareRow(row);

											return (
												<ShipmentTableRow {...row.getRowProps()}>
													{row.cells.map(cell => (
														<TableCell component="th" scope="row" {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
													))}
												</ShipmentTableRow>
											)
										})}
									</TableBody>
								</Table>

								<ShipmentTableActions>
									<button onClick={() => handleShipmentsPagination(shipmentsListPrevious)} disabled={!Boolean(shipmentsListPrevious)}>
										<Typography variant="body1">{'<'} Prev</Typography>
									</button>
									<button onClick={() => handleShipmentsPagination(shipmentsListNext)} disabled={!Boolean(shipmentsListNext)}>
										<Typography variant="body1">Next {'>'}</Typography>
									</button>
								</ShipmentTableActions>
							</TableContainer>
						</>
					) : (
						<NoShipmentIconContainer>
							<Icon name="no-shipment" size={300} />
						</NoShipmentIconContainer>
					)}
				</>
			)}

			<Modal
				open={openFilters}
				onClose={handleCloseFilters}
				style={{ overflow: 'auto' }}
				aria-labelledby="filters-ship-modal-title"
				aria-describedby="filters-ship-modal-description"
				keepMounted
			>
				<ModalBody>
					<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
						<Icon name="filters-fill" color="black" masked={true} size={24} />
						<ConfirmModalHeading variant="h2">
							Columns and Filters
						</ConfirmModalHeading>
					</div>

					<div style={{ marginTop: 34 }}>
						<form id="filter-form" onSubmit={formik.handleSubmit}>
							{/* {shipmentColumns.filter(col => col.filterkey).map(col => {
						if (Array.isArray(col.filterkey)) {
							return (
								<div></div>
							)
						} else {
							return (
								<DataFilter
									columnId={col.id || ''}
									name={col.Header as string}
									visible={!hiddenColumns.includes(col.id || '')}
									active={filterList.some(fl => fl[col.id || ''])}
									onChange={e => handleFilterCheck(e, col.id || '')}
								>
									<FormControl error={Boolean(formik.errors[col.id || ''] && formik.touched[col.id || ''])}>
										<Input
											name={col.id || ''}
											id={col.id || ''}
											placeholder={col.Header as string}
											value={formik.values[col.id || '']}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										/>
										{(formik.errors[col.id || ''] && formik.touched[col.id || '']) && <FormHelperText id="id-error-text">{formik.errors[col.id || '']}</FormHelperText>}
									</FormControl>
								</DataFilter>
							)
						}
					})} */}

							<DataFilter
								columnId="id"
								name="ID"
								shouldCheck={hiddenColumns.length < (shipmentColumns.length - 1)}
								visible={!hiddenColumns.includes('id')}
								active={filterList.some(fl => fl['id'])}
								onChange={e => handleFilterCheck(e, 'id')}
							>
								<FormControl error={Boolean(formik.errors.id && formik.touched.id)}>
									<Input
										name="id"
										id="id"
										placeholder="ID"
										value={formik.values.id}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{(formik.errors.id && formik.touched.id) && <FormHelperText id="id-error-text">{formik.errors.id}</FormHelperText>}
								</FormControl>
							</DataFilter>

							<DataFilter
								columnId="comments"
								name="Comments"
								shouldCheck={hiddenColumns.length < (shipmentColumns.length - 1)}
								visible={!hiddenColumns.includes('comments')}
								active={filterList.some(fl => fl['comment_1'] || fl['comment_2'] || fl['comment_3'])}
								onChange={e => handleFilterCheck(e, ['comment_1', 'comment_2', 'comment_3'])}
							>
								<FormControl error={Boolean(formik.errors.comment_1 && formik.touched.comment_1)}>
									<Input
										name="comment_1"
										id="comment_1"
										placeholder="Comment 1"
										value={formik.values.comment_1}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{(formik.errors.comment_1 && formik.touched.comment_1) && <FormHelperText id="comment_1-error-text">{formik.errors.comment_1}</FormHelperText>}
								</FormControl>

								<FormControl error={Boolean(formik.errors.comment_2 && formik.touched.comment_2)}>
									<Input
										name="comment_2"
										id="comment_2"
										placeholder="Comment 2"
										value={formik.values.comment_2}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{(formik.errors.comment_2 && formik.touched.comment_2) && <FormHelperText id="comment_2-error-text">{formik.errors.comment_2}</FormHelperText>}
								</FormControl>

								<FormControl error={Boolean(formik.errors.comment_3 && formik.touched.comment_3)}>
									<Input
										name="comment_3"
										id="comment_3"
										placeholder="Comment 3"
										value={formik.values.comment_3}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{(formik.errors.comment_3 && formik.touched.comment_3) && <FormHelperText id="comment_3-error-text">{formik.errors.comment_3}</FormHelperText>}
								</FormControl>
							</DataFilter>

							<DataFilter
								columnId="consignee_name"
								name="Consignee Name"
								shouldCheck={hiddenColumns.length < (shipmentColumns.length - 1)}
								visible={!hiddenColumns.includes('consignee_name')}
								active={filterList.some(fl => fl['consignee_name'])}
								onChange={e => handleFilterCheck(e, 'consignee_name')}
							>
								<FormControl error={Boolean(formik.errors.consignee_name && formik.touched.consignee_name)}>
									<Input
										name="consignee_name"
										id="consignee_name"
										placeholder="Consignee Name"
										value={formik.values.consignee_name}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{(formik.errors.consignee_name && formik.touched.consignee_name) && <FormHelperText id="consignee_name-error-text">{formik.errors.consignee_name}</FormHelperText>}
								</FormControl>
							</DataFilter>

							<DataFilter
								columnId="status"
								name="Status"
								shouldCheck={hiddenColumns.length < (shipmentColumns.length - 1)}
								visible={!hiddenColumns.includes('status')}
								active={filterList.some(fl => fl['status'])}
								onChange={e => handleFilterCheck(e, 'status')}
							>
								<FormControl error={Boolean(formik.errors.status && formik.touched.status)}>
									<UISelect
										labelId="status-filter-label"
										id="status"
										name="status"
										value={formik.values.status}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={Boolean(formik.errors.status && formik.touched.status)}
										style={{ textTransform: 'capitalize' }}
										displayEmpty
									>
										<MenuItem value="" disabled>Select Status</MenuItem>
										{["created", "pending", "cancelled"].map(sts => (
											<MenuItem style={{ textTransform: 'capitalize' }} key={sts} value={sts}>{sts}</MenuItem>
										))}
									</UISelect>
									{(formik.errors.status && formik.touched.status) && <FormHelperText id="primary-error-text">{formik.errors.status}</FormHelperText>}
								</FormControl>
							</DataFilter>

							<DataFilter
								columnId="weight"
								name="Weight (kg)"
								shouldCheck={hiddenColumns.length < (shipmentColumns.length - 1)}
								visible={!hiddenColumns.includes('weight')}
								active={filterList.some(fl => fl['weight'])}
								onChange={e => handleFilterCheck(e, 'weight')}
							>
								<FormControl error={Boolean(formik.errors.weight && formik.touched.weight)}>
									<Input
										type="number"
										name="weight"
										id="weight"
										placeholder="weight"
										value={formik.values.weight}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										InputProps={{
											endAdornment: (
												<InputAdornment position="start">kg</InputAdornment>
											)
										}}
									/>
									{(formik.errors.weight && formik.touched.weight) && <FormHelperText id="weight-error-text">{formik.errors.weight}</FormHelperText>}
								</FormControl>
							</DataFilter>

							<DataFilter
								columnId="dest_country"
								name="Dest. Country"
								shouldCheck={hiddenColumns.length < (shipmentColumns.length - 1)}
								visible={!hiddenColumns.includes('dest_country')}
								active={filterList.some(fl => fl['dest_country'])}
								onChange={e => handleFilterCheck(e, 'dest_country')}
							>
								<FormControl error={Boolean(formik.errors.dest_country && formik.touched.dest_country)}>
									<UISelect
										labelId="country-filter-label"
										id="dest_country"
										name="dest_country"
										value={formik.values.dest_country}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={Boolean(formik.errors.dest_country && formik.touched.dest_country)}
										displayEmpty
									>
										<MenuItem value="" disabled>Select Country</MenuItem>
										{countriesList.map(co => (
											<MenuItem key={co.code} value={co.code}>{co.name}</MenuItem>
										))}
									</UISelect>
									{(formik.errors.dest_country && formik.touched.dest_country) && <FormHelperText id="dest_country-error-text">{formik.errors.dest_country}</FormHelperText>}
								</FormControl>
							</DataFilter>

							<DataFilter
								columnId="service"
								name="Service Name"
								shouldCheck={hiddenColumns.length < (shipmentColumns.length - 1)}
								visible={!hiddenColumns.includes('service')}
								active={filterList.some(fl => fl['service'])}
								onChange={e => handleFilterCheck(e, 'service')}
							>
								<FormControl error={Boolean(formik.errors.service && formik.touched.service)}>
									<Input
										name="service"
										id="service"
										placeholder="Service Name"
										value={formik.values.service}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{(formik.errors.service && formik.touched.service) && <FormHelperText id="service-error-text">{formik.errors.service}</FormHelperText>}
								</FormControl>
							</DataFilter>

							<DataFilter
								columnId="entry_point"
								name="Entry Point"
								shouldCheck={hiddenColumns.length < (shipmentColumns.length - 1)}
								visible={!hiddenColumns.includes('entry_point')}
								active={filterList.some(fl => fl['entry_point'])}
								onChange={e => handleFilterCheck(e, 'entry_point')}
							>
								<FormControl error={Boolean(formik.errors.entry_point && formik.touched.entry_point)}>
									<Input
										name="entry_point"
										id="entry_point"
										placeholder="Entry Point"
										value={formik.values.entry_point}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{(formik.errors.entry_point && formik.touched.entry_point) && <FormHelperText id="entry_point-error-text">{formik.errors.entry_point}</FormHelperText>}
								</FormControl>
							</DataFilter>

							<DataFilter
								columnId="tracking_number"
								name="Tracking Number"
								shouldCheck={hiddenColumns.length < (shipmentColumns.length - 1)}
								visible={!hiddenColumns.includes('tracking_number')}
								active={filterList.some(fl => fl['tracking_number'])}
								onChange={e => handleFilterCheck(e, 'tracking_number')}
							>
								<FormControl error={Boolean(formik.errors.tracking_number && formik.touched.tracking_number)}>
									<Input
										name="tracking_number"
										id="tracking_number"
										placeholder="Tracking Number"
										value={formik.values.tracking_number}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{(formik.errors.tracking_number && formik.touched.tracking_number) && <FormHelperText id="tracking_number-error-text">{formik.errors.tracking_number}</FormHelperText>}
								</FormControl>
							</DataFilter>

							<DataFilter
								columnId="date"
								name="Created Date"
								shouldCheck={hiddenColumns.length < (shipmentColumns.length - 1)}
								visible={!hiddenColumns.includes('date')}
								active={filterList.some(fl => fl['created_before'] || fl['created_after'])}
								onChange={e => handleFilterCheck(e, ['created_before', 'created_after'])}
							>
								<FormControl error={Boolean(formik.errors.created_after && formik.touched.created_after)}>
									<Typography style={{ marginBottom: 8 }} variant="body1">Start Date</Typography>
									<UIDatePicker
										// type="date"
										// name="created_after"
										// id="created_after"
										// placeholder="Comment 2"
										// value={formik.values.created_after}
										// onChange={formik.handleChange}
										// onBlur={formik.handleBlur}'
										InputProps={{
											error: Boolean(formik.errors.created_after && formik.touched.created_after),
										}}
										value={formik.values.created_after}
										onChange={(value) => formik.setFieldValue("created_after", value, true)}
										renderInput={(params => (
											<Input
												// onBlur={(value) => formik.setFieldValue("created_after", value, true)}
												id="created_after"
												name="created_after"
												{...params}
											/>
										))}
									/>
									{(formik.errors.created_after && formik.touched.created_after) && <FormHelperText id="created_after-error-text">{formik.errors.created_after}</FormHelperText>}
								</FormControl>

								<FormControl error={Boolean(formik.errors.created_before && formik.touched.created_before)}>
									<Typography style={{ marginBottom: 8 }} variant="body1">End Date</Typography>
									<UIDatePicker
										// type="date"
										// name="created_before"
										// id="created_before"
										// placeholder="Comment 1"
										// value={formik.values.created_before}
										// onChange={formik.handleChange}
										// onBlur={formik.handleBlur}
										InputProps={{
											error: Boolean(formik.errors.created_before && formik.touched.created_before),
										}}
										value={formik.values.created_before}
										onChange={(value) => formik.setFieldValue("created_before", value, true)}
										renderInput={(params => (
											<Input
												id="created_before"
												name="created_before"
												{...params}
											/>
										))}
									/>
									{(formik.errors.created_before && formik.touched.created_before) && <FormHelperText id="created_before-error-text">{formik.errors.created_before}</FormHelperText>}
								</FormControl>
							</DataFilter>
						</form>
					</div>

					<div style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-end', gap: 16 }}>
						<UIButton type="button" variant="outlined" onClick={handleCloseFilters}>Cancel</UIButton>
						<UIButton type="submit" form="filter-form" color="primary">Apply</UIButton>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default ShipmentTable;