import * as React from "react";
import { PageHeading } from "components/Heading";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import bgOne from "assets/images/link-bg-1.png";
import Icon from "components/Icon";
import CardLink from "components/CardLink";
import bgFour from 'assets/images/link-bg-4.png';
import { useAppSelector } from "store/hooks";

const ComingMore = styled("div")`
  display: flex;
  align-items: center;
  border: 1px solid #e7e7e7;
  border-radius: 14px;
  padding: 21px;
  margin-top: 50px;

  & > p {
    margin-left: 16px;
  }
`;

const CardLinks = styled("div")`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export default function Home() {
  const { organization } = useAppSelector(
    (state) => state.auth
  );

  return (
    <>
      <PageHeading variant="h4">Home</PageHeading>

      <CardLinks>
        <CardLink
          title="Organization Settings"
          bgURL={bgOne}
          to="/settings"
          style={{ height: 240 }}
        />

        {organization?.overlabeling_enabled && (
          <CardLink
            title="Start Overlabeling"
            bgURL={bgFour}
            to="/wms/overlabeling"
            style={{ height: 240 }}
          />

        )}
      </CardLinks>

      <ComingMore>
        <Icon name="success" size={24} />
        <Typography variant="body1">
          <b>You’re all caught up!</b> That’s it for now.{" "}
        </Typography>
      </ComingMore>
    </>
  );
}
