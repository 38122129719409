import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit';
import { inviteUser, getOrganizationAdmins, statusUpdateAdmin } from 'api/users';
import { UsersState } from './type';

const initialState: UsersState = {
    usersList: [],
    inviteUserStatus: 'IDLE',
    getAdminsStatus: 'IDLE',
    removeAdminStatus: 'IDLE'
}

export const inviteUserToTeam = createAsyncThunk('users/inviteUser', inviteUser);
export const getAdmins = createAsyncThunk('users/getOrganizationAdmins', getOrganizationAdmins);
export const removeAdmin = createAsyncThunk('users/statusUpdateAdmin', statusUpdateAdmin);

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        resetInviteUserStatus: (state) => {
            state.inviteUserStatus = 'IDLE';
        }
    },
    extraReducers(builder) {
        builder.addCase(inviteUserToTeam.pending, (state, action) => {
            state.inviteUserStatus = 'PENDING';
        });
        builder.addCase(inviteUserToTeam.fulfilled, (state, action) => {
            state.inviteUserStatus = 'SUCCESS';
        });
        builder.addCase(inviteUserToTeam.rejected, (state, action) => {
            state.inviteUserStatus = 'ERROR';
        });
        builder.addCase(getAdmins.pending, (state, action) => {
            state.getAdminsStatus = 'PENDING';
        });
        builder.addCase(getAdmins.fulfilled, (state, action) => {
            state.usersList = action.payload;
            state.getAdminsStatus = 'SUCCESS';
        });
        builder.addCase(getAdmins.rejected, (state, action) => {
            state.getAdminsStatus = 'ERROR';
        });
        builder.addCase(removeAdmin.pending, (state, action) => {
            state.removeAdminStatus = 'PENDING';
        });
        builder.addCase(removeAdmin.fulfilled, (state, action) => {
            state.usersList = state.usersList.map(user => {
                if (user.id === action.payload.id) {
                    user.is_active = action.payload.is_active
                }

                return user;
            })
            state.removeAdminStatus = 'SUCCESS';
        });
        builder.addCase(removeAdmin.rejected, (state, action) => {
            state.removeAdminStatus = 'ERROR';
        });
    },
});

export const { resetInviteUserStatus } = usersSlice.actions;
export default usersSlice.reducer;