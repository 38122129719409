import * as React from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import UICard from "components/UICard";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Input, UISelect } from "components/UIForm";
import UIButton from "components/UIButton";
import {
  getCustomerById,
  updateCustomerById,
  updateCustomerLimitById,
} from "store/customers";
import { default as countriesList } from "countries.json";
import { DetailsContainer, DetailsForm } from "./customer-view.styled";
import HeadingCrumb from "components/HeadingCrumb";
import Grid from "@mui/material/Grid";
import CardLink from "components/CardLink";
import bgThree from "assets/images/link-bg-3.png";
import { CustomerHeading, DetailHeading } from "styles/headings.styled";

const brandingSchema = Yup.object().shape({
  name: Yup.string(),
  // .required('Company Name is required'),
  email: Yup.string(),
  // .required('Billing Email Address is required'),
  line_1: Yup.string().required("Address Line 1 is required"),
  line_2: Yup.string(),
  // .required('Address Line 2 is required'),
  postal_code: Yup.string(),
  // .required('Postal Code is required'),
  city: Yup.string().required("City is required"),
  region: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
});

const limitSchema = Yup.object().shape({
  usage_limit_type: Yup.string(),
  limited_to: Yup.number().when("usage_limit_type", {
    is: (usage_limit_type: string) => {
      if (["us_dollars", "shipment"].includes(usage_limit_type)) {
        return true;
      }
    },
    then: Yup.number().required("Limit is required"),
  }),
});

const CustomerView = () => {
  const { customerId } = useParams();
  const dispatch = useAppDispatch();
  const { currentCustomer, updateCustomerByIdStatus } = useAppSelector(
    (state) => state.customers
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentCustomer?.company_name || "",
      email: currentCustomer?.billing_address?.email || "",
      line_1: currentCustomer?.billing_address?.line_1 || "",
      line_2: currentCustomer?.billing_address?.line_2 || "",
      postal_code: currentCustomer?.billing_address?.postal_code || "",
      city: currentCustomer?.billing_address?.city || "",
      region: currentCustomer?.billing_address?.region || "",
      country: currentCustomer?.billing_address?.country || "",
      // usage_limit_type: currentCustomer?.usage_limit_type || "null",
      // limited_to: currentCustomer?.limited_to || "",
    },
    validationSchema: brandingSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const { name, ...rest } = values;

      if (customerId) {
        dispatch(
          updateCustomerById({
            customer_id: customerId,
            data: {
              company_name: values.name,
              // limited_to: limited_to,
              // usage_limit_type:
              //   usage_limit_type === "null" ? null : usage_limit_type,
              billing_address: { ...rest },
            },
          })
        );
      }
    },
  });

  const limitFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      usage_limit_type: currentCustomer?.usage_limit_type || "null",
      limited_to: currentCustomer?.limited_to || "",
    },
    validationSchema: limitSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const { usage_limit_type, limited_to } = values;

      if (customerId) {
        dispatch(
          updateCustomerLimitById({
            customer_id: customerId,
            data: {
              limited_to: usage_limit_type === "null" ? null : limited_to,
              usage_limit_type:
                usage_limit_type === "null" ? null : usage_limit_type,
            },
          })
        );
      }
    },
  });

  const handleResetLimit = () => {
    limitFormik.resetForm();
  };

  React.useEffect(() => {
    if (customerId) {
      dispatch(getCustomerById(customerId));
    }
  }, [dispatch, customerId]);

  return (
    <>
      <HeadingCrumb lastElement={currentCustomer?.company_name || ""} />
      <CustomerHeading variant="h6">Customer Profile</CustomerHeading>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <UICard padding="p4">
            <DetailsForm onSubmit={formik.handleSubmit}>
              <DetailHeading variant="h6">Customer Details</DetailHeading>

              <FormControl
                style={{ marginBottom: 32 }}
                error={Boolean(formik.errors.name && formik.touched.name)}
              >
                <Input
                  name="name"
                  id="name"
                  placeholder="Full Name"
                  aria-describedby="primary-error-text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.name && formik.touched.name)}
                />
                {formik.errors.name && formik.touched.name && (
                  <FormHelperText id="primary-error-text">
                    {formik.errors.name}
                  </FormHelperText>
                )}
              </FormControl>

              <DetailHeading variant="h6">Billing Address</DetailHeading>

              <DetailsContainer>
                <FormControl
                  error={Boolean(formik.errors.line_1 && formik.touched.line_1)}
                >
                  <Input
                    name="line_1"
                    id="line_1"
                    placeholder="Address Line 1"
                    aria-describedby="primary-error-text"
                    value={formik.values.line_1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.errors.line_1 && formik.touched.line_1
                    )}
                  />
                  {formik.errors.line_1 && formik.touched.line_1 && (
                    <FormHelperText id="primary-error-text">
                      {formik.errors.line_1}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  error={Boolean(formik.errors.line_2 && formik.touched.line_2)}
                >
                  <Input
                    name="line_2"
                    id="line_2"
                    placeholder="Address Line 2"
                    aria-describedby="primary-error-text"
                    value={formik.values.line_2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.errors.line_2 && formik.touched.line_2
                    )}
                  />
                  {formik.errors.line_2 && formik.touched.line_2 && (
                    <FormHelperText id="primary-error-text">
                      {formik.errors.line_2}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  error={Boolean(
                    formik.errors.postal_code && formik.touched.postal_code
                  )}
                >
                  <Input
                    name="postal_code"
                    id="postal_code"
                    placeholder="Postal Code"
                    aria-describedby="primary-error-text"
                    value={formik.values.postal_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.errors.postal_code && formik.touched.postal_code
                    )}
                  />
                  {formik.errors.postal_code && formik.touched.postal_code && (
                    <FormHelperText id="primary-error-text">
                      {formik.errors.postal_code}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  error={Boolean(formik.errors.city && formik.touched.city)}
                >
                  <Input
                    name="city"
                    id="city"
                    placeholder="City"
                    aria-describedby="primary-error-text"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.city && formik.touched.city)}
                  />
                  {formik.errors.city && formik.touched.city && (
                    <FormHelperText id="primary-error-text">
                      {formik.errors.city}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  error={Boolean(formik.errors.region && formik.touched.region)}
                >
                  <Input
                    name="region"
                    id="region"
                    placeholder="State"
                    aria-describedby="primary-error-text"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.errors.region && formik.touched.region
                    )}
                  />
                  {formik.errors.region && formik.touched.region && (
                    <FormHelperText id="primary-error-text">
                      {formik.errors.region}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  error={Boolean(
                    formik.errors.country && formik.touched.country
                  )}
                  // style={{ marginBottom: 32 }}
                >
                  <UISelect
                    labelId="country-label"
                    id="country"
                    name="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.errors.country && formik.touched.country
                    )}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Country
                    </MenuItem>
                    {countriesList.map((co) => (
                      <MenuItem key={co.code} value={co.code}>
                        {co.name}
                      </MenuItem>
                    ))}
                  </UISelect>
                  {formik.errors.country && formik.touched.country && (
                    <FormHelperText id="primary-error-text">
                      {formik.errors.country}
                    </FormHelperText>
                  )}
                </FormControl>

                {/* <DetailHeading variant="h6">Usage Limits</DetailHeading>

                <FormControl
                  error={Boolean(
                    formik.errors.usage_limit_type &&
                      formik.touched.usage_limit_type
                  )}
                  //   style={{ marginBottom: 32 }}
                >
                  <UISelect
                    labelId="usage_limit_type-label"
                    id="usage_limit_type"
                    name="usage_limit_type"
                    value={formik.values.usage_limit_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.errors.usage_limit_type &&
                        formik.touched.usage_limit_type
                    )}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Usage Limit Type
                    </MenuItem>
                    {[
                      { title: "Off", value: "null" },
                      { title: "US Dollars ($)", value: "us_dollars" },
                      { title: "Qty of Shipments", value: "shipment" },
                    ].map((co) => (
                      <MenuItem key={co.value} value={co.value}>
                        {co.title}
                      </MenuItem>
                    ))}
                  </UISelect>
                  {formik.errors.usage_limit_type &&
                    formik.touched.usage_limit_type && (
                      <FormHelperText id="primary-error-text">
                        {formik.errors.usage_limit_type}
                      </FormHelperText>
                    )}
                </FormControl>

                <FormControl
                  error={Boolean(
                    formik.errors.limited_to && formik.touched.limited_to
                  )}
                >
                  <Input
                    name="limited_to"
                    id="limited_to"
                    type="number"
                    placeholder="Maximum Usage"
                    aria-describedby="primary-error-text"
                    value={formik.values.limited_to}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.errors.limited_to && formik.touched.limited_to
                    )}
                    disabled={
                      !formik.values.usage_limit_type ||
                      formik.values.usage_limit_type === "null"
                    }
                  />
                  {formik.errors.limited_to && formik.touched.limited_to && (
                    <FormHelperText id="primary-error-text">
                      {formik.errors.limited_to}
                    </FormHelperText>
                  )}
                </FormControl> */}

                <div>
                  {updateCustomerByIdStatus === "PENDING" ? (
                    <CircularProgress color="primary" size={24} thickness={6} />
                  ) : (
                    <UIButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Update
                    </UIButton>
                  )}
                </div>
              </DetailsContainer>
            </DetailsForm>
          </UICard>
        </Grid>
        <Grid item xs={9}>
          <UICard padding="p4" style={{ height: "100%" }}>
            <DetailHeading variant="h6">Take Action</DetailHeading>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CardLink
                  title="Shipments History"
                  bgURL={bgThree}
                  to={`/customers/${customerId}/shipments`}
                  style={{ width: "100%", height: 114 }}
                />
              </Grid>
              <Grid item xs={6}>
                <CardLink
                  title="Bulk Uploads"
                  bgURL={bgThree}
                  to={`/customers/${customerId}/shipments/bulk`}
                  style={{ width: "100%", height: 114 }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: 44 }}>
              <Grid item xs={4}>
                <DetailsForm onSubmit={limitFormik.handleSubmit}>
                  <DetailHeading variant="h6">Usage Limits</DetailHeading>

                  <DetailsContainer>
                    <FormControl
                      error={Boolean(
                        limitFormik.errors.usage_limit_type &&
                          limitFormik.touched.usage_limit_type
                      )}
                    >
                      <UISelect
                        labelId="usage_limit_type-label"
                        id="usage_limit_type"
                        name="usage_limit_type"
                        value={limitFormik.values.usage_limit_type}
                        onChange={(e) => {
                          limitFormik.setFieldValue("limited_to", "");
                          limitFormik.handleChange(e);
                        }}
                        onBlur={limitFormik.handleBlur}
                        error={Boolean(
                          limitFormik.errors.usage_limit_type &&
                            limitFormik.touched.usage_limit_type
                        )}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select Usage Limit Type
                        </MenuItem>
                        {[
                          { title: "Off", value: "null" },
                          { title: "US Dollars ($)", value: "us_dollars" },
                          { title: "Qty of Shipments", value: "shipment" },
                        ].map((co) => (
                          <MenuItem key={co.value} value={co.value}>
                            {co.title}
                          </MenuItem>
                        ))}
                      </UISelect>
                      {limitFormik.errors.usage_limit_type &&
                        limitFormik.touched.usage_limit_type && (
                          <FormHelperText id="primary-error-text">
                            {limitFormik.errors.usage_limit_type}
                          </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl
                      error={Boolean(
                        limitFormik.errors.limited_to &&
                          limitFormik.touched.limited_to
                      )}
                    >
                      <Input
                        name="limited_to"
                        id="limited_to"
                        type="number"
                        placeholder="Maximum Usage"
                        aria-describedby="primary-error-text"
                        value={limitFormik.values.limited_to}
                        onChange={limitFormik.handleChange}
                        onBlur={limitFormik.handleBlur}
                        error={Boolean(
                          limitFormik.errors.limited_to &&
                            limitFormik.touched.limited_to
                        )}
                        disabled={
                          !limitFormik.values.usage_limit_type ||
                          limitFormik.values.usage_limit_type === "null"
                        }
                      />
                      {limitFormik.errors.limited_to &&
                        limitFormik.touched.limited_to && (
                          <FormHelperText id="primary-error-text">
                            {limitFormik.errors.limited_to}
                          </FormHelperText>
                        )}
                    </FormControl>

                    {limitFormik.dirty && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <UIButton
                          type="button"
                          variant="outlined"
                          color="black"
                          onClick={handleResetLimit}
                        >
                          Cancel
                        </UIButton>

                        <UIButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={formik.isSubmitting || !formik.isValid}
                        >
                          Save Changes
                        </UIButton>
                      </div>
                    )}
                  </DetailsContainer>
                </DetailsForm>
              </Grid>
            </Grid>
          </UICard>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerView;
