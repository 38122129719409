import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Modal from '@mui/material/Modal';
import { PageHeading } from 'components/Heading';
import Icon from 'components/Icon';
import { Alert, FormControl, FormHelperText, Grid, Snackbar, AlertTitle } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Input } from 'components/UIForm';
import Button from 'components/UIButton';
import { ModalBody } from 'components/UIModal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetOverpackInfo, updateOverpackDimentionsById, clearOverPackList, deleteManifestOverpack, createNewManifest, resetCreateNewManifestStatus, duplicateOverpackError } from 'store/wms';
import {
    Column,
    useTable
} from "react-table";
import { ManifestSessionOverpack } from 'store/wms/types';
import { BackLink, DeleteButton, DimensionContainer, ManifestAlert, ManifestForm, ManifestHeader, ManifestInfo, ManifestItem } from './manifesting.styled';
import { ShipmentTableRow } from 'styles/table.styled';
import { ConfirmModalContent, ConfirmModalHeading } from 'styles/modal.styled';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const manifestSchema = Yup.object().shape({
    overpack: Yup.string()
        .required('overpack is required'),
    weight: Yup.number()
        .required('weight is required'),
    width: Yup.number()
        .required('width is required'),
    length: Yup.number()
        .required('length is required'),
    height: Yup.number()
        .required('height is required'),
});

const Manifesting = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { currentManifestSession, createNewManifestStatus } = useAppSelector(state => state.wms);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const overpackRef = React.useRef<HTMLInputElement>(null);
    const [manifestError, setManifestError] = React.useState('Error Manifesting Overpacks');

    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);

    const overPackListColumns: Array<Column<ManifestSessionOverpack>> = React.useMemo(() => [
        {
            Header: "Overpack#",
            accessor: "overpack",
        },
        {
            Header: "Service",
            accessor: "service",
        },
        {
            Header: "Destination",
            accessor: "destination",
        },
        {
            Header: "Weight (kg)",
            accessor: "weight",
        },
        {
            Header: "Dimensions",
            accessor: (originalRow) => `${originalRow.length} x ${originalRow.width} x ${originalRow.height} cm`,
        },
        {
            Header: () => (
                currentManifestSession.overPackList.length > 0 ? <Button
                    color="delete"
                    variant="outlined"
                    startIcon={<Icon name="x-mark" size={10} masked={true} color="delete" />}
                    onClick={() => dispatch(clearOverPackList())}
                >
                    Clear all
                </Button> : <></>
            ),
            accessor: "_id",
            Cell: ({ row }) => (
                <DeleteButton
                    color="delete"
                    variant="outlined"
                    onClick={() => dispatch(deleteManifestOverpack(row.original._id))}
                >
                    <Icon name="x-mark" size={10} masked={true} color="delete" />
                </DeleteButton>
            )
        }
    ], [dispatch, currentManifestSession.overPackList])

    const {
        getTableProps,
        headerGroups,
        getTableBodyProps,
        rows,
        prepareRow,
    } = useTable(
        {
            data: currentManifestSession.overPackList,
            columns: overPackListColumns,
        }
    );

    const formik = useFormik({
        initialValues: { overpack: '', weight: '', width: '', length: '', height: '' },
        validationSchema: manifestSchema,
        onSubmit: async (values, { resetForm }) => {
            const { overpack, height, length, weight, width } = values;

            if (currentManifestSession.overPackList.find(ov => ov.overpack === parseInt(overpack))) {
                dispatch(duplicateOverpackError())
            } else {
                dispatch(updateOverpackDimentionsById({
                    id: overpack,
                    payload: {
                        dimensions: {
                            height: parseFloat(height),
                            length: parseFloat(length),
                            weight: parseFloat(weight),
                            width: parseFloat(width)
                        }
                    }
                })).unwrap().then(() => {
                    resetForm();
                    overpackRef.current?.focus();
                }).finally(() => {
                    let intervalId = setInterval(() => {
                        dispatch(resetOverpackInfo(intervalId));
                    }, 3000);
                });
            }
        }
    });

    const handleCreateManifest = () => {
        if (currentManifestSession.overPackList.length > 0) {
            dispatch(createNewManifest({
                overpacks: currentManifestSession.overPackList.map(op => op.overpack)
            })).unwrap().then(res => navigate('/warehousing')).catch(err => {
                // console.log('err: ', err);
                setManifestError(err.message);
            }).finally(() => {
                let intervalId = setInterval(() => {
                    dispatch(resetCreateNewManifestStatus(intervalId));
                }, 3000);

                handleCloseConfirm();
            });
        }
    }

    const calculateOverPackListVolume = () => {
        const totalLength = currentManifestSession.overPackList.reduce((a, b) => a + b.length, 0);
        const totalWidth = currentManifestSession.overPackList.reduce((a, b) => a + b.width, 0);
        const totalHeight = currentManifestSession.overPackList.reduce((a, b) => a + b.height, 0);

        return Number((totalLength * totalWidth * totalHeight) / 6000).toFixed(1);
    }

    const calculateOverPackListCubicMeters = () => {
        const totalLength = currentManifestSession.overPackList.reduce((a, b) => a + b.length, 0);
        const totalWidth = currentManifestSession.overPackList.reduce((a, b) => a + b.width, 0);
        const totalHeight = currentManifestSession.overPackList.reduce((a, b) => a + b.height, 0);

        return Number((totalLength * totalWidth * totalHeight) / 1000000).toFixed(3);
    }

    React.useEffect(() => {
        overpackRef.current?.focus();
    }, []);

    return (
        <>
            <BackLink to="/warehousing">
                <Icon name="back-arrow" size={12} />
                <Typography>Back to Warehousing</Typography>
            </BackLink>

            <ManifestHeader>
                <PageHeading variant="h4">Manifest</PageHeading>
                {currentManifestSession.overPackList.length > 0 && <Button color="primary" onClick={handleOpenConfirm}>Manifest</Button>}
            </ManifestHeader>

            <ManifestForm onSubmit={formik.handleSubmit}>
                <div style={{ display: 'flex', gap: 20, marginTop: 64 }}>
                    <div style={{ flex: 1 }}>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>Overpack</Typography>
                        <FormControl style={{ width: '100%' }} error={Boolean(formik.errors.overpack && formik.touched.overpack)}>
                            <Input
                                name="overpack"
                                id="overpack"
                                placeholder="Scan Overpack"
                                aria-describedby="overpack-error-text"
                                value={formik.values.overpack}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.overpack && formik.touched.overpack)}
                                inputRef={overpackRef}
                            />
                            {(formik.errors.overpack && formik.touched.overpack) && <FormHelperText id="overpack-error-text">{formik.errors.overpack}</FormHelperText>}
                        </FormControl>
                    </div>
                    <div style={{ width: 270 }}>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>Weight (kg)</Typography>
                        <FormControl style={{ width: '100%' }} error={Boolean(formik.errors.weight && formik.touched.weight)}>
                            <Input
                                type="number"
                                name="weight"
                                id="weight"
                                placeholder="Weight (kg)"
                                aria-describedby="weight-error-text"
                                value={formik.values.weight}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.weight && formik.touched.weight)}
                            />
                            {(formik.errors.weight && formik.touched.weight) && <FormHelperText id="weight-error-text">{formik.errors.weight}</FormHelperText>}
                        </FormControl>
                    </div>
                    <div>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>Dimensions (cm)</Typography>
                        <DimensionContainer>
                            <FormControl error={Boolean(formik.errors.length && formik.touched.length)}>
                                <Input
                                    type="number"
                                    name="length"
                                    id="length"
                                    placeholder="Length"
                                    aria-describedby="length-error-text"
                                    value={formik.values.length}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.errors.length && formik.touched.length)}
                                />
                                {(formik.errors.length && formik.touched.length) && <FormHelperText id="length-error-text">{formik.errors.length}</FormHelperText>}
                            </FormControl>

                            <FormControl error={Boolean(formik.errors.width && formik.touched.width)}>
                                <Input
                                    type="number"
                                    name="width"
                                    id="width"
                                    placeholder="Width"
                                    aria-describedby="width-error-text"
                                    value={formik.values.width}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.errors.width && formik.touched.width)}
                                />
                                {(formik.errors.width && formik.touched.width) && <FormHelperText id="width-error-text">{formik.errors.width}</FormHelperText>}
                            </FormControl>

                            <FormControl error={Boolean(formik.errors.height && formik.touched.height)}>
                                <Input
                                    type="number"
                                    name="height"
                                    id="height"
                                    placeholder="Height"
                                    aria-describedby="height-error-text"
                                    value={formik.values.height}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.errors.height && formik.touched.height)}
                                />
                                {(formik.errors.height && formik.touched.height) && <FormHelperText id="height-error-text">{formik.errors.height}</FormHelperText>}
                            </FormControl>

                            {(formik.isValid && formik.dirty) ? (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={formik.isSubmitting || !formik.isValid}
                                    startIcon={<Icon name="box" size={16} masked={true} />}
                                >
                                    Add to Manifest
                                </Button>
                            ) : <div />}
                        </DimensionContainer>
                    </div>
                </div>
            </ManifestForm>

            <Grid container columnSpacing={2} alignItems="center" style={{ marginTop: 64 }}>
                <Grid item xs={4}>
                    <ManifestInfo>
                        <ManifestItem>
                            <Typography>CTNS</Typography>
                            <Typography>{currentManifestSession.overPackList.length}</Typography>
                        </ManifestItem>

                        <ManifestItem>
                            <Typography>GRS</Typography>
                            <Typography>{Number(currentManifestSession.overPackList.reduce((a, b) => a + b.weight, 0)).toFixed(1)} kg</Typography>
                        </ManifestItem>

                        <ManifestItem>
                            <Typography>VOL</Typography>
                            <Typography>{calculateOverPackListVolume()} kg / {calculateOverPackListCubicMeters()} CBM</Typography>
                        </ManifestItem>
                    </ManifestInfo>
                </Grid>

                <Grid item xs={8}>
                    {currentManifestSession.currentOverpackInfo.message && currentManifestSession.currentOverpackInfo.type !== 'pending' && (
                        <ManifestAlert
                            icon={
                                <Icon name={currentManifestSession.currentOverpackInfo.type === 'error' ? 'warning-full' : 'package-filling'} size={16} masked={true} color="black" />
                            }
                            status={currentManifestSession.currentOverpackInfo.type}
                        >
                            {currentManifestSession.currentOverpackInfo.message}
                        </ManifestAlert>
                    )}
                </Grid>
            </Grid>

            <TableContainer style={{ marginTop: 80 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell align={column.id === '_id' ? 'right' : 'left'}  {...column.getHeaderProps()}>
                                        {/* {column.render('Header')} */}
                                        <Typography>{column.render('Header')}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);

                            return (
                                <ShipmentTableRow {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <TableCell component="th" scope="row" align={cell.column.id === '_id' ? 'right' : 'left'} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                                    ))}
                                </ShipmentTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={openConfirm}
                onClose={handleCloseConfirm}
                style={{ overflow: 'auto' }}
                aria-labelledby="confirm-manifest-modal-title"
                aria-describedby="confirm-manifest-modal-description"
                keepMounted
            >
                <ModalBody>
                    <ConfirmModalHeading variant="h2">Confirm Manifest</ConfirmModalHeading>

                    <ConfirmModalContent>
                        <Typography variant="body1">
                            This action is final and cannot be undone.
                        </Typography>
                    </ConfirmModalContent>

                    <Button onClick={handleCreateManifest} fullWidth>Submit Manifest</Button>
                    <Button variant="outlined" style={{ marginTop: 8 }} onClick={handleCloseConfirm} fullWidth>Return to Manifesting</Button>
                </ModalBody>
            </Modal>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={createNewManifestStatus === 'ERROR' || createNewManifestStatus === 'SUCCESS'}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    {createNewManifestStatus === 'ERROR' && <Alert
                        color="error"
                        variant="filled"
                        icon={<CheckCircleOutlineIcon fontSize="inherit" />}
                    >
                        <>
                            <AlertTitle>Error</AlertTitle>
                            {manifestError}
                        </>
                    </Alert>}

                    {createNewManifestStatus === 'SUCCESS' && <Alert
                        color="success"
                        variant="filled"
                        icon={<CheckCircleOutlineIcon fontSize="inherit" />}
                    >
                        <AlertTitle>Success</AlertTitle>
                        Successfully Manifested Overpacks
                    </Alert>}
                </div>
            </Snackbar>
        </>
    )
}

export default Manifesting;